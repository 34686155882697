<template>
    <div class="Overview Container flex flex-1">
        <Sidebar
            ref="Sidebar"
            :tab="tab"
            @tab="handleTabChange"
        />
        <div class="Screen flex-1">
            <div class="Titles">
                <div class="Title">
                    <div class="TitleText">
                        {{ $t('booster.performanceBoosterTitle') }}
                        <div class="Description">
                            {{ description }}
                        </div>
                    </div>
                    <QSpace />
                    <div class="RightText">
                        <QIcon
                            v-if="tab === 'PREDICTION' && highlightsData && !fullResult"
                            class="WarningIcon"
                            name="warning"
                            :style="{ color: '#ffbc04' }"
                            size="40px"
                        >
                            <div class="TooltipText">
                                <WText>
                                    {{ $t('visualization.predictions.someNotAvailable') }}
                                </WText>
                            </div>
                        </QIcon>
                        {{ tabTitle }}
                        <div class="D2T">
                            <QBtn
                                v-if="tab === 'OVERVIEW'"
                                color="primary"
                                noCaps
                                :label="$t('booster.d2t.viewReport')"
                                :loading="analysisReportLoading"
                                :disable="analysisReportDisabled"
                                @click="handleOpenAnalysisReportModal"
                            >
                                <QIcon
                                    right
                                    name="vital_signs"
                                    class="material-symbols-outlined"
                                />
                            </QBtn>
                            <QBtn
                                v-if="tab === 'PREDICTION'"
                                color="primary"
                                noCaps
                                :label="$t('booster.d2t.viewReport')"
                                :loading="analysisReportLoading"
                                :disable="analysisReportDisabled"
                                @click="handleOpenAnalysisReportModal"
                            >
                                <QIcon
                                    right
                                    name="vital_signs"
                                    class="material-symbols-outlined"
                                />
                            </QBtn>
                            <QBtn
                                v-if="tab === 'IMPROVEMENT'"
                                color="primary"
                                noCaps
                                :label="$t('booster.d2t.viewReport')"
                                :loading="analysisReportLoading"
                                :disable="analysisReportDisabled"
                                @click="handleOpenAnalysisReportModal"
                            >
                                <QIcon
                                    right
                                    name="vital_signs"
                                    class="material-symbols-outlined"
                                />
                            </QBtn>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="tab === 'OVERVIEW'"
                class="ScreenOptions"
            >
                <div
                    v-if="!slaDefined || !attributes || attributes.length <= 0"
                    class="flex flex-col justify-center items-center q-ma-md"
                >
                    <div>
                        <WText
                            :size="20"
                        >
                            {{ $t('booster.overview.slaNotDefined') }}
                        </WText>
                    </div>
                    <div>
                        <WText
                            :size="15"
                        >
                            {{ $t('booster.overview.noConditionsText') }}
                        </WText>
                    </div>
                    <div class="mt-2">
                        <QBtn
                            iconLeft="settings"
                            noCaps
                            color="primary"
                            @click="handleGoToSettings"
                        >
                            {{ $t('visualization.tabs.settings') }}
                        </QBtn>
                    </div>
                </div>
                <State
                    v-if="attributes && attributes.length > 0
                        && attributesWithoutAll && attributesWithoutAll.length > 0
                        && slaDefined"
                    ref="State"
                    :processId="processId"
                    :attributes="attributes"
                    :attributesWithoutAll="attributesWithoutAll"
                    @on-analysis-report-loading="handleAnalysisReportLoading"
                    @on-analysis-report-finished="handleAnalysisReportFinished"
                />
            </div>
            <div
                v-if="tab === 'PREDICTION'"
                class="ScreenOptions"
            >
                <div
                    v-if="!slaDefined || !caseCompletionDefined|| !attributes || attributes.length <= 0"
                    class="flex flex-col justify-center items-center q-ma-md"
                >
                    <div>
                        <WText
                            :size="20"
                        >
                            {{ getPredictionErrorMessage() }}
                        </WText>
                    </div>
                    <div>
                        <WText
                            :size="15"
                        >
                            {{ $t('booster.overview.noConditionsText') }}
                        </WText>
                    </div>
                    <div class="mt-2">
                        <QBtn
                            iconLeft="settings"
                            noCaps
                            color="primary"
                            @click="handleGoToSettings"
                        >
                            {{ $t('visualization.tabs.settings') }}
                        </QBtn>
                    </div>
                </div>
                <Prediction
                    v-if="attributes && attributes.length > 0 && slaDefined && caseCompletionDefined"
                    ref="Prediction"
                    :processId="processId"
                    :attributes="attributes"
                    :sla="slaValue"
                    @on-analysis-report-loading="handleAnalysisReportLoading"
                    @on-analysis-report-finished="handleAnalysisReportFinished"
                    @on-check-highlights="handleHighlightsWarning"
                />
            </div>
            <div
                v-if="tab === 'IMPROVEMENT'"
                class="ScreenOptions"
            >
                <div
                    v-if="!cost || !attributes || attributes.length <= 0"
                    class="flex flex-col justify-center items-center q-ma-md"
                >
                    <div>
                        <WText
                            :size="20"
                        >
                            {{ $t('booster.overview.costNotDefined') }}
                        </WText>
                    </div>
                    <div>
                        <WText
                            :size="15"
                        >
                            {{ $t('booster.overview.noConditionsText') }}
                        </WText>
                    </div>
                    <div class="mt-2">
                        <QBtn
                            iconLeft="settings"
                            noCaps
                            color="primary"
                            @click="handleGoToSettings"
                        >
                            {{ $t('visualization.tabs.settings') }}
                        </QBtn>
                    </div>
                </div>
                <Improvement
                    v-if="attributes && attributes.length > 0
                        && attributesWithoutAll && attributesWithoutAll.length > 0
                        && cost"
                    ref="Improvement"
                    :processId="processId"
                    :attributes="attributesWithoutAll"
                    :cost="cost"
                    @on-analysis-report-loading="handleAnalysisReportLoading"
                    @on-analysis-report-finished="handleAnalysisReportFinished"
                />
            </div>
            <q-inner-loading :showing="attributesLoading || settingsLoading" />
        </div>
        <AnalysisReportModal
            ref="analysisReportModal"
        />
    </div>
</template>
<script>

import {
    Api, apiRequest, notifyError,
} from '@/api'
import Sidebar from './components/Sidebar.vue'
import Prediction from './sections/prediction/Prediction.vue'
import State from './sections/state/State.vue'
import Improvement from './sections/improvement/Improvement.vue'
import AnalysisReportModal from './components/AnalysisReport.vue'

const DEFAULT_TAB = 'OVERVIEW'

export default {
    name: 'Overview',
    components: {
        Sidebar,
        Prediction,
        Improvement,
        State,
        AnalysisReportModal,
    },
    data () {
        return {
            tab: DEFAULT_TAB,
            processId: this.$route.params.processId,
            viewMode: 'booster',
            tabTitle: this.$t('booster.overview.process'),
            description: this.$t('booster.overview.description'),
            attributes: undefined,
            attributesWithoutAll: undefined,
            cost: undefined,
            // settings: undefined,
            slaDefined: true,
            slaValue: undefined,
            caseCompletionDefined: true,
            attributesLoading: false,
            settingsLoading: false,
            analysisReport: '',
            analysisReportLoading: false,
            analysisReportDisabled: true,
            highlightsData: undefined,
            fullResult: false,
        }
    },
    mounted () {
        this.tabChangeByRoute()
        this.getAttributes()
        this.getSettings()
    },
    methods: {
        handleTabChange (newTab) {
            this.tab = newTab
            this.handleRouteChange(newTab)
            this.handleTabTitleChange(newTab)
            this.analysisReportDisabled = true
        },
        handleRouteChange (newTab) {
            const currentRoute = this.$route.fullPath
            const segments = currentRoute.split('/')
            const index = segments.indexOf('booster')
            if (index !== -1) {
                segments.splice(index, 2, `booster/${newTab}`)
                const newRoute = segments.join('/')
                this.$router.push(newRoute)
            }
        },
        handleTabTitleChange (newTab) {
            switch (newTab) {
                case 'OVERVIEW':
                    this.tabTitle = this.$t('booster.overview.process')
                    this.description = this.$t('booster.overview.description')
                    break
                case 'PREDICTION':
                    this.tabTitle = this.$t('booster.prediction.process')
                    this.description = this.$t('booster.prediction.description')
                    break
                case 'IMPROVEMENT':
                    this.tabTitle = this.$t('booster.improvement.process')
                    this.description = this.$t('booster.improvement.description')
                    break
                default:
                    this.tabTitle = this.$t('booster.overview.process')
                    this.description = this.$t('booster.overview.description')
                    break
            }
        },
        handleGoToSettings () {
            const { organizationId } = this.$route.params
            const { projectId } = this.$route.params
            const { processId } = this.$route.params

            this.$router.push({
                name: 'ProcessVisualization',
                params: {
                    organizationId,
                    projectId,
                    processId,
                    tab: 'SETTINGS',
                },
            })
        },
        tabChangeByRoute () {
            const currentRoute = this.$route.fullPath
            const segments = currentRoute.split('/')
            if (segments.includes('OVERVIEW')) {
                this.tab = 'OVERVIEW'
                this.handleTabTitleChange('OVERVIEW')
            } else if (segments.includes('PREDICTION')) {
                this.tab = 'PREDICTION'
                this.handleTabTitleChange('PREDICTION')
            } else if (segments.includes('IMPROVEMENT')) {
                this.tab = 'IMPROVEMENT'
                this.handleTabTitleChange('IMPROVEMENT')
            }
        },
        getAttributes () {
            // eslint-disable-next-line prefer-destructuring
            const datasetId = this.processId
            this.attributesLoading = true
            apiRequest(Api().datasets.getConfigurations({ datasetId }))
                .then(({ data }) => {
                    const relevantAttr = data[0].relevantAttributes
                    this.attributesWithoutAll = JSON.parse(JSON.stringify(relevantAttr))
                    this.attributes = relevantAttr
                    if (this.attributes[0].name !== this.$t('booster.state.all')) {
                        this.attributes.unshift({ name: this.$t('booster.state.all') })
                    }
                })
                .catch(notifyError)
                .finally(() => {
                    this.attributesLoading = false
                })
        },
        getSettings () {
            this.settingsLoading = true
            apiRequest(Api().datasets.get({ datasetId: this.processId }))
                .then(({ data }) => {
                    if (data[0].caseCompletion === null || data[0].caseCompletion.endingActivities.length <= 0) {
                        this.caseCompletionDefined = false
                    }
                    if (data[0].slaValue === null) {
                        this.slaDefined = false
                        this.slaValue = data[0].slaValue
                    }
                    this.cost = data[0].cost
                })
                .catch(notifyError)
                .finally(() => {
                    this.settingsLoading = false
                })
        },
        getPredictionErrorMessage () {
            let errorMessage = ''
            if (this.caseCompletionDefined === false && this.slaDefined === false) {
                errorMessage = this.$t('booster.overview.slaAndCaseNotDefined')
            } else if (this.caseCompletionDefined !== false && this.slaDefined === false) {
                errorMessage = this.$t('booster.overview.slaNotDefined')
            } else if (this.caseCompletionDefined === false && this.slaDefined !== false) {
                errorMessage = this.$t('booster.overview.caseCompletionNotDefined')
            }
            return errorMessage
        },
        handleAnalysisReportLoading () {
            this.analysisReportLoading = true
        },
        handleAnalysisReportFinished (analysisReport) {
            this.analysisReport = analysisReport
            if (this.analysisReport) {
                this.analysisReportDisabled = false
            }
            this.analysisReportLoading = false
        },
        handleOpenAnalysisReportModal () {
            this.$refs.analysisReportModal.open({ analysisReport: this.analysisReport })
        },
        handleHighlightsWarning (highlightContent) {
            this.highlightsData = highlightContent.highlightsData
            this.fullResult = highlightContent.fullResult
        },
    },
}
</script>
<style scoped lang="scss">
    $visualization-sidebar-width: 60px;
    .Overview {
        overflow: visible;
    }
    .Container {
        flex-wrap: nowrap;
        min-height: 100vh;
    }
    .Screen {
        margin-top: $subheader-height;
        height: calc(100vh - $subheader-height);
        padding-left: 1vw;
        padding-right: 1vw;
        position: relative;
    }
    .Sidebar {
        width: 100%;
        height: calc(100vh - $subheader-height);
        top: $subheader-height;
        padding-left: 10px;
        position: relative;
        overflow-x: hidden;
        max-width: $visualization-sidebar-width;
        min-width: $visualization-sidebar-width;
        border-right: 1px solid $border-color;
        transition: $transition;
        @media screen and (min-width: $md) {
            max-width: $visualization-sidebar-width;
            min-width: $visualization-sidebar-width;
        }
    }
    .ScreenOptions {
        height: 87%;
    }
    .Titles {
       height: 12%;
    }
    .Title {
        display: flex;
    }
    .TitleText {
        font-size: 3vh;
        font-weight: bolder;
    }
    .RightText {
        font-size: 3vh;
    }
    .toggle {
        font-size:2vh;
        display:flex;
        justify-content: right;
    }
    .Description {
        color: $primary;
        width:fit-content;
        font-size: 1.7vh;
        font-weight: normal;
    }
    @media (max-width: 1150px) {
        .Overview {
            overflow: visible;
        }
    }
    .D2T {
        display: flex;
        justify-content: flex-end;
        margin-top: 5px;
    }
    // .WarningIcon {
    //     float: right
    // }
    .WarningIcon:hover .TooltipText {
        display: block;
    }
    .TooltipText {
        display: none;
        position: absolute;
        top: 70%;
        left: -15%;
        transform: translate(-100%, -50%);
        width: 35vw;
        background-color: #ffbc04;
        color: white;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 10;
        white-space: normal;
    }
</style>
