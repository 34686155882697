<template>
    <button
        :class="buttonClass"
        :disabled="disabled"
        class="WButton"
    >
        <div class="Wrapper">
            <WSpinner
                :visible="loading"
                :color="spinnerColor"
                small
                noMargin
            />
            <WIcon
                v-if="iconLeft"
                class="Icon"
                :icon="iconLeft"
                :size="iconSize"
            />
            <span><slot /></span>
            <WIcon
                v-if="iconRight"
                class="Icon"
                :icon="iconRight"
                :size="iconSize"
            />
        </div>
    </button>
</template>

<script>
import VueTypes from 'vue-types'
import { capitalize } from 'lodash'

export default {
    name: 'WButton',
    props: {
        size: VueTypes.oneOf(['sm', '', 'lg']),
        width: VueTypes.oneOf(['sm', '', 'lg']),
        iconRight: VueTypes.string.def(''),
        iconLeft: VueTypes.string.def(''),
        iconSize: VueTypes.number.def(18),
        color: VueTypes.oneOf(['primary', 'secondary', 'tertiary', 'success', 'warning', 'info', 'error', 'none', 'link']).def('primary'),
        linkColor: VueTypes.oneOf(['primary', 'secondary', 'tertiary', 'success', 'warning', 'info', 'error']).def(() => undefined),
        outline: VueTypes.bool.def(false),
        rounded: VueTypes.bool.def(false),
        shadow: VueTypes.bool.def(false),
        block: VueTypes.bool.def(false),
        disabled: VueTypes.bool.def(false),
        loading: VueTypes.bool.def(false),
        uppercase: VueTypes.bool.def(false),
    },
    computed: {
        buttonClass () {
            return [
                this.color,
                this.linkColor ? `link-${this.linkColor}` : '',
                this.size ? `size${capitalize(this.size)}` : '',
                this.width ? `width${capitalize(this.width)}` : '',
                {
                    block: this.block,
                    outline: this.outline,
                    rounded: this.rounded,
                    shadow: this.shadow,
                    loading: this.loading,
                    disabled: this.disabled,
                    uppercase: this.uppercase,
                    iconLeft: this.iconLeft,
                    iconRight: this.iconRight,
                },
            ]
        },
        spinnerColor () {
            const primaryColors = {
                secondary: true,
                link: true,
                none: true,
            }
            if (this.disabled) {
                return primaryColors[this.color] ? 'grayMedium' : 'white'
            }
            return primaryColors[this.color] ? 'primary' : 'white'
        },
    },
}
</script>

<style scoped lang="scss">
.Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover,
    &:focus,
    &:active {
        outline: none;
    }

    span {
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.WButton {
    position: relative;
    padding: 7px 10px;
    border-radius: $border-radius;
    border: 1px solid transparent;
    background: transparent;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    outline: none;
    cursor: pointer;
    transition: all .3s ease-in-out;

    &.primary {
        color: $white;
        border-color: $primary;
        background: $primary;
        .Icon {
            color: $white;
        }
        &:hover,
        &:focus,
        &:active,
        &.active {
            background-color: darken($primary, 10%);
            border-color: $primary;
        }
        &:focus {
            box-shadow: 0 0 0 0.2rem rgba($primary, 0.5);
        }
        &.disabled {
            background-color: $gray-medium;
            border-color: $gray-medium;
            cursor: not-allowed;
            &:hover,
            &:focus,
            &:active {
                background-color: $gray-medium;
                border-color: $gray-medium;
            }
        }
    }
    &.secondary {
        color: $primary;
        border-color: $white;
        background: $white;
        .Icon {
            color: $primary;
        }
        &:hover,
        &:focus,
        &:active,
        &.active {
            background-color: darken($white, 10%);
            border-color: darken($white, 10%);
        }
        &:focus {
            box-shadow: 0 0 0 0.2rem rgba($white, 0.5);
        }
        &.disabled {
			color: $gray-medium;
			background-color: $white;
			border-color: $white;
			cursor: not-allowed;

			&:deep(.Icon) {
				color: $gray-medium;
			}
            &:hover,
            &:focus,
            &:active {
                background-color: $white;
                border-color: $white;
            }
        }
    }
    &.tertiary {
        color: $white;
        border-color: $gray-01;
        background: $gray-01;
        .Icon {
            color: $white;
        }
        &:hover,
        &:focus,
        &:active,
        &.active {
            background-color: darken($gray-01, 10%);
            border-color: $gray-01;
        }
        &:focus {
            box-shadow: 0 0 0 0.2rem rgba($gray-01, 0.5);
        }
        &.disabled {
			background-color: lighten($gray-01, 15%);
			border-color: lighten($gray-01, 15%);
			cursor: not-allowed;
            &:hover,
            &:focus,
            &:active {
                background-color: lighten($gray-01, 15%);
                border-color: lighten($gray-01, 15%);
            }
        }
    }
    &.success {
        color: $white;
        border-color: $success;
        background: $success;
        .Icon {
            color: $white;
        }
        &:hover,
        &:focus,
        &:active,
        &.active {
            background-color: darken($success, 10%);
            border-color: $success;
        }
        &:focus {
            box-shadow: 0 0 0 0.2rem rgba($success, 0.5);
        }
        &.disabled {
            background-color: $gray-medium;
            border-color: $gray-medium;
            cursor: not-allowed;
            &:hover,
            &:focus,
            &:active {
                background-color: $gray-medium;
                border-color: $gray-medium;
            }
        }
    }
    &.warning {
        color: $white;
        border-color: $warning;
        background: $warning;
        .Icon {
            color: $white;
        }
        &:hover,
        &:focus,
        &:active,
        &.active {
            background-color: darken($warning, 10%);
            border-color: $warning;
        }
        &:focus {
            box-shadow: 0 0 0 0.2rem rgba($warning, 0.5);
        }
        &.disabled {
            background-color: $gray-medium;
            border-color: $gray-medium;
            cursor: not-allowed;
            &:hover,
            &:focus,
            &:active {
                background-color: $gray-medium;
                border-color: $gray-medium;
            }
        }
    }
    &.error {
        color: $white;
        border-color: $danger;
        background: $danger;
        .Icon {
            color: $white;
        }
        &:hover,
        &:focus,
        &:active,
        &.active {
            background-color: darken($danger, 10%);
            border-color: $danger;
        }
        &:focus {
            box-shadow: 0 0 0 0.2rem rgba($danger, 0.5);
        }
        &.disabled {
            background-color: $gray-medium;
            border-color: $gray-medium;
            cursor: not-allowed;
            &:hover,
            &:focus,
            &:active {
                background-color: $gray-medium;
                border-color: $gray-medium;
            }
        }
    }
    &.info {
        color: $white;
        border-color: $info;
        background: $info;
        .Icon {
            color: $white;
        }
        &:hover,
        &:focus,
        &:active,
        &.active {
            background-color: darken($info, 10%);
            border-color: $info;
        }
        &:focus {
            box-shadow: 0 0 0 0.2rem rgba($info, 0.5);
        }
        &.disabled {
            background-color: $gray-medium;
            border-color: $gray-medium;
            cursor: not-allowed;
            &:hover,
            &:focus,
            &:active {
                background-color: $gray-medium;
                border-color: $gray-medium;
            }
        }
    }
    &.link {
        padding: 0;
        color: $primary;
        box-shadow: none;
        border-color: transparent;
        background: transparent;
        .Icon {
            top: 0;
            color: $primary;
            border-radius: 4px;
        }
        &:hover,
        &:focus,
        &:active,
        &.active {
            border-color: transparent;
            background: transparent;
        }

        &-primary {
            color: $primary;
            .Icon {
                color: $primary;
            }
        }

        &-secondary {
            color: $primary;
            .Icon {
                color: $primary;
            }
        }

        &-tertiary {
            color: $gray-01;
            .Icon {
                color: $gray-01;
            }
        }

        &-success {
            color: $success;
            .Icon {
                color: $success;
            }
        }

        &-warning {
            color: $warning;
            .Icon {
                color: $warning;
            }
        }

        &-error {
            color: $danger;
            .Icon {
                color: $danger;
            }
        }

        &-info {
            color: $info;
            .Icon {
                color: $info;
            }
        }

        &.disabled {
            color: $gray-medium;
			cursor: not-allowed;
            .Icon {
                color: $gray-medium;
            }
              span {
                text-decoration: none;
            }
        }
    }
    &.none {
        color: $primary;
        background: transparent;
        border-color: transparent;

        &:hover,
        // &:focus,
        &:active,
        &.active {
            background-color: rgba($primary, 0.1);
            border-color: rgba($primary, 0.1);
        }
        &:focus {
            // box-shadow: 0 0 0 0.2rem rgba($primary, 0.5);
            box-shadow: none;
        }
		&.disabled {
			cursor: not-allowed;

			&:deep(.Icon) {
				color: $primary;
			}
		}
    }
    // Loaders
    &.loading {
        &.icon {
            i {
                visibility: hidden;
            }
        }
        .WSpinner {
            margin-right: 10px;
        }
    }
    // Outline
    &.outline {
        &.icon {
            i {
                color: $gray-03;
            }
        }
        &.icon:hover,
        &.icon:focus,
        &.icon:active {
            i {
                color: $white;
            }
        }
        &.disabled {
            &.icon:hover,
            &.icon:focus,
            &.icon:active {
                i {
                    color: $gray-03;
                }
            }
        }
        &.primary {
            color: $primary;
            background: transparent;
            border-color: $primary;

            .Icon {
                color: $primary;
            }

            &:hover,
            &:focus,
            &:active {
                background-color: $primary;
                border-color: $primary;
                color: $white;

                .Icon {
                    color: $white;
                }
            }
            &:focus {
                box-shadow: 0 0 0 0.2rem rgba($primary, 0.5);
            }
            &.disabled {
				border-color: $gray-medium;
				color: $gray-medium;
				cursor: not-allowed;
                &:hover,
                &:focus,
                &:active {
                    background: transparent;
                    border-color: $gray-medium;
                    color: $gray-medium;
                    box-shadow: none;
                }
            }
        }
        &.secondary {
            color: $black;
            background: transparent;
            border-color: darken($black, 10%);

            &:hover,
            &:focus,
            &:active {
                color: $white;
                background-color: darken($black, 10%);
                border-color: darken($black, 10%);
            }
            &:focus {
                box-shadow: 0 0 0 0.2rem rgba(darken($white, 10%), 0.5);
            }
            &.disabled {
				color: darken($white, 10%);
				border-color: darken($white, 10%);
				cursor: not-allowed;
                &:hover,
                &:focus,
                &:active {
                    color: darken($white, 10%);
                    background: transparent;
                    border-color: darken($white, 10%);
                    box-shadow: none;
                }
            }
        }
        &.tertiary {
            color: $gray-01;
            background: transparent;
            border-color: $gray-01;

            &:hover,
            &:focus,
            &:active {
                color: $white;
                background-color: $gray-01;
                border-color: $gray-01;
            }
            &:focus {
                box-shadow: 0 0 0 0.2rem rgba($gray-01, 0.5);
            }
            &.disabled {
				color: $gray-01;
				border-color: $gray-01;
				cursor: not-allowed;
                &:hover,
                &:focus,
                &:active {
                    color: $gray-01;
                    background: transparent;
                    border-color: $gray-01;
                    box-shadow: none;
                }
            }
        }
        &.error {
            color: $danger;
            background: transparent;
            border-color: $danger;

            &:hover,
            &:focus,
            &:active {
                color: $white;
                background-color: $danger;
                border-color: $danger;
            }
            &:focus {
                box-shadow: 0 0 0 0.2rem rgba($danger, 0.5);
            }
            &.disabled {
				color: $danger;
				border-color: $danger;
				cursor: not-allowed;
                &:hover,
                &:focus,
                &:active {
                    color: $danger;
                    background: transparent;
                    border-color: $danger;
                    box-shadow: none;
                }
            }
        }
    }
    // Attr
    &.rounded {
        border-radius: 2rem;
    }
    &.uppercase {
        text-transform: uppercase;
    }
    &.shadow {
        box-shadow: 0 3px 7px 0 rgba($gray-01, 0.2);
    }
    &.block {
        width: 100%;
    }
    // Sizes
    &.widthSm {
        width: 100px;
    }
    &.widthLg {
        width: 200px;
    }
    &.sizeSm {
        padding-top: 0.22rem;
        padding-bottom: 0.22rem;
        font-size: 11px;
    }
    &.sizeLg {
        padding: 1rem 2rem;
        font-size: 24px;
        font-weight: 800;
        line-height: 1.3;
    }
    // Icons
    &.iconLeft {
        span:not(:empty) {
            margin-left: 10px;
        }
    }
    &.iconRight {
        span:not(:empty) {
            margin-right: 10px;
        }
    }
}
</style>
