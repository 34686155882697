<template>
    <div
        ref="draggable"
        class="Draggable"
        :style="{ position: isDragged ? 'absolute' : 'relative', top: isDragged ? `${top}px` : 'initial', left: isDragged ? `${left}px` : 'initial', zIndex: zIndex }"
        @mousedown="onMouseDown"
    >
        <slot />
    </div>
</template>

<script>

export default {
    name: 'Draggable',
    props: {
        zIndex: {
            type: Number,
            default: 0,
        },
    },
    data () {
        return {
            isDragging: false,
            isDragged: false,
            dragStartX: 0,
            dragStartY: 0,
            initialLeft: 0,
            initialTop: 0,
            left: 0,
            top: 0,
        }
    },
    mounted () {},
    methods: {
        onMouseDown (event) {
            this.left = this.$refs.draggable.offsetLeft
            this.top = this.$refs.draggable.offsetTop
            this.initialLeft = this.left
            this.initialTop = this.top
            this.isDragging = true
            this.dragStartX = event.clientX
            this.dragStartY = event.clientY
            document.addEventListener('mousemove', this.onMouseMove)
            document.addEventListener('mouseup', this.onMouseUp)
        },
        onMouseMove (event) {
            if (!this.isDragging) return
            const deltaX = event.clientX - this.dragStartX
            const deltaY = event.clientY - this.dragStartY
            const threshold = 10
            if (deltaX > threshold || deltaY > threshold) {
                this.isDragged = true
            }
            this.left = this.initialLeft + deltaX
            this.top = this.initialTop + deltaY
        },
        onMouseUp () {
            this.isDragging = false
            document.removeEventListener('mousemove', this.onMouseMove)
            document.removeEventListener('mouseup', this.onMouseUp)
        },
        resetPosition () {
            this.isDragged = false
            this.isDragging = false
            this.left = this.initialLeft
            this.top = this.initialTop
        },
    },
}
</script>
<style lang="scss">
.Draggable {
    display: flex;
    width: fit-content;
    // justify-content: center;
    align-items: center;
    position: absolute;
    cursor: move;
    user-select: none;
    transition: none;
 }
</style>
