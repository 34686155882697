<!-- eslint-disable prefer-destructuring -->
<template>
    <div class="row justify-center">
        <div class="col-8 justify-center q-pa-xs">
            <div class="row justify-center title">
                {{ $t('wizard.boosterWizard.performanceBooster.title') }}
            </div>
            <div class="row justify-center Subtitle">
                {{ $t('wizard.boosterWizard.performanceBooster.description') }}
            </div>
            <div
                v-if="loading"
                class="row items-center q-mt-lg"
            >
                <div class="square bg-green-3 border-green q-mx-md" />
                <WText>
                    {{ $t('wizard.boosterWizard.sla.compliance') }}
                </WText>
                <div class="square bg-red-3 border-red q-mx-md" />
                <WText>
                    {{ $t('wizard.boosterWizard.sla.nonCompliance') }}
                </WText>
            </div>
            <div class="row q-my-md">
                <div
                    :class="`col-${nonSlaCompliancePct === 0 ? 12 : getColWith(slaCompliancePct)}
                    ${slaCompliancePct === 0 ? 'hidden' : ''}`"
                    class="bg-green-3 border-green q-pa-lg text-center text-white text-weight-bolder"
                >
                    {{ `${App.numberLocationFormat(slaComplianceAbs, true)} ${$t('wizard.boosterWizard.sla.cases')} (${App.numberLocationFormat(slaCompliancePct, true)}%)` }}
                </div>
                <div
                    :class="`col-${slaCompliancePct === 0 ? 12 : 12-getColWith(slaCompliancePct)}
                    ${nonSlaCompliancePct === 0 ? 'hidden' : ''}`"
                    class="bg-red-3 border-red q-pa-lg text-center text-white text-weight-bolder"
                >
                    {{ `${App.numberLocationFormat(nonSlaComplianceAbs, true)} ${$t('wizard.boosterWizard.sla.cases')} (${App.numberLocationFormat(nonSlaCompliancePct, true)}%)` }}
                </div>
            </div>
            <div class="row justify-between">
                <WText
                    class="mb-1"
                    color="dark"
                >
                    {{ `Min: ${min}` }}
                </WText>
                <WText
                    class="mb-1"
                    color="dark"
                >
                    {{ `Max: ${max}` }}
                </WText>
            </div>
            <div class="row">
                <q-card
                    shadow
                    class="col q-pa-md rounded-borders"
                >
                    <WText
                        class="mb-1"
                        weight="bold"
                        color="dark"
                    >
                        {{ $t('wizard.boosterWizard.sla.name') }}
                    </WText>
                    <WText
                        class="mb-1"
                        color="dark"
                    >
                        {{ $t('wizard.boosterWizard.sla.description') }}
                    </WText>
                    <div class="OptionsGroup row justify-around">
                        <InputNumeric
                            v-model="number"
                            :decimalNum="0"
                            :decimalStrictLimit="true"
                            :input="updateSLA"
                            :label="$t('visualization.settings.sla.number')"
                            standout
                            outlined
                            type="number"
                            class="col-5"
                            labelColor="dark"
                            color="dark"
                        />
                        <QSelect
                            v-model="unit"
                            outlined
                            class="col-5"
                            labelColor="dark"
                            color="dark"
                            :label="$t('visualization.settings.sla.unit')"
                            :options="slaUnits"
                        />
                    </div>
                </q-card>
            </div>
            <div class="row mt-1">
                <div class="backButton">
                    <QBtn
                        v-if="!$route.query.booster"
                        type="submit"
                        noCaps
                        color="primary"
                        style="padding-inline: 40px;"
                        @click="backBtn"
                    >
                        {{ $t('wizard.boosterWizard.sla.backButton') }}
                    </QBtn>
                </div>
                <div class="nextButton">
                    <QBtn
                        type="submit"
                        color="primary"
                        noCaps
                        style="padding-inline: 40px;"
                        @click="nextBtn"
                    >
                        {{ $t('wizard.boosterWizard.sla.nextButton') }}
                    </QBtn>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    Api,
    apiRequest,
    notifyError,
} from '@/api'

import { InputNumeric } from '@/components'

export default {
    name: 'WizardSLA',
    components: { InputNumeric },
    inject: ['App'],
    data () {
        return {
            projectId: this.$route.params.projectId,
            processId: this.$route.params.processId,
            tab: this.$route.params.tab,
            number: 0,
            unit: { value: 'minutes', label: this.$t('visualization.settings.sla.units.minutes') },
            max: 0,
            min: 0,
            slaComplianceAbs: 0,
            slaCompliancePct: 0,
            nonSlaComplianceAbs: 0,
            nonSlaCompliancePct: 0,
            loading: true,
            values: undefined,
        }
    },
    computed: {
        slaUnits () {
            return [
                {
                    label: this.$t('visualization.settings.sla.units.minutes'),
                    value: 'minutes',
                },
                {
                    label: this.$t('visualization.settings.sla.units.hours'),
                    value: 'hours',
                },
                {
                    label: this.$t('visualization.settings.sla.units.days'),
                    value: 'days',
                },
                {
                    label: this.$t('visualization.settings.sla.units.weeks'),
                    value: 'weeks',
                },
                {
                    label: this.$t('visualization.settings.sla.units.months'),
                    value: 'months',
                },
            ]
        },
    },
    mounted () {
        apiRequest(Api().datasets.get({ datasetId: this.processId }))
            .then(({ data }) => {
                this.values = {
                    sla: data[0].sla,
                    caseCompletion: data[0].caseCompletion,
                    cost: data[0].cost,
                }
                if (this.$route.query.booster) {
                    this.number = data[0].sla.value
                    this.unit.value = data[0].sla.unit
                    this.unit.label = this.$t(`visualization.settings.sla.units.${data[0].sla.unit}`)
                }
            })
        const params = { filters: [] }
        apiRequest(Api().visualizations.modelInfo({ processId: this.processId, params }))
            .then((data) => {
                if (!this.$route.query.booster) {
                    const [num, unit] = this.formatDuration(data.duration.med)
                    this.number = num
                    this.unit.value = unit
                    this.unit.label = this.$t(`visualization.settings.sla.units.${unit}`)
                }
                const [maxNum, maxUnit] = this.formatDuration(data.duration.max)
                this.max = `${maxNum} ${this.$t(`visualization.settings.sla.units.${maxUnit}`)}`
                const [minNum, minUnit] = this.formatDuration(data.duration.min)
                this.min = `${minNum} ${this.$t(`visualization.settings.sla.units.${minUnit}`)}`
                this.updateSLA()
            })
            .catch(notifyError)
    },
    methods: {
        updateSLA () {
            this.values.sla = {
                unit: this.unit.value ? this.unit.value : this.unit,
                value: this.number,
            }
            this.getSLAPercentages()
        },
        getSLAPercentages () {
            const params = { value: this.values.sla.value, unit: this.values.sla.unit }
            apiRequest(Api().booster.slaInfo({ processId: this.processId, params }))
                .then((data) => {
                    this.slaComplianceAbs = data.meetSlaAbsolute
                    this.slaCompliancePct = parseFloat((data.meetSlaPercentage * 100).toFixed(2))
                    this.nonSlaComplianceAbs = data.exceedSlaAbsolute
                    this.nonSlaCompliancePct = parseFloat((data.exceedSlaPercentage * 100).toFixed(2))
                })
                .catch((notifyError))
        },
        getColWith (percentage) {
            let res = Math.round(percentage * 0.12)
            res = res > 11 ? 11 : res
            res = res < 1 ? 1 : res
            return res
        },
        formatDuration (duration) {
            if (duration < 3601) {
                return [Math.round(duration / 60), 'minutes']
            }
            if (duration < 180000) {
                return [Math.round(duration / 3600), 'hours']
            }
            if (duration < 604800) {
                return [Math.round(duration / 86400), 'days']
            }
            if (duration < 2419000) {
                return [Math.round(duration / 604800), 'weeks']
            }
            return [Math.round(duration / 2419000), 'months']
        },
        nextBtn () {
            this.updateSLA()
            this.$storage.set('WVS-values', this.values)
            if (this.$route.query.booster) {
                this.$router.push({
                    name: 'WizardUpload',
                    params: {
                        projectId: this.projectId, processId: this.processId,
                    },
                    query: { booster: this.$route.query.booster },
                })
            } else {
                this.$router.push({
                    name: 'WizardUpload',
                    params: {
                        projectId: this.projectId, processId: this.processId,
                    },
                })
            }
        },

        backBtn () {
            this.$router.push({ name: 'WizardChooseApproach', params: { projectId: this.projectId, processId: this.processId } })
        },
    },
}
</script>
<style scoped lang="scss">
    .border-red {
        border: 4px solid red;
    }
    .border-green {
        border: 4px solid limegreen;
    }
    .border-grey {
        border: 2px solid grey;
    }
    .backButton, .nextButton {
        width: 50%;
        display: flex;
        align-items: center;
    }
    .backButton {
        justify-content: left;
    }
    .nextButton {
        justify-content: right;
    }
    .square {
        height: 1.5rem;
        aspect-ratio: 1;
    }
    .title {
        display: flex;
        justify-content: center;
        font-size: 24px;
        margin-top: 3vh;
        @media (max-height:$md) {
            margin-top: 0px;
            font-size: 20px;
        }
    }
    .Subtitle {
        display: flex;
        justify-content: center;
        font-size: 22px;
        font-weight: 600;
        margin-top: 3vh;
        margin-bottom: 3vh;
        @media (max-height:$md) {
            margin-top: 0.5vh;
            margin-bottom: 0vh;
            font-size: 15px;
        }
    }
</style>
