<template>
    <div class="HeaderList flex row-stretch-center">
        <div class="HeaderLeft">
            <slot name="headerLeft" />
        </div>
        <div class="HeaderRight flex row-stretch-center">
            <slot name="headerRight" />
            <label
                v-if="enableArchived"
                class="flex row-left-center"
            >
                <WText
                    :size="12"
                    class="mr-1"
                >
                    {{ $t('general.archived') }}
                </WText>
                <WSwitch
                    id="visibility"
                    name="visibility"
                    :value="modelValue.archived"
                    :disable="disabled"
                    @updateValue="handleInput('archived', $event)"
                />
            </label>
            <Transition
                name="fade"
                mode="out-in"
            >
                <QInput
                    v-if="search"
                    v-model="modelValue.search"
                    dense
                    outlined
                    type="search"
                    :label="$t('actions.search')"
                    class="Search search-input float-left"
                    @keyup.enter="handleInput('search', modelValue.search)"
                >
                    <template #append>
                        <q-icon
                            v-if="modelValue.search"
                            id="clean"
                            name="close"
                            size="20px"
                            clickable
                            style="margin-right: 0.5rem;cursor: pointer;"
                            @click="handleClean"
                        />
                        <q-icon
                            id="search"
                            name="search1"
                            size="20px"
                            clickable
                            style="cursor: pointer;"
                            @click="handleInput('search', modelValue.search)"
                        />
                    </template>
                </QInput>
            </Transition>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'

const ENABLE_ARCHIVED = false

export default {
    name: 'HeaderList',
    props: {
        modelValue: VueTypes.shape({
            archived: VueTypes.bool,
            search: VueTypes.string,
        }),
        search: VueTypes.bool.def(true),
        disabled: VueTypes.bool.def(false),
        enableArchived: VueTypes.bool.def(ENABLE_ARCHIVED),
    },
    methods: {
        handleClean () {
            this.$emit('update:modelValue', { ...this.modelValue, search: '' })
        },
        handleInput (key, value) {
            this.$emit('update:modelValue', { ...this.modelValue, [key]: value })
        },
    },
}
</script>
<style scoped lang="scss">
.Search {
    width: 275px;
}
</style>
