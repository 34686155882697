<template>
    <div class="MainContainer">
        <div class="TablesContainer">
            <div
                class="ActivitiesTable"
            >
                <ImprovementTables
                    :case="activities"
                    :rows="rows"
                    :columns="columns"
                    :cost="cost"
                    @improve="improve"
                    @reset="reset"
                />
            </div>
            <div
                class="TransitionsTable"
            >
                <ImprovementTables
                    :case="transitions"
                    :rows="data"
                    :columns="columns"
                    :cost="cost"
                    @improve="improve"
                    @reset="reset"
                />
            </div>
            <div class="ImprovedScopeContainer">
                <div class="TitleSelect">
                    <div class="Title">
                        {{ $t('booster.improvement.improvementScope.title') }}
                    </div>
                    <QSpace />
                    <QBtn
                        flat
                        icon="history"
                        dense
                        size="md"
                        color="primary"
                        @click="resetAttrValPairs"
                    />
                </div>
                <div class="ImprovedScopeBody">
                    <ImprovementScope
                        :attributes="attributes"
                        :attrValPairs="attrValPairs"
                        @attr-val-pairs="attributeValue"
                    />
                </div>
            </div>
        </div>
        <div class="ContainerChartFigures">
            <div class="PerformanceCharts">
                <div
                    v-if="improvedImpactData"
                    class="Title"
                >
                    {{ $t('booster.improvement.improvementResults') }}
                    {{ App.numberLocationFormat(improvedImpactData.improvedSlaComplianceAbsolute + improvedImpactData.improvedSlaNonComplianceAbsolute, true) }}
                    {{ $t('booster.improvement.improvementFigures.cases') }}
                </div>
                <PerformanceCharts
                    :perfChartsValues="perfChartsValues"
                />
            </div>
            <div class="ImprovementFigures">
                <div class="ImprFigTitle">
                    {{ $t('booster.improvement.improvementFigures.title') }}
                </div>
                <ImprovementFigures
                    :improvementFigures="improvementFigures"
                />
            </div>
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import moment from 'moment'
import {
    Api, apiRequest, notifyError,
} from '@/api'
import ImprovementFigures from './components/ImprovementFigures.vue'
import PerformanceCharts from './components/PerformanceCharts.vue'
import ImprovementTables from './components/ImprovementTables.vue'
import ImprovementScope from './components/ImprovementScope.vue'

export default {
    name: 'Improvement',
    components: {
        ImprovementFigures,
        PerformanceCharts,
        ImprovementTables,
        ImprovementScope,
    },
    inject: ['App'],
    inject: ['App'],
    props: {
        attributes: VueTypes.array,
        cost: VueTypes.object,
        processId: VueTypes.string,
    },
    emits: ['onAnalysisReportLoading', 'onAnalysisReportFinished'],
    data () {
        return {
            attrValPairs: [],
            attribute: undefined,
            selectedValue: undefined,
            activities: 'activities',
            transitions: 'transitions',
            currentImpactData: undefined,
            improvedImpactData: undefined,
            data: undefined,
            rows: undefined,
            columns: [],
            perfChartsValues: undefined,
            activitiesImprovement: [],
            transitionsImprovement: [],
            currentImpactFlag: true,
            improvedImpactFlag: true,
            improvementFigures: {},
        }
    },
    computed: {
        curOptions () {
            return {
                euro: '€',
                dollar: '$',
                pound: '£',
            }
        },
    },
    mounted () {
        this.getCurrentImpact()
        this.getEstimatedImpact()
        this.getImprovementAnalysisReport()
    },
    methods: {
        // reset buttons
        reset (caseName) {
            this[`${caseName}Improvement`] = []
            this.getEstimatedImpact()
        },
        resetAttrValPairs () {
            this.attrValPairs = []
            this.getCurrentImpact()
            this.getEstimatedImpact()
        },
        // add improvement table
        improve (caseName, props) {
            // eslint-disable-next-line no-restricted-globals
            const replace = isNaN(props) ? props.improvement.replace(',', '.') : props
            const improvement = parseFloat(replace)
            const singularCaseName = caseName === 'activities' ? 'activity' : 'transition'
            // // eslint-disable-next-line no-restricted-globals
            const existingCase = this[`${caseName}Improvement`].find(item => item[singularCaseName] === props.name)
            const improvementValue = improvement ? ((100 - (improvement.toFixed(1))) / 100).toFixed(3) : 1
            if (existingCase) {
                existingCase.improvement = improvementValue !== 0 ? improvementValue : 0
            } else {
                this[`${caseName}Improvement`].push({
                    [singularCaseName]: props.name,
                    improvement: improvementValue,
                })
            }
            this.getEstimatedImpact()
        },
        // add pair attribute value
        attributeValue (newattrValPairsArray) {
            this.attrValPairs = newattrValPairsArray
            this.getCurrentImpact()
            this.getEstimatedImpact()
        },
        numFormatter (num) {
            if (num > 9999 && num < 1000000) {
                return `${(num / 1000).toFixed(1) }K`
            } if (num >= 1000000) {
                return `${(num / 1000000).toFixed(1) }M`
            }
            return num
        },

        getCurrentImpact () {
            const params = {
                attributes: this.attrValPairs,
            }
            this.currentImpactFlag = false
            apiRequest(Api().booster.activitiesTransition({ processId: this.processId, params }))
                .then((res) => {
                    this.currentImpactData = res
                })
                .catch((e) => {
                    notifyError(e)
                })
                .finally(() => {
                    this.currentImpactFlag = true
                    this.fillTables()
                })
        },
        getEstimatedImpact () {
            const params = {
                activitiesImprovement: this.activitiesImprovement,
                transitionsImprovement: this.transitionsImprovement,
                attributes: this.attrValPairs,
            }
            this.improvedImpactFlag = false
            apiRequest(Api().booster.overallImprovement({ processId: this.processId, params }))
                .then((res) => {
                    this.improvedImpactData = res
                    this.perfChartsValues = {
                        currentSlaComplianceAbsolute: res.currentSlaComplianceAbsolute,
                        currentSlaNonComplianceAbsolute: res.currentSlaNonComplianceAbsolute,
                        currentSlaCompliancePercentage: res.currentSlaCompliancePercentage,
                        currentSlaNonCompliancePercentage: res.currentSlaNonCompliancePercentage,
                        improvedSlaComplianceAbsolute: res.improvedSlaComplianceAbsolute,
                        improvedSlaNonComplianceAbsolute: res.improvedSlaNonComplianceAbsolute,
                        improvedSlaCompliancePercentage: res.improvedSlaCompliancePercentage,
                        improvedSlaNonCompliancePercentage: res.improvedSlaNonCompliancePercentage,
                        improvedCostAbsolute: res.improvedCostAbsolute,
                        improvedCostPercentage: res.improvedCostPercentage,
                    }
                    // const decreaseCost = (((res.currentDurationAbsolute - res.improvedDurationAbsolute) / 3600) * this.cost.value).toFixed()
                    // const currentCost = ((res.currentDurationAbsolute / 3600) * this.cost.value).toFixed()
                    const daysLabel = this.$t('booster.improvement.improvementFigures.days')
                    const hoursLabel = this.$t('booster.improvement.improvementFigures.hours')
                    const improvedCost = res.improvedCostAbsolute < 0.00 ? 0.00 : res.improvedCostAbsolute.toFixed(2)
                    const decreaseCostPercentage = res.improvedCostPercentage < 0.00 ? 0.00 : res.improvedCostPercentage.toFixed(2)

                    this.improvementFigures = {
                        // eslint-disable-next-line max-len
                        improvedDurationAbsolute: moment.duration((res.currentDurationAbsolute - res.improvedDurationAbsolute), 'seconds').format(`d[ ${daysLabel}] h[ ${hoursLabel}]:m[ min]:s[ sec]`, { largest: 2, trim: false }),
                        decreaseCostPercentage: decreaseCostPercentage !== 0 ? `${decreaseCostPercentage} ` : 0,
                        decreaseDurationPercentage: res.improvedDurationPercentage !== 0 ? `${(res.improvedDurationPercentage).toFixed(2)}` : 0,
                        // eslint-disable-next-line max-len
                        improvedCost: `${this.numFormatter(improvedCost)} ${this.curOptions[this.cost.unit]}`,
                        redBecomeGreenAbsolute: this.numFormatter(res.currentSlaNonComplianceAbsolute - res.improvedSlaNonComplianceAbsolute),
                        // eslint-disable-next-line max-len
                        redBecomeGreenPercentage: (res.currentSlaNonCompliancePercentage - res.improvedSlaNonCompliancePercentage) !== 0 ? `${(res.currentSlaNonCompliancePercentage - res.improvedSlaNonCompliancePercentage).toFixed(2)}` : 0,
                    }
                })
                .catch((e) => {
                    // notifyError(e)
                })
                .finally(() => {
                    this.improvedImpactFlag = true
                    this.fillTables()
                })
        },
        fillTables () {
            if (this.currentImpactFlag && this.improvedImpactFlag) {
                const tableRowsActivity = []
                const tableRowsTransition = []
                const tableColumns = []
                this.currentImpactData.activities.forEach((activity) => {
                    const matchingActivity = this.improvedImpactData.rows.activities.find(item => item.activity === activity.activity)
                    const improvementExists = this.activitiesImprovement.find(item => item.activity === activity.activity)
                    const tableRow = {
                        name: activity.activity,
                        currentImpact: `${activity.totalTime} - ${activity.totalCost.toFixed(1)}`,
                        // eslint-disable-next-line max-len
                        improvement: improvementExists ? (100 - (((this.activitiesImprovement.find(item => item.activity === activity.activity).improvement)) * 100)).toFixed(1) : (0).toFixed(1),
                        // estimatedImpact: improvementExists ? activity.totalTime - matchingActivity.totalTime : matchingActivity.totalTime,
                        estimatedImpact: `${matchingActivity.totalTime} - ${matchingActivity.totalCost.toFixed(1)}`,
                    }
                    tableRowsActivity.push(tableRow)
                })

                this.currentImpactData.transitions.forEach((transition) => {
                    const improvementExists = this.transitionsImprovement.find(item => item.transition === transition.transition)
                    const matchingTransition = this.improvedImpactData.rows.transitions.find(item => item.transition === transition.transition)
                    const tableRow = {
                        name: transition.transition,
                        currentImpact: `${transition.totalTime} - ${transition.totalCost.toFixed(1)}`,
                        // eslint-disable-next-line max-len
                        improvement: improvementExists ? (100 - (((this.transitionsImprovement.find(item => item.transition === transition.transition).improvement)) * 100)).toFixed(1) : (0).toFixed(1),
                        // estimatedImpact: improvementExists ? transition.totalTime - matchingTransition.totalTime : matchingTransition.totalTime,
                        estimatedImpact: `${matchingTransition.totalTime} - ${matchingTransition.totalCost.toFixed(1)}`,
                    }
                    tableRowsTransition.push(tableRow)
                })
                tableRowsActivity.sort((a, b) => a.name.localeCompare(b.name))
                tableRowsTransition.sort((a, b) => a.name.localeCompare(b.name))

                tableColumns.push({ name: 'name', align: 'left', label: `${this.$t('booster.improvement.improvementTables.name')}` })
                tableColumns.push({ name: 'currentImpact', align: 'left', label: `${this.$t('booster.improvement.improvementTables.currentImpact')}` })
                tableColumns.push({ name: 'improvement', align: 'left', label: `${this.$t('booster.improvement.improvementTables.improvement')}` })
                tableColumns.push({ name: 'estimatedImpact', align: 'left', label: `${this.$t('booster.improvement.improvementTables.estimatedImpact')}` })
                this.rows = tableRowsActivity
                this.data = tableRowsTransition
                this.columns = tableColumns
            }
        },
        getImprovementAnalysisReport () {
            this.$emit('onAnalysisReportLoading')
            const params = { format: 'html' }
            apiRequest(Api().booster.whatIfD2T({ processId: this.processId, params }))
                .then((data) => {
                    const { analysisReport } = data
                    this.analysisReport = analysisReport
                })
                .catch(notifyError)
                .finally(() => {
                    this.$emit('onAnalysisReportFinished', this.analysisReport)
                })
        },
    },
}
</script>
<style lang="scss" scoped>
.MainContainer {
    height: 99%;
    width:100%;
    display:flex;
    flex-direction:row;
}
.ImprovedScopeContainer {
    height: 32%;
    background-color: #fbfbfb;
}
.ActivitiesTable {
    height: 33%;
}
.TransitionsTable {
    height:34%;
}
.ImprovementFigures {
    height:50%;
    width:100%;
    background-color:#fbfbfb;
}
.PerformanceCharts {
    height: 50%;
}
.ContainerChartFigures {
    height:100%;
    width:50%;
    margin-right: 2%;
}
.TablesContainer {
    height:95%;
    width:55%;
    padding-right:2vw;
    display: grid;
    gap: 1%;
}

.ImprFigTitle {
    width:90%;
    font-size: 15px;
    font-weight: bold;
    color:$primary;
    display: flex;
    margin: 0 auto;
    padding-top: 10px;
}
.TitleSelect {
    display: flex;
    flex-direction:row;
    background-color: white;
}
.Title {
    padding: 0.5vh 0px;
    height: fit-content;
    font-size: medium;
    @media screen and (min-height: $md) {
        font-size: large;
    }
}

</style>
