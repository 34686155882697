<template>
    <div class="Process">
        <div class="Header flex row-stretch-center">
            <div class="HeaderLeft flex row-center">
                <q-icon
                    name="arrow_back_ios_new"
                    size="20px"
                    color="primary"
                    clickable
                    class="mr-1"
                    @click="handleBack"
                />
                <Breadcrumb />
            </div>
            <div class="HeaderRight">
                <div class="button-container flex-row-center">
                    <QBtn
                        v-if="boosterButtonAvailable"
                        color="primary"
                        padding="5px 7px"
                        noCaps
                        :disable="boosterButtonDisabled"
                        @click="toggleView"
                    >
                        {{ viewMode === 'visualization' ? $t('booster.visualizationToBooster') : $t('booster.customAnalysis') }}
                        <QTooltip
                            v-if="boosterButtonDisabled"
                        >
                            {{ $t('booster.boosterDisabledTooltip') }}
                        </QTooltip>
                    </QBtn>
                    <UserAvatar
                        direction="bottomLeft"
                        class="ml-1"
                    />
                </div>
            </div>
        </div>
        <div class="Container flex row-stretch">
            <component
                :is="viewMode === 'visualization' ? 'Visualization' : 'Overview'"
                class="flex-1"
                @booster-button-enabled="handleBoosterButtonEnabled"
                @booster-button-disabled="handleBoosterButtonDisabled"
                @booster-button-hidden="handleBoosterButtonHidden"
            />
        </div>
    </div>
</template>
<script>
import { Breadcrumb, UserAvatar } from '@/components'
import Visualization from './Visualization/Visualization.vue'
import Overview from './Booster/Overview.vue'

export default {
    name: 'Process',
    components: {
        Breadcrumb,
        Visualization,
        UserAvatar,
        Overview,
    },
    data () {
        return {
            viewMode: 'visualization', // Inicialmente en la vista de Visualization
            tabVisualization: 'MODEL', // tab en la que estaba antes de abrir booster
            boosterButtonDisabled: true,
            boosterButtonAvailable: false,
        }
    },
    watch: {
        '$route.fullPath': {
            handler: 'determineInitialView',
            immediate: true,
        },
    },
    mounted () {
        this.determineInitialBoosterButton()
    },
    methods: {
        handleBack () {
            if (this.viewMode === 'booster') {
                this.$router.back()
            }
            this.$router.back()
        },
        // handleGoToBooster () {
        //     const { projectId } = this
        //     this.$router.push({ name: 'ProcessBooster', params: { projectId, tab: 'subview' } })
        // },
        determineInitialView () {
            const currentRoute = this.$route.fullPath
            const segments = currentRoute.split('/')
            // Verifica si "booster" está en la ruta, y si es así, muestra la vista del booster
            if (segments.includes('booster')) {
                this.viewMode = 'booster'
            } else {
                this.viewMode = 'visualization'
                this.tabVisualization = segments[segments.length - 1]
            }
        },
        determineInitialBoosterButton () {
            if (this.viewMode === 'booster') {
                this.boosterButtonDisabled = false
                this.boosterButtonAvailable = true
            }
        },
        toggleView () {
            const currentRoute = this.$route.fullPath
            const segments = currentRoute.split('/')
            if (this.viewMode === 'visualization') {
                const index = segments.indexOf('visualization')
                if (index !== -1) {
                    segments.splice(index, 2, 'booster/OVERVIEW')
                    const newRoute = segments.join('/')
                    this.$router.push(newRoute)
                }
            } else {
                const index = segments.indexOf('booster')
                if (index !== -1) {
                    segments.splice(index, 2, `visualization/${ this.tabVisualization }`)
                    // const newRoute = segments.join('/')
                    this.$router.push({ name: 'ProcessVisualization', params: { tab: this.tabVisualization } })
                }
            }
        },
        handleBoosterButtonEnabled () {
            this.boosterButtonDisabled = false
        },
        handleBoosterButtonDisabled () {
            this.boosterButtonDisabled = true
        },
        handleBoosterButtonHidden () {
            this.boosterButtonAvailable = true
        },
    },
}
</script>
    <style scoped lang="scss">
    .Process {
        .Header {
        height: $subheader-height;
        width: 100%;
        border-bottom: 1px solid $border-color;
        position: fixed;
        z-index: 5999;
        background: $white;
        padding: 0 20px;
        box-sizing: border-box;
        }
        & > .Container {
        min-height: calc(100vh - #{$header-height} - #{$subheader-height})
        }
        .Screen {
        padding: 20px;
        }
    }
    .HeaderRight {
        display: flex;
        align-items: center;
    }
    .button-container {
        display: flex;
        align-items: center;
    }
    .button-container .ml-1 {
        margin-left: 5px; /* Ajusta el espacio entre el botón y el avatar según tus necesidades */
    }
    </style>
