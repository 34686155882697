<template>
    <label
        class="WSwitch"
        :class="[color, { error, disabled }]"
    >
        <input
            :id="id"
            :name="name"
            :checked="value"
            :disabled="disabled"
            :class="['Input', inputStyles]"
            type="checkbox"
            v-on="inputListeners"
        >
        <span class="Slider" />
    </label>
</template>

<script>
import VueTypes from 'vue-types'
import { merge } from 'lodash'

export default {
    name: 'WSwitch',
    props: {
        value: VueTypes.bool.isRequired,
        id: VueTypes.string,
        name: VueTypes.string.isRequired,
        color: VueTypes.oneOf(['primary', 'secondary', '']).def('secondary'),
        error: VueTypes.bool.def(false),
        disabled: VueTypes.bool.def(false),
    },
    emits: ['updateValue'],
    computed: {
        inputListeners () {
            return merge({}, this.$attrs, {
                input: (event) => {
                    this.$emit('updateValue', event.target.checked)
                },
            })
        },
        inputStyles () {
            return { checked: this.value }
        },
    },
}
</script>

<style scoped lang="scss">
$width: 50px;
$space: 4px;
$height: calc($width / 2) + $space;
$size: $height - 8;

.WSwitch {
    position: relative;
    display: inline-block;
    width: $width;
    height: $height;

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked:not(.disabled) {
            & + .Slider {
                background-color: $secondary;

                &:before {
                    left: 0;
                    transform: translateX($width - $size - $space);
                }
            }
        }

        &:focus + .Slider {
            box-shadow: 0 0 1px $secondary;
        }
    }

    .Slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: $height;
        background-color: $gray-04;
        transition: .4s;
        cursor: pointer;

        &:before {
            position: absolute;
            content: "";
            height: $size;
            width: $size;
            bottom: 4px;
            left: $space;
            border-radius: 50%;
            background-color: $white;
            transition: .4s;
        }
    }

    &.primary {
        input {
            &:checked + .Slider {
                background-color: $primary;
            }

            &:focus + .Slider {
                box-shadow: 0 0 1px $primary;
            }
        }
    }

    &.secondary {
        input {
            &:checked + .Slider {
                background-color: $secondary;
            }

            &:focus + .Slider {
                box-shadow: 0 0 1px $secondary;
            }
        }
    }

    &.error {
        input {
            &:checked + .Slider {
                background-color: $danger;
            }

            &:focus + .Slider {
                box-shadow: 0 0 1px $danger;
            }
        }
    }

    &.disabled {
        input {
            &:checked + .Slider {
                opacity: .3;
            }
            &:focus + .Slider {
                opacity: .3;
            }
        }
    }
}
</style>
