<template>
    <Modal
        :isOpen="isOpen"
        class="Modal"
        @close="closeModal"
    >
        <template #header>
            <div class="custom-modal-header">
                <WText
                    tag="h3"
                    :size="16"
                    weight="semi"
                    class="mb-0"
                >
                    {{ $t('projects.datasets.moveDataset') }}
                </WText>
            </div>
        </template>
        <template #body>
            <QTabs
                v-model="sharedTab"
                activeColor="primary"
                indicatorColor="primary"
                class="q-mt-md"
            >
                <QTab
                    name="my_organizations"
                    :label="$t('projects.datasets.createdByMe')"
                    @click="toggleShared(false, 'my_organizations')"
                />
                <QTab
                    name="shared_organizations"
                    :label="$t('projects.datasets.sharedWithMe')"
                    @click="toggleShared(true, 'shared_organizations')"
                />
            </QTabs>
            <div class="q-my-md">
                <div
                    v-for="organization in organizations"
                    :key="organization.uuid"
                    class="organization-item"
                >
                    <QItem
                        clickable
                        @click="loadProjects(organization)"
                    >
                        <QItemSection avatar>
                            <QIcon name="folder" />
                        </QItemSection>
                        <QItemSection>{{ organization.name }}</QItemSection>
                    </QItem>
                    <div
                        v-if="selectedOrganization && selectedOrganization.uuid === organization.uuid"
                        class="projects-list"
                    >
                        <div
                            v-for="project in projects"
                            :key="project.uuid"
                            class="project-item"
                            style="padding-left: 20px"
                        >
                            <QItem
                                clickable
                                :class="{ 'selected-project': selectedProject && selectedProject.uuid === project.uuid }"
                                @click="selectProject(project)"
                            >
                                <QItemSection avatar>
                                    <QIcon name="folder_open" />
                                </QItemSection>
                                <QItemSection>{{ project.name }}</QItemSection>
                                <QItemSection avatar>
                                    <QIcon
                                        :name="selectedProject && selectedProject.uuid === project.uuid ? 'check_box' : 'check_box_outline_blank'"
                                        color="primary"
                                    />
                                </QItemSection>
                            </QItem>
                        </div>
                    </div>
                </div>
            </div>
            <QForm
                class="align-center q-gutter-md q-my-md"
                @submit.prevent="moveDataset"
            >
                <div class="justify-end row q-my-lg">
                    <QBtn
                        noCaps
                        unelevated
                        color="primary"
                        type="submit"
                        :label="$t('projects.datasets.move')"
                    />
                </div>
            </QForm>
        </template>
    </Modal>
</template>

<script>
import VueTypes from 'vue-types'
import Modal from '@/components/Modal/Modal.vue'

import {
    apiRequest, Api, notifyError, notifySuccess, notify,
} from '@/api'

export default {
    name: 'MoveDatasetModal',
    components: {
        Modal,
    },
    props: {
        isOpen: VueTypes.bool.def(false),
        dataset: VueTypes.object.isRequired,
        currentProjectId: VueTypes.string.isRequired,
    },
    emits: ['close', 'move'],
    data () {
        return {
            organizations: [],
            groups: [],
            projects: [],
            datasets: [],
            selectedOrganization: null,
            selectedProject: null,
            selectedDataset: null,
            shared: false,
            sharedTab: 'my_organizations',
        }
    },
    created () {
        this.loadOrganizations()
    },
    methods: {
        toggleShared (isShared, tabName) {
            if (this.sharedTab !== tabName) {
                this.sharedTab = tabName
                this.shared = isShared
                this.loadOrganizations()
            }
        },
        async loadOrganizations () {
            try {
                const params = {
                    shared: this.shared,
                }
                const { data } = await apiRequest(Api().organizations.list(params))
                this.organizations = data
            } catch (error) {
                notifyError(error)
            }
        },
        async loadProjects (organization) {
            this.selectedOrganization = organization
            this.projects = []
            if (this.selectedOrganization) {
                try {
                    const params = {
                        organizationUid: this.selectedOrganization.uuid,
                    }
                    const { data } = await apiRequest(Api().projects.list(params))
                    this.projects = data
                    this.datasets = []
                } catch (error) {
                    notifyError(error)
                }
            }
        },
        async loadDatasets () {
            if (this.selectedProject) {
                try {
                    const params = {
                        projectUid: this.selectedProject.uuid,
                    }
                    const { data } = await apiRequest(Api().datasets.list(params))
                    this.datasets = data
                } catch (error) {
                    notifyError(error)
                }
            }
        },
        selectProject (project) {
            this.selectedProject = project
        },
        async moveDataset () {
            if (!this.dataset || !this.dataset.uuid || !this.selectedProject) {
                notify({ message: this.$t('projects.datasets.missingData') })
                return
            }
            if (this.selectedProject.uuid === this.currentProjectId) {
                notify({ message: this.$t('projects.datasets.sameProjectError') })
                return
            }
            const params = {
                datasetId: this.dataset.uuid,
                targetProjectId: this.selectedProject.uuid,
            }
            try {
                await apiRequest(Api().datasets.move(params))
                notifySuccess(this.$t('projects.datasets.moveSuccess'))

                this.$emit('moved')
            } catch (error) {
                notifyError(error)
            }
        },
        closeModal () {
            this.$emit('close')
        },
    },
}
</script>
<style scoped lang="scss">
.Modal {
    &:deep(.Dialog) {
        width: 80vw;
        max-width: 600px;
    }
}
.custom-modal {
    &:deep(.Dialog) {
        width: 80vw;
        max-width: 800px !important;
    }
}

.custom-modal-header,
.custom-modal-body {
    width: 100%;
}
.Field {
    width: 100%;
    height: auto;
    &:deep(.q-field__control) {
        height: 50px;
        .q-field__control-container .q-field__native span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            line-height: 34px;
        }
        .q-field__append {
            height: 50px;
        }
    }
}
.titleName {
    overflow: hidden;
    text-overflow: ellipsis;
    color: $primary;
    font-size: 18px;
    white-space: nowrap;
    font-weight: bold;
}
.actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}
</style>
