export default {
    visualizationToBooster: 'Process Booster',
    boosterDisabledTooltip: 'You must configure the necessary conditions to access the Booster',
    customAnalysis: 'Custom Analysis',
    performanceBoosterTitle: 'Performance Booster',
    overview: {
        process: 'Process Overview',
        description: 'Understand how your process currently performs',
        slaNotDefined: 'Configure the SLA conditions to display the data.',
        caseCompletionNotDefined: 'Configure the case completion conditions to display the data.',
        slaAndCaseNotDefined: 'Configure the SLA conditions and the case completion conditions to display the data.',
        noConditionsText: 'You can define the necessary conditions in the dataset configuration.',
        costNotDefined: 'Configure the Cost conditions to display the data.',
    },
    prediction: {
        process: 'Process Prediction',
        description: 'Find out how your process will perform',
        predictionHighlights: {
            name: 'Prediction highlights',
            cases: 'cases',
            ongoingCases: 'ongoing cases ',
            meetingSLA: 'in condition of ',
            meetingSLAColor: 'meeting SLA',
            missingSLAColor: 'under risk ',
            missingSLA: 'of missing SLA',
            exceeding: 'already ',
            exceedingColor: 'exceeding SLA',
        },
        predictedPerformance: {
            name: 'Predicted performance by ',
            risk: 'Risk estimation of ongoing predictions',
            riskLabel: 'Risk',
            duration: 'Predicted duration versus past duration',
            durationLabel: 'Dur. SLA',
            distribution: 'SLA performance distribution',
            distributionLabel: 'Distr. SLA',
            past: 'Past',
            predicted: 'Predicted',
            finishedAbove: 'Finished above Sla',
            finishedUnder: 'Finished under Sla',
            finished: 'Finished',
            ongoingAbove: 'Ongoing above Sla',
            ongoingUnder: 'Ongoing under Sla',
            finishedOngoing: 'Finished - Ongoing',
            pastPredicted: 'Past - Predicted',
            ongoing: 'Ongoing',
            pastMonths: 'Past ',
            months: ' months',
        },
        ongoingCases: {
            showOnly: 'Show only for ',
            ongoingCaseList: 'Ongoing case list',
            state: 'State',
            caseId: 'Case ID',
            currentDuration: 'Current duration',
            estimatedDuration: 'Estimated duration',
            Good: 'Good',
            Risk: 'Risk',
            above: 'Above SLA',
            select: 'Please, select a value',

        },
        timeToMeet: {
            timeToMeet: 'Time to meet ',
            improvementRate: 'Minimum monthly improvement rate for meeting ',
            improvementRateSla: 'Minimum monthly improvement rate for ',
            improvementRateSecondPart: ' in ',
            past: 'Past ',
            months: ' months',
            bestPerforming: 'current best performing ',
        },
    },
    state: {
        performanceBy: 'Performance by ',
        rightClick: 'Right click on a bar to view graph',
        leadTimesBy: 'Lead times by ',
        timeSpentPerCase: 'Average time spent per case',
        performanceComparison: 'performance comparison',
        leadTimes: ' lead times',
        cases: ' cases',
        selectFilteringElements: 'Currently selected elements for resource filtering',
        evolutionOverTime: 'Performance evolution over time',
        overallPerformance: 'Overall performance by:',
        activity: 'Activity',
        transition: 'Transition',
        variant: 'Variant',
        months: 'months',
        weeks: 'weeks',
        all: 'All',
        below: 'Below SLA',
        intermediate: 'Intermediate',
        above: 'Above SLA',
        slaCompliance: 'SLA compliance',
        noTraces: 'No traces found for the current filters',
        performanceByClick: 'Click a left chart bar to select',
    },
    improvement: {
        process: 'Process Improvement',
        description: 'Discover how to improve the performance of your process',
        activitiesImprove: 'Process activities to improve',
        transitionsImprove: 'Process transitions to improve',
        improvementResults: 'Improvement results on ',
        improvementTables: {
            rule1: 'Only numbers',
            rule2: '0-100',
            name: 'Name',
            currentImpact: 'Current Impact',
            improvement: 'Improvement',
            estimatedImpact: 'Estimated Impact',
        },
        improvementFigures: {
            title: 'Improvement figures',
            cases: ' cases',
            red: 'red ',
            green: ' green',
            become: 'that become',
            decrease: 'decrease',
            overallDuration: ' in overall duration',
            overallCosts: ' in overall costs',
            days: ' days',
            hours: 'hours',
            seconds: 'sec',
            currentPerformance: 'Current performance',
            improvedPerformance: 'Improved performance',
            slaCompliance: 'SLA compliance',
            slaNonCompliance: 'SLA non compliance',
        },
        improvementScope: {
            title: 'Improvement scope',
            add: 'Select and add attribute-value pairs to constrain the improvement scope',
            attributes: 'Attributes',
            values: 'Values',
        },
    },
    tabs: {
        overview: 'Overview',
        prediction: 'Prediction',
        improvement: 'Improvement',
    },
    d2t: {
        viewReport: 'View report',
        analysisReport: 'Analysis Report',
        copyToClipboard: 'Copy to clipboard',
        copied: 'Copied!',
    },
}
