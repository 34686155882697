<template>
    <div class="assistant_window">
        <div
            v-if="isLoading"
            class="loading-container"
        >
            <q-inner-loading :showing="isLoading" />
        </div>
        <div
            v-else
            visible
            class="chat"
        >
            <div
                v-if="noChat"
            >
                <div class="q-pa-lg q-gutter-md">
                    <QBanner
                        rounded
                        style="max-width: 70%; margin: 0 auto;"
                        class="bg-warning text-white w-half"
                    >
                        {{ $t('assistant.labsDisclaimer') }}
                        <template #avatar>
                            <QIcon
                                name="warning"
                                style="font-size: 3em;"
                            />
                        </template>
                        <template
                            #action
                            class="flex justify-center q-mt-2"
                        >
                            <QBtn
                                class="bg-white text-warning text-weight-bold q-my-md"
                                :label="$t('assistant.upload')"
                                @click="toggleCheckbox"
                            />
                        </template>
                    </QBanner>
                </div>
                <!-- <div -->
                <!--     class="uploadDatasetSwitch" -->
                <!-- > -->
                <!--     <div class="uploadDatasetSwitchExplanation"> -->
                <!--         <div -->
                <!--             class="disclaimer text-weight-bold" -->
                <!--         > -->
                <!--             {{ $t('assistant.labsDisclaimer') }} -->
                <!--         </div> -->
                <!--         {{ $t('assistant.upload') }} -->
                <!--     </div> -->
                <!--     <label -->
                <!--         class="switch" -->
                <!--     > -->
                <!--         <input -->
                <!--             type="checkbox" -->
                <!--             @click="toggleCheckbox" -->
                <!--         > -->
                <!--         <div -->
                <!--             class="slider round" -->
                <!--         /> -->
                <!--     </label> -->
                <!-- </div> -->
            </div>
            <div
                v-else
                class="q-ma-sm row"
            >
                <div class="">
                    <QBtn
                        color="primary"
                        icon="refresh"
                        noCaps
                        class="q-mr-sm"
                        @click="restartConversation"
                    >
                        {{ $t('assistant.restartConversation') }}
                    </QBtn>
                    <QBtn
                        color="primary"
                        icon="upload"
                        noCaps
                        class="q-mr-sm"
                        @click="openModal"
                    >
                        {{ $t('assistant.reuploadDataset') }}
                    </QBtn>
                    <QBtn
                        color="primary"
                        icon="download"
                        noCaps
                        class="q-mr-sm"
                        @click="downloadChatHistory"
                    >
                        {{ $t('assistant.downloadConversation') }}
                    </QBtn>
                </div>
                <div class="col align-right">
                    <QChip
                        square
                        textColor="white"
                        icon="warning"
                        color="warning"
                    >
                        {{ $t('assistant.labs') }}
                        <QTooltip
                            anchor="bottom middle"
                            self="center middle"
                            :offset="[20, 20]"
                        >
                            {{ $t('assistant.labsDisclaimer') }}
                        </QTooltip>
                    </QChip>
                </div>
            </div>
            <div
                v-if="!noChat"
                class="chatContainer"
            >
                <div
                    v-if="!noChat"
                    class="messagesShow"
                >
                    <div
                        v-if="showModal"
                        class="modal-overlay"
                    >
                        <div
                            v-if="showModal"
                            class="modal"
                        >
                            <p>{{ $t('assistant.reuploadWarning') }}</p>
                            <div class="flex flex-center q-my-sm">
                                <QBtn
                                    class="button"
                                    color="primary"
                                    noCaps
                                    style="margin-right: 5%;"
                                    :label="$t('assistant.yes')"
                                    @click="reuploadDataset"
                                />
                                <QBtn
                                    class="button"
                                    color="primary"
                                    noCaps
                                    style="margin-right: 5%;"
                                    :label="$t('assistant.no')"
                                    @click="closeModal"
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        class="messageList"
                    >
                        <div
                            v-if="isThinking"
                            class="thinking"
                        >
                            {{ $t('assistant.thinking') }}<span class="dots">.</span>
                        </div>
                        <Message
                            v-for="message in messages"
                            :key="message.messageId"
                            :time="message.messageTimestamp"
                            :class="['message', { 'user-message': !(message.messageAuthor === 'User') },
                                     { 'right': message.messageAuthor === 'User', 'other-message': message.messageAuthor !== 'User' }]"
                            :dark="message.messageAuthor === 'User'"
                            :text="getMessageText(message.messageContent)"
                            :author="message.author"
                        />
                    </div>
                    <div class="chatBoxContainer">
                        <div>
                            <ChatBox
                                class="chat-box"
                                @submit="onSubmit"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import {
    ApiLLM, apiRequest, notifyError,
} from '@/api'
import { ChatBox, Message } from '.'

export default {
    name: 'Assistant',
    components: {
        ChatBox,
        Message,
    },
    props: {
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        showDismissed: VueTypes.bool,
    },
    data () {
        return {
            isThinking: false,
            isLoading: true,
            noChat: false,
            showModal: false,
            messages: [
            ],
        }
    },
    computed: {
    },
    created () {
        this.getChat()
    },
    methods: {
        formatDate (timestamp) {
            const date = new Date(timestamp)
            return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
        },
        downloadChatHistory () {
            // Suponiendo que `this.chatHistory` es un array que contiene tu historial de chat
            const chatHistoryText = this.messages.slice().reverse().map(message => `"messageAuthor": "${message.messageAuthor}"\n` +
                `${this.formatDate(message.messageTimestamp)}\n` +
                `${this.getMessageText(message.messageContent)}\n`).join('\n')
            const blob = new Blob([chatHistoryText], { type: 'text/plain' })
            const url = URL.createObjectURL(blob)

            const link = document.createElement('a')
            link.href = url
            link.download = 'chat_history.txt'
            link.click()

            URL.revokeObjectURL(url)
        },
        getMessages () {
            return this.messages.reverse()
        },
        getMessageText (text) {
            return (text === '' || !text)
                ? this.$t('assistant.emptyMessage')
                : text
        },
        async restartConversation () {
            this.isLoading = true
            const response = await this.restartThread()
            this.messages = []
            this.addMessage([response])
            this.isLoading = false
        },
        openModal () {
            this.showModal = true
        },
        closeModal () {
            this.showModal = false
        },
        async reuploadDataset () {
            this.showModal = false
            this.isLoading = true
            await this.substituteFile()
            this.getChat()
        },
        onRegister (event, name) {
            event.preventDefault()
            this.user = { name, id: this.uid() }
        },
        uid () {
            return Math.random().toString(36).substring(2) + Date.now().toString(36)
        },
        addMessage (message) {
            this.messages = message.reverse().concat(this.messages)
        },
        async getChat () {
            const history = await this.getHistory()
            this.messages = []
            if (history === '') {
                this.noChat = true
                this.isLoading = false
            } else if (history.length) {
                this.messages = this.messages.concat(history)
                this.messages = this.messages.reverse()
                this.isLoading = false
            } else if (history.length === 0) {
                this.isLoading = false
            }
        },
        async toggleCheckbox () {
            this.isLoading = true
            if (this.noChat) {
                try {
                    await this.startChat()
                } catch (error) {
                    console.error('Error during uploaddataset:', error)
                }
            }
            this.isLoading = false
        },
        async startChat () {
            try {
                const response = await this.uploadFileAndInitThread()
                if (response) {
                    this.addMessage(response)
                }
                this.noChat = false
            } catch (error) {
                console.error('Error during startThread:', error)
            }
        },
        // This method will be called when a new message is sent
        async onSubmit (event, textReceived) {
            event.preventDefault()
            this.isThinking = true
            const text = encodeURIComponent(textReceived)
            const response = await this.sendMessage({
                text,
            })
            if (response === undefined) {
                console.log('error')
            }
            if (response !== undefined) {
                this.addMessage(response)
            }
            this.isThinking = false
        },

        async getHistory () {
            const params = { }
            let messages
            await apiRequest(ApiLLM().assistantChat.getHistory({ processId: this.processId, params }))
                .then((response) => {
                    messages = response
                })
                .finally(() => {
                })
            return messages
        },
        async uploadFileAndInitThread () {
            const params = { }
            let messages
            await apiRequest(ApiLLM().assistantChat.uploadFileAndInitThread({ processId: this.processId, params }))
                .then((response) => {
                    messages = [response]
                })
            return messages
        },
        async substituteFile () {
            const params = { }
            let messages
            await apiRequest(ApiLLM().assistantChat.substituteFile({ processId: this.processId, params }))
                .then((response) => {
                    messages = response
                })
                .finally(() => {
                })
            return messages
        },

        async sendMessage (message) {
            const { processId } = this.$route.params
            const { text } = message
            let messages
            await apiRequest(ApiLLM().assistantChat.sendMessage({ processId, text, params: { } }))
                .then((response) => {
                    messages = response
                })
                .catch((error) => {
                    console.log(error)
                    notifyError({
                        result: {
                            response: {
                                data: { errorMessage: this.$t('assistant.errorSendMessage') },
                                status: 500,
                            },
                        },
                    })
                })
                .finally(() => {
                })
            return messages
        },
        async restartThread () {
            const params = { }
            let messages
            await apiRequest(ApiLLM().assistantChat.restartThread({ processId: this.processId, params }))
                .then((response) => {
                    messages = response
                })
                .catch(notifyError)
                .finally(() => {
                })
            return messages
        },

    },
}
</script>
<style scoped lang="scss">
.assistant_window {
    width: 100%;

}
.right_warning {
    display: flex;
    justify-content: flex-end;
    margin-right: 5%;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

input {
  border: 0;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.1);
}

.chatContainer {
  display: flex;
  padding: 1rem;
  width: 100%;
  height:calc(100% - 40px);
  overflow-y: auto;
  flex-direction: column-reverse;
}
.chat {
    height:calc(100% - 88px);
}

.messageList {
    flex-direction: column-reverse;
    overflow-y: auto;
    display: flex;
    // margin-bottom: 10px;
}
.chatBoxContainer {
    padding-top: 10px;
    background-color: white;
    bottom: 0;
    left: 1em;
    right: 1em;
    position: absolute;
}
.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.message + .message {
  margin-top: 1rem;
  margin-bottom: 20px;
}

.message.right {
  margin-left: auto;
  margin-right: 15px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Optional: semi-transparent background */
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: green;
}

input:focus + .slider {
  box-shadow: 0 0 1px #101010;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal {
  background-color: white;
  padding: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.uploadDatasetSwitch {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.uploadDatasetSwitchExplanation {
    flex-direction: column;
    font-size: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.disclaimer {
  padding: 20px;
  background-color: #fcf8e4;
  color: #fcd060; /* color del texto */
  border-radius: 5px; /* bordes redondeados */
  width:70%; /* ancho del recuadro */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15); /* sombra */
  margin: 10px; /* margen */
  word-wrap: break-word; /* permite que el texto se parta en varias líneas */;
  font-size: 0.7em;
}
.message {
  background: #e7e7e7;
  border-radius: 10px;
  padding: 1rem;
  width: fit-content;
  word-wrap: break-word;
  max-width: 80%;
}

.switch {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dots {
    animation: dots 3s steps(5, end) infinite;
}

.thinking {
    font-size: 1.5em;
}

.custom-color {
  background-color: #fcdb32;
}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .3em 0 0 rgba(0,0,0,0),
      .6em 0 0 rgba(0,0,0,0);
  }
  40% {
    color: black;
    text-shadow:
      .3em 0 0 rgba(0,0,0,0),
      .6em 0 0 rgba(0,0,0,0);
  }
  60% {
    text-shadow:
      .3em 0 0 black,
      .6em 0 0 rgba(0,0,0,0);
  }
  80%, 100% {
    text-shadow:
      .3em 0 0 black,
      .6em 0 0 black;
  }
}
</style>
