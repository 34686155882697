<template>
    <div
        class="SettingsModal"
    >
        <div
            ref="SettingsContainer"
            class="SettingsContainer"
        >
            <SettingsModalSection
                v-if="settings && !attributesLoading"
                :values="settings"
                :attributes="attributes"
                :relevantAttributes="relevantAttributes"
                :totalInfo="totalInfo"
                @SettingsConfig="saveSettings"
                @UpdateRelevant="handleUpdateRelevant"
            />
            <div
                v-else
                class="flex justify-center items-center"
                style="height:400px;"
            >
                <QCircularProgress
                    indeterminate
                    rounded
                    size="50px"
                    color="primary"
                    class="q-ma-md"
                />
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import {
    Api,
    apiRequest,
    notifyError,
    notifySuccess,
} from '@/api'
import SettingsModalSection from './components/SettingsModalSection.vue'

export default {
    name: 'SettingsModal',
    components: { SettingsModalSection },
    mixins: [],
    props: {
        attributes: VueTypes.array,
        processId: VueTypes.oneOfType([String, Number]),
        settings: VueTypes.oneOfType([Array, Object]),
        relevantAttributes: VueTypes.String,
        attributesLoading: VueTypes.bool,
        totalInfo: VueTypes.object,
    },
    emits: ['settingsSaved', 'relevantAttributesUpdated'],
    data () {
        return {
        }
    },
    methods: {
        saveSettings (settings) {
            const settingsToSave = {}

            const processSetting = (s) => {
                if (s.type === 'activityCosts') {
                    settingsToSave[s.type] = Object.values(s.data)
                } else if (typeof s.data !== 'string' && s.type !== 'variantsSla' && s.type !== 'customSla') {
                    settingsToSave[s.type] = { ...s.data }
                } else {
                    settingsToSave[s.type] = s.data
                }
            }

            if (Array.isArray(settings)) {
                settings.forEach(processSetting)
            } else {
                processSetting(settings)
            }

            apiRequest(Api().datasets.update({
                datasetId: this.$route.params.processId,
                params: settingsToSave,
            }))
                .then(() => {
                    this.$emit('settingsSaved')
                    notifySuccess(this.$t('visualization.settings.settingsSaved'))
                })
                .catch(notifyError)
        },
        async updateAttributes (relevant) {
            const datasetId = this.$route.params.processId
            apiRequest(Api().datasets.updateRelevant({ datasetId, context: 'SETTINGS', params: { columnNames: relevant } }))
                .then((data) => {
                    notifySuccess(this.$t('visualization.settings.settingsSaved'))
                    this.$emit('relevantAttributesUpdated', relevant)
                })
                .catch(notifyError)
        },
        handleUpdateRelevant (relevant) {
            this.updateAttributes(relevant)
        },
    },
}
</script>
<style lang="scss" scoped>
.SettingsModal {
    min-height: fit-content;
    width: min(95%, 1600px);
    margin-bottom: 20px;
    top: $subheader-height;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background: white;

}
</style>
