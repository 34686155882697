<template>
    <div
        class="ContextualInfo"
    >
        <div class="row justify-between">
            <div class="row items-center">
                <QIcon
                    class="IconTitle"
                    name="info"
                    color="white"
                    size="25px"
                />
                <div class="Title">
                    {{ $t(`visualization.filters.modelMetrics`) }}
                </div>
            </div>
            <div class="row justify-end">
                <QBtn
                    round
                    color="primary"
                    flat
                    icon="close_fullscreen"
                    size="12px"
                    textColor="white"
                    @click="visible=!visible"
                />
            </div>
        </div>
        <div v-if="visible">
            <div class="row q-col-gutter-sm">
                <div class="col-sm-6">
                    <span class="ContextualElementName">{{ $t(`visualization.filters.contextualInfo.cases`) }}</span>
                    <QCard
                        flat
                        class="bg-grey-2"
                    >
                        <QLinearProgress
                            size="40px"
                            :value="parseFloat(dictFormattedData.cases['displayedDataPercentage'])"
                            color="secondary"
                            trackColor="transparent"
                        >
                            <div class="absolute-full flex flex-center">
                                <QBadge
                                    color="white"
                                    textColor="secondary"
                                    class="flex flex-center"
                                >
                                    <span class="q-px-sm text-center text-weight-bold text-caption text-black">
                                        {{ dictFormattedData.cases['displayedData'] }}
                                    </span>
                                    {{ (dictFormattedData.cases['displayedDataPercentage']*100).toFixed(0) }}%
                                </QBadge>
                            </div>
                        </QLinearProgress>
                    </QCard>
                </div>
                <div class="col-sm-6">
                    <span class="ContextualElementName">{{ $t(`visualization.filters.contextualInfo.variants`) }}</span>
                    <QCard
                        flat
                        class="bg-grey-2"
                    >
                        <QLinearProgress
                            size="40px"
                            :value="parseFloat(dictFormattedData.variants['displayedDataPercentage'])"
                            color="secondary"
                            trackColor="transparent"
                        >
                            <div class="absolute-full flex flex-center">
                                <QBadge
                                    color="white"
                                    textColor="secondary"
                                    class="flex flex-center"
                                >
                                    <span class="q-px-sm text-center text-weight-bold text-caption text-black">
                                        {{ dictFormattedData.variants['displayedData'] }}
                                    </span>
                                    {{ (dictFormattedData.variants['displayedDataPercentage']*100).toFixed(0) }}%
                                </QBadge>
                            </div>
                        </QLinearProgress>
                    </QCard>
                </div>
            </div>
            <div class="col-sm-12">
                <span class="ContextualElementName">{{ $t(`visualization.filters.contextualInfo.duration`) }}</span>
                <div class="row q-col-gutter-sm items-center">
                    <div class="col-sm-6">
                        <QCard
                            flat
                            class="bg-grey-2"
                        >
                            <div class="q-pa-sm text-center text-weight-bold">
                                {{ dictFormattedData[`${durMode.value}`]['displayedData'] }}
                            </div>
                        </QCard>
                    </div>
                    <div class="col-sm-6 text-center">
                        <QFab
                            v-model="displayDurationOptions"
                            :label="durMode.value"
                            square
                            flat
                            labelPosition="left"
                            color="grey-2"
                            textColor="white"
                            icon="keyboard_arrow_right"
                            direction="right"
                            padding="sm"
                        >
                            <QFabAction
                                v-for="option in durOptions"
                                :key="option.value"
                                textColor="primary"
                                color="grey-2"
                                :label="option.label"
                                padding="xs"
                                @click="setDurationOption(option)"
                            />
                        </QFab>
                    </div>
                </div>
            </div>
            <QExpansionItem
                v-model="advancedOptionsToggle"
                defaultOpened
                dark
                dense
                denseToggle
                headerStyle="display: none;"
                :label="$t('visualization.filters.modelMetrics')"
            >
                <div
                    class="col-sm-12"
                >
                    <div class="row q-col-gutter-sm">
                        <div class="col-sm-6">
                            <span class="ContextualElementName">{{ $t(`visualization.filters.contextualInfo.minStartDateTime`) }}</span>
                            <QCard
                                flat
                                class="bg-grey-2"
                            >
                                <div class="q-pa-sm text-center text-weight-bold">
                                    {{ dictFormattedData.minStartDateTime }}
                                </div>
                            </QCard>
                        </div>
                        <div class="col-sm-6">
                            <span class="ContextualElementName">{{ $t(`visualization.filters.contextualInfo.maxEndDateTime`) }}</span>
                            <QCard
                                flat
                                class="bg-grey-2"
                            >
                                <div class="q-pa-sm text-center text-weight-bold">
                                    {{ dictFormattedData.maxEndDateTime }}
                                </div>
                            </QCard>
                        </div>
                    </div>
                </div>
            </QExpansionItem>
            <div
                class="col-sm-12"
            >
                <div
                    class="row cursor-pointer"
                    @click="advancedOptionsToggle = !advancedOptionsToggle"
                >
                    <div class="col-sm-12 q-pt-sm text-center text-white">
                        {{ advancedOptionsToggle ? $t(`visualization.filters.contextualInfo.expandLess`) : $t(`visualization.filters.contextualInfo.expandMore`) }}
                        <QIcon :name="advancedOptionsToggle ? 'expand_less' : 'expand_more'" />
                    </div>
                </div>
            </div>
        </div>
        <q-inner-loading
            :showing="Object.keys(totalInfo).length == 0 || Object.keys(displayedInfo).length == 0 || variantsLoading"
            :style="{zIndex: 3000}"
        />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import moment from 'moment'
import 'moment-duration-format'
import { filtersMixin } from '@/mixins'

export default {
    name: 'ChartContextualInfo',
    components: {
    },
    mixins: [filtersMixin],
    inject: ['App'],
    props: {
        totalInfo: VueTypes.object.def({}),
        filteredInfo: VueTypes.object.def({}),
        displayedInfo: VueTypes.object.def({}),
        percentageMode: VueTypes.string,
        currency: VueTypes.object,
        variantsLoading: VueTypes.bool,
    },
    data () {
        return {
            durMode: {
                label: this.$t('visualization.filters.contextualInfo.avg'),
                value: 'avg',
            },
            displayDurationOptions: false,
            advancedOptionsToggle: false,
            visible: true,
        }
    },
    computed: {
        dictFormattedData () {
            const {
                displayedInfo, getDurationFormattedData, getFormattedData,
            } = this
            return {
                cases: getFormattedData('cases'),
                variants: getFormattedData('variants'),
                med: getDurationFormattedData('med'),
                avg: getDurationFormattedData('avg'),
                sd: getDurationFormattedData('sd'),
                min: getDurationFormattedData('min'),
                max: getDurationFormattedData('max'),
                minStartDateTime: this.App.dateLocationFormat(displayedInfo.minStartDateTime),
                maxEndDateTime: this.App.dateLocationFormat(displayedInfo.maxEndDateTime),
            }
        },
        durOptions () {
            return [
                {
                    label: this.$t('visualization.filters.contextualInfo.med'),
                    value: 'med',
                },
                {
                    label: this.$t('visualization.filters.contextualInfo.avg'),
                    value: 'avg',
                },
                {
                    label: this.$t('visualization.filters.contextualInfo.sd'),
                    value: 'sd',
                },
                {
                    label: this.$t('visualization.filters.contextualInfo.min'),
                    value: 'min',
                },
                {
                    label: this.$t('visualization.filters.contextualInfo.max'),
                    value: 'max',
                },
            ]
        },
        curOptions () {
            return {
                euro: '€',
                dollar: '$',
                pound: '£',
            }
        },
    },
    mounted () {
    },
    created () {
    },
    methods: {
        setDurationOption (option) {
            this.durMode = option
        },
        getDurationFormattedData (key) {
            const { totalInfo, filteredInfo, displayedInfo } = this
            return {
                totalData: totalInfo.duration ? moment.duration(totalInfo.duration[key], 'seconds').format('d[d] h[h]:m[m]:s[s]', { largest: 2, trim: false }) : '-',
                filteredData: filteredInfo.duration ? moment.duration(filteredInfo.duration[key], 'seconds').format('d[d] h[h]:m[m]:s[s]', { largest: 2, trim: false }) : '-',
                displayedData: displayedInfo.duration ? moment.duration(displayedInfo.duration[key], 'seconds').format('d[d] h[h]:m[m]:s[s]', { largest: 2, trim: false }) : '-',
            }
        },
        getFormattedData (key) {
            const {
                totalInfo, filteredInfo, displayedInfo, numFormatter, formatPercentage,
            } = this
            let shownDataPercentage
            if (this.percentageMode === 'TOTAL') {
                shownDataPercentage = displayedInfo[key] ? `${formatPercentage(displayedInfo[key], totalInfo[key])}` : ''
            } else {
                shownDataPercentage = displayedInfo[key] ? `${formatPercentage(displayedInfo[key], filteredInfo[key])}` : ''
            }
            return {
                totalData: numFormatter(totalInfo[key]),
                totalDataPercentage: `${formatPercentage(totalInfo[key], totalInfo[key])}`,
                totalDataTooltip: totalInfo[key] !== numFormatter(totalInfo[key]) ? totalInfo[key] : '',
                filteredData: numFormatter(filteredInfo[key]),
                filteredDataPercentage: `${formatPercentage(filteredInfo[key], totalInfo[key])}`,
                filteredDataTooltip: filteredInfo[key] !== numFormatter(filteredInfo[key]) ? filteredInfo[key] : '',
                displayedData: displayedInfo[key] ? this.App.numberLocationFormat(numFormatter(displayedInfo[key]), false, true) : '-',
                displayedDataPercentage: shownDataPercentage,
                displayedDataTooltip: displayedInfo[key] !== numFormatter(displayedInfo[key]) ? displayedInfo[key] : '',
            }
        },
        formatPercentage (value, total) {
            return value ? ((value / total)).toFixed(2) : 0
        },
        numFormatter (num) {
            if (num > 9999 && num < 1000000) {
                return `${(num / 1000).toFixed(1) }K`
            } if (num > 1000000) {
                return `${(num / 1000000).toFixed(1) }M`
            }
            return num
        },
    },
}
</script>
<style lang="scss" scoped>
.ContextualInfo {
  position: relative;
  z-index: 99;
  width: 300px;
  height: auto;
  max-width: 300px;
}
.ContextualTitle {
    font-size: small;
    color: white;
    font-weight: bold;
}
.ContextualElementName {
    font-size: small;
    color: white;
}
.Title {
    color:$white;
    margin-left: 1.7em;
}
</style>
