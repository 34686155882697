<template>
    <div
        v-if="isOpen"
        class="CaseCompletionSettings"
    >
        <div class="CaseCompletion">
            <WText
                class="title"
                weight="bold"
                color="primary"
            >
                {{ $t('visualization.settings.relevantAttributes.name') }}
            </WText>
            <div class="container">
                <WText
                    weight="bold"
                    color="primary"
                >
                    {{ $t('visualization.settings.relevantAttributes.chooseRelevant') }}
                    ({{ relevantAttributes.length }} / {{ $t('visualization.settings.relevantAttributes.maxAttributes') }})
                </WText>
                <div class="OptionsGroup">
                    <QSelect
                        v-if="noButton"
                        v-model="relevantAttributes"
                        outlined
                        multiple
                        dense
                        hideSelected
                        class="Options"
                        labelColor="primary"
                        color="primary"
                        :label="$t('visualization.settings.relevantAttributes.attributes')"
                        :maxValues="3"
                        :options="attributes.filter(c => c.type === 'STRING').map(c => c.name)"
                    >
                        <template #option="scope">
                            <QItem v-bind="scope.itemProps">
                                <QItemSection>
                                    <QItemLabel v-html="scope.opt" />
                                </QItemSection>
                                <QItemSection side>
                                    <QToggle
                                        v-model="scope.selected"
                                        :val="scope.opt"
                                        @update:modelValue="handleAttributesInput(scope.opt)"
                                    />
                                </QItemSection>
                            </QItem>
                        </template>
                    </QSelect>
                    <QSelect
                        v-else
                        v-model="relevantAttributes"
                        outlined
                        multiple
                        dense
                        hideSelected
                        class="Options"
                        labelColor="primary"
                        color="primary"
                        :label="$t('visualization.settings.relevantAttributes.attributes')"
                        :maxValues="3"
                        :options="attributes.filter(c => c.type === 'STRING').map(c => c.name)"
                    >
                        <template #option="scope">
                            <QItem v-bind="scope.itemProps">
                                <QItemSection>
                                    <QItemLabel v-html="scope.opt" />
                                </QItemSection>
                                <QItemSection side>
                                    <QToggle
                                        v-model="scope.selected"
                                        :val="scope.opt"
                                        @update:modelValue="handleAttributesInput(scope.opt)"
                                    />
                                </QItemSection>
                            </QItem>
                        </template>
                    </QSelect>
                </div>
                <div
                    v-for="(selected, index) in relevantAttributes"
                    :key="index"
                >
                    <QChip
                        removable
                        color="primary"
                        textColor="white"
                        @remove="deleteIndexValue(index)"
                    >
                        {{ selected }}
                    </Qchip>
                </div>
            </div>
            <div
                v-if="!noButton"
                class="row justify-end mt-1"
            >
                <QBtn
                    outline
                    type="submit"
                    color="primary"
                    class="buttons"
                    noCaps
                    :label="$t('visualization.filters.save')"
                    @click="saveData"
                />
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'RelevantAttributesSettings',
    components: {},
    mixins: [],
    props: {
        isOpen: VueTypes.bool.def(false),
        noButton: VueTypes.bool.def(false),
        attributes: VueTypes.array,
    },
    emits: ['UpdateAttributes'],
    data () {
        return {
            timeout: null,
            activities: null,
            relevantAttributes: [],
            selectedRelevantAttributes: [],
            optionValue: 'id',
            optionLabel: 'label',
            title: "$t('visualization.settings.cost.name')",
        }
    },
    computed: {
    },
    watch: {
        attributes: {
            immediate: true,
            handler (newVal) {
                if (newVal.length > 0) {
                    this.relevantAttributes = newVal
                        .filter(c => c.relevant === true)
                        .map(c => c.name)
                } else {
                    this.relevantAttributes = null
                }
            },
        },
    },
    beforeMount () {
    },
    mounted () {
        // this.getAttributes()
        this.$nextTick(() => {
            this.relevantAttributes = this.attributes
                .filter(c => c.relevant === true)
                .map(c => c.name)
            this.selectedRelevantAttributes = this.relevantAttributes
        })
    },
    methods: {
        saveData () {
            this.$emit('UpdateAttributes', this.relevantAttributes)
        },
        // async getAttributes () {
        //     const datasetId = this.$route.params.processId
        //     apiRequest(Api().datasets.getConfigurations({ datasetId }))
        //         .then(({ data }) => {
        //             this.attributes = data[0].columns.filter(c => c.type === 'STRING').map(c => c.name)
        //             this.relevantAttributes = data[0].columns
        //                 .filter(c => c.relevant === true)
        //                 .map(c => c.name)
        //         })
        //         .catch(notifyError)
        // },
        deleteIndexValue (index) {
            this.relevantAttributes.splice(index, 1)
            if (this.noButton) { this.saveData() }
        },
        handleAttributesInput (selected) {
            if (!this.relevantAttributes.includes(selected)) {
                if (this.relevantAttributes.length < 3) {
                    this.relevantAttributes.push(selected)
                }
            } else {
                const index = this.relevantAttributes.indexOf(selected)
                if (index !== -1) {
                    this.relevantAttributes.splice(index, 1)
                }
            }
            this.selectedRelevantAttributes = this.relevantAttributes
            if (this.noButton) { this.saveData() }
        },
    },
}
</script>
<style lang="scss" scoped>
.CaseCompletionSettings {
    min-height: 300px;
    max-width: 100%;
    top: $subheader-height;
    bottom: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    outline: 0;
    z-index: 2002;
    background: white;
    border: solid 1px rgba($black, 0.2);
    box-shadow: 0 2px 5px 0 rgba(68, 68, 68, 0.2);
    border-radius: $border-radius;
    display: flex;
    .CaseCompletion {
        width:100%;
        margin: 20px;
        display: flex;
        flex-direction: column;
        @media (max-height:$md) {
            margin: 10px;
        }
    }
    .title {
        margin-bottom: 10px;
    }
    .OptionsGroup {
        display: flex;
        gap: 10px;
        padding: 10px 0;
    }
    .Options {
        width: 60%;
    }
    .container {
        width:auto;
        padding: 25px;
        border: solid 1px rgba($black, 0.2);
        border-radius: 5px;
        margin-top: 10px;
        justify-content: center;
        @media (max-height:$md) {
            padding: 10px;
        }
    }

    .buttons {
        width: 80px;
    }
}
</style>
