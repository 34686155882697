/* eslint-disable max-len */
export default {
    back: 'Back',
    continue: 'Continue',
    finish: 'Finish',
    cancel: 'Cancel process',
    testing: 'Testing connection',

    trace: 'Trace Id',
    activity: 'Activity',
    startTime: 'Start time',
    endTime: 'End time',
    relevant: 'Relevant attribute',

    name: 'Name',
    description: 'Description',
    file: 'File',

    notifications: {
        keyFieldsSuccess: {
            text: 'Key field configuration created successfully.',
        },
        keyFieldsError: {
            text: 'Check your key field configuration.',
        },
        keyRelevantFieldsLoading: {
            text: 'Loading relevant attributes, please wait.',
        },
        keyRelevantFieldsCountError: {
            text: 'You must select at least 1 relevant attribute.',
        },
        keyRelevantFieldsError: {
            text: 'Relevant attributes must have type STRING',
        },
        launched: {
            dataset: {
                text: 'Data set uploaded successfully.',
            },
            discovery: {
                text: 'Discovery launched successfully.',
            },
            import: {
                text: 'Data import process launched successfully.',
            },
            query: {
                text: 'Insight launched successfully.',
            },
        },
        connection: {
            text: 'Connection launched successfully',
        },
    },

    modals: {
        cancel: {
            text: 'Are you sure you want to leave this process?',
        },
    },

    configs: {
        dataset: {
            title: 'File import Wizard',
            steps: {
                0: 'Settings',
                1: 'Upload File',
                2: 'Summary',
                3: 'Relevant Fields',
            },
        },

        query: {
            title: 'Insight Wizard',
            steps: {
                step0: 'Data set',
                step1: 'Insight definition',
            },
        },

        import: {
            title: 'Connector Wizard',
            steps: {
                step1: 'Service',
                step2: 'Connection data',
                step3: 'Workflows',
                step4: 'Summary',
            },
        },
    },

    preview: {
        step: 'Step',
        select: 'Select',
        column: 'column',
        optional: 'Optional',
        decimalCharacter: 'Decimal character',
        thousandCharacter: 'Thousands character',
        homogeinzeAttribute: 'Homogenize categorical attributes',
        numberFormat: {
            label: 'Number format',
            placeholder: '',
        },
        numbersFormats: {
            en: 'American',
            eu: 'European',
        },
        warning: 'WARNING: ',
        mismatchedNumberFormat: 'Format different from the one configured in your account. Make sure which number format is being used in the uploaded data.',
        relevantAttributesTitle: 'Adjust data types and select relevant attributes',
        relevantNoAttributesSelectedWarning: 'No relevant attributes selected. Not having relevant attributes configured means you will not have access to the performance booster.',
        relevantAttributesDescription: 'Modify attribute types and choose the most relevant attributes for your process (for instance, teams, business units, resources, etc.)',
        done: {
            title: 'Done',
            text: 'Review your selections and proceed further.',
            noColumnsLeft: 'No columns left to select relevant attributes.',
        },

        tooltips: {
            ACTIVITY: `This field should contain the name or code of the activity that is associated to each registered event, just like “order creation” or “product packaging”.`,
            START: 'This field should contain the start time for a registered event.',
            END: `This field should contain the end time for a registered event.`,
            TRACE: `This field should uniquely identify each different execution of the process.`,
            RELEVANT: `This field is considered relevant for your process`,
        },

        modal: {
            dateformat: 'Add date format',
            time: 'Time format',
            text: {
                one: 'The time format should conform to the ISO 8601 standard',
                two: 'You can see more info of formats date in this',
            },
            website: 'website',
            example: 'Example',
        },
    },

    report: {
        name: 'Report name',
        type: 'Report type',
    },

    query: {
        mode: 'Insight mode',
        query: 'Insight',
        model: 'Model',
        restrictions: 'Insight Restrictions',
        queries: {
            seq: 'SEQ',
            switch: 'SWITCH',
            fork: 'FORK',
            loop: 'LOOP',
            activities: 'Activities',
        },
        insight: {
            model: {
                title: 'INSIGHT MODEL',
                description: `An insight model is simply the workflow structure that will be used to determine which execution traces fit the process as it has been conceived.
                The InVerbis Process Language (IPL) allows to easily define process models that contain gates and activities in a similar manner to BPMN representations.
                Using just 4 keywords, an user can model any process that contains activity sequences, parallels, choices, and loops by nesting these elements.
                `,
                seq: {
                    title: 'SEQ("Activity Name" | Nested structure, ...):',
                    description: `This keyword defines a sequence of activities or structures that must be executed following a precedence order (from left to right).
                    Both activities and structures can be mixed within the same SEQ, always separated by commas. Examples:`,
                    examples: [
                        'SEQ("Order created")',
                        'SEQ("Order created","Notify manager")',
                        'SEQ("Order created","Notify manager",SWITCH("Order accepted","Order rejected))',
                    ],
                },
                switch: {
                    title: 'SWITCH("Activity Name" | Nested structure, ...):',
                    description: `This keyword defines a choice or selection among different activites or substructures, with only one of them being executed in a specific trace. Examples:`,
                    examples: [
                        'SWITCH("Card payment","Payment by PayPal")',
                        'SWITCH(SEQ("Send e-mail","Receive e-mail"),SEQ("Send SMS","Receive phone call"))',
                        'SEQ("Ticket opened",SWITCH("Escalate","Manage"))',
                    ],
                },
                fork: {
                    title: 'FORK("Activity Name" | Nested structure, ...):',
                    description: `This keyword defines a set of activities or process substructures that must be executed regardless of their order, thus covering parallel or concurrent activities. Examples:`,
                    examples: [
                        'FORK("Notify user","Notify manager")',
                        'SEQ("Triage",FORK("Blood analysis","CT Scan"),"Result analysis","Surgery")',
                    ],
                },
                loop: {
                    title: 'LOOP("Activity Name" | Nested structure, ...):',
                    description: `This keyword defines a set of activities or process substructures that can be executed several times in a row. Currently,
                    this keyword represents a 0..* repetition, and is likely to change in the future to a configurable n..*.
                    This means that, for instance, to specify an activity that is repeated at least once: `,
                    examples: [
                        'SEQ("Notify user",LOOP("Notify user"))',
                    ],
                },
                custom: {
                    description: `Likewise, if we need to specify that an activity or submodel must execute at least twice, the user will have to explicitly include in the model the minimum number of times that must be repeated:`,
                    examples: [
                        'SEQ("Notify user","Notify user",LOOP("Notify user"))',
                    ],
                },
            },
            restrictions: {
                copy: 'Operator copied to the clipboard',
                title: `INSIGHT RESTRICTIONS`,
                description: `Restrictions allow to further constraint the result of an insight by filtering traces that, in addition to verifying the insight model,
                must also verify certain conditions that can be defined on attributes and durations.
                Restrictions are evaluated as boolean conditions, but we provide several logical,
                 numerical and date/time operations that allow to specify complex constraints. The list of operations we currently support is:`,
                operations: [
                    'date(dd,MM,yyyy)',
                    'day(number)',
                    'week(number)',
                    'month(number)',
                    'before(datetime,datetime)',
                    'after(datetime,datetime)',
                    'eq(number,number)',
                    'gt(number,number)',
                    'gte(number,number)',
                    'lt(number,number)',
                    'lte(number,number)',
                    'or(boolean,boolean)',
                    'and(boolean,boolean)',
                    'sum(number,number)',
                    'minus(number,number)',
                    'prod(number,number)',
                    'div(number,number)',
                    'between(a, x, y)',
                    'traceDuration()',
                    'timestamp("Activity")',
                    'duration("Activity")',
                    'timeBetween("Activity1","Activity2")',
                    'attribute("Activity","Attribute name","type")',
                ],
                examples: {
                    title: 'Examples',
                    items: [
                        'and(between(timestamp("O_Created"), ',
                        'date(2,3,2002),',
                        'timestamp("O_Cancelled")),',
                        'gt(duration("O_Created"), month(2) + week(1)))',
                        'after(timestamp("O_Created"),',
                        ' timestamp("O_Cancelled") + month(2) + week(1))',
                        'gt(timeBetween("O_Created", "O_Cancelled"), ',
                        'month(2) + week(1))',
                        'gt(sum(attrib("O_Created", "MonthlyCost", float),',
                        'attrib("O_Cancelled", "MonthlyCost", float)), 1500)',
                    ],
                },
            },
        },
        options: {
            one: 'Define a new insight model',
            two: 'Choose an existing model to check',
        },
    },

    connection: {
        standard: 'Standard',
        service: 'Service',
        database: 'Database',
        port: 'Port',
        user: 'User',
        password: 'Password',
        workflows: 'Workflows',
        filterTraces: 'Filter traces',
        filterEvents: 'Filter events with null fields',
        enable: 'Enable',
        disable: 'Disable',

        notifications: {
            empty: {
                title: 'No virtual views found',
                text: 'No virtual views for your search',
            },
        },
    },

    boosterWizard: {
        chooseApproach: {
            head: 'Freshly baked data set',
            title: 'Choose your analysis approach',
            performanceBooster: 'Performance booster',
            customAnalysis: 'Custom analysis',
            performanceBoosterDescription: 'Obtain inmediate insights about the state of your process, potential problems and root causes, and find solutions for quickly boosting its performance',
            customAnalysisDescription: 'Discover how your process flows. Manually explore process variants, loops, perform conformance analysis and apply filters to achieve the finest level of detail',
        },
        performanceBooster: {
            title: 'Performance booster',
            description: 'Set up how Inverbis will group cases into performance groups according to lead times',
            costAndCaseCompletion: {
                description: 'Set up the following optional elements to help Inverbis provide more thorough insights',
                warning: 'No valid case conditions were defined. Incomplete cases with shorter durations may be included in the analysis. This is likely if the minimum duration (left of the green bar) is 0.',
                buttons: {
                    back: 'Back',
                    boost: 'Boost!',
                },
            },
        },
        sla: {
            name: 'Service level agreement (SLA)',
            description: 'Separates good from bad executions. When a case duration is shorter than the established threshold, it is considered to be meeting the Service Level Agreement (SLA) and therefore it is an acceptable execution.',
            backButton: 'Back',
            nextButton: 'Next',
            compliance: 'Meet SLA',
            nonCompliance: 'Perform below SLA',
            cases: 'cases',
        },
    },
}
