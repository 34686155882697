export default {
    model: 'Process',
    modelLabel: 'Number of variants',
    availableVariants: 'Available variants',
    variants: 'Variants',
    editVariants: 'Enter number of variants to aggregate',
    variant: 'Variant',
    min: 'min',
    max: 'max',
    patterns: 'Patterns',
    loops: 'Rework',
    loop: 'Loop',
    nLoops: 'Loops',
    selfLoops: 'Self-loops',
    cases: 'traces',
    loopsFound: 'Found in',
    options: 'Options',
    percentageTotal: 'Show % of total data',
    percentageFiltered: 'Show % of filtered data',
    changePercentage: 'Change % reference',
    showLoopMetrics: 'Show loop metrics',
    changeGraphDirection: 'Set graph direction',
    changeGraphMetric: 'Set time metric',
    centerChart: 'Center chart',
    cancel: 'Cancel',
    filterVariants: 'Filter displayed variants',
    filterVariantsApplied: 'Variant filter already applied',
    filterLoop: 'Filter displayed loop',
    filterLoopApplied: 'Loop filter already applied',
    filterLoopText: 'Filter by loop ',
    resetMultipleVariants: 'Reset selected variants',
    aggregate: 'Aggregate',
    individual: 'Individual',
    exportImageFormat: 'Export as image format',
    exportLoadingDialog: 'Exporting data, please wait. Format: ',

    pending: 'Pending',
    soon: 'Soon',

    display: 'Display',
    stats: 'Stats',
    kPIs: 'KPIs',
    heatMap: 'Heat map',
    direction: 'Toggle graph direction',
    directionInfo: 'Alternates between a vertical or a horizontal graph layout',

    frequencies: 'frequency',
    freq: 'frequency',
    durations: 'duration',
    both: 'both',
    kPI: 'KPI',
    averages: 'avg',
    variances: 'sd',
    median: 'median',
    off: 'off',

    resetOptions: 'Reset options',
    centerView: 'Center view',
    resetHidePanelLocation: 'Reset or hide panels',
    modelMetricsToggle: 'Model metrics',
    customPlotPanelsToggle: 'Attribute Information',
    resetPanelLocation: 'Reset location',
    loopMetricsToggle: 'Loop metrics',
    zoomer: {
        fab: 'Edit activities',
        edit: 'Edit displayed activities',
        create: 'Create dataset from current edition',
        reset: 'Reset displayed activities',
        addGroup: 'Add group',
        removeGroup: 'Remove',
        groupTab: 'Group activities',
        removeTab: 'Remove activities',
        labelGroup: 'Group activity',
        labelActivities: 'Activities included',
        labelGroupName: 'Group activity name',
        selectActivities: 'Select activities',
        groupName: 'Define a name for the group',
        datasetName: 'Define a name for the new dataset',
        labelDatasetName: 'Name of new dataset',
        datasetTitle: 'New dataset',
        savedDatasetNotification: 'New dataset created',
        zoomerConfigCreatedNotification: 'Activities configuration updated',
        restoreConfigNotification: 'Activities configuration restored',
        loopsNotAvailable: 'Loops not available with grouped activities applied. You can create a new dataset to view them.',
        createDatasetError: 'No grouping configuration established',
    },
    tabs: {
        process: 'Process',
        variants: 'Variants',
        loops: 'Rework',
        metrics: 'Metrics',
        traces: 'Traces',
        alerts: 'Alerts',
        conformance: 'Conformance',
        predictions: 'Predictions',
        contextComparison: 'Comparisons',
        settings: 'Settings',
        assistant: 'AI Assistant',
        tooltip: {
            process: 'Discover how your process behaves',
            variants: 'Explore the different paths in your process',
            loops: 'Inspect potential waste and its impact',
            metrics: 'View and export process performance metrics',
            traces: 'Verify findings against the original data',
            alerts: 'Set up and check alerts on real time',
            conformance: 'Perform conformance checking against process models',
            predictions: 'Find out how your process will behave',
            contextComparison: 'Compare and export different analytic contexts',
            settings: 'Configure process parameters',
            assistant: 'An AI assistant to help you understand your data',
        },
    },
    bpmnSave: 'Convert displayed model to BPMN',
    bpmnOK: 'The model has been correctly generated',
    bpmnNotShowable: 'The model does not contain display data',
    bpmnModal: {
        title: 'Generate BPMN model',
        name: 'Model name',
        submit: 'Save model',
    },
    help: `
        <strong>Preventing graph pan and zoom on page scroll</strong><br><br>
        The scrolling action can unintentionally cause the graph to zoom.<br><br>
        The website below allows the page to scroll normally, without affecting the graph. Users can zoom the graph by clicking the zoom controls.<br><br>
        They can also zoom and pan by using two-finger movements on the graph for touchscreen devices.
    `,
    downloadPng: {
        label: 'Download chart',
        button: `<strong>Download png</strong><br><br>
            Make sure the graph is centered to download the image correctly.
        `,
    },
    notifications: {
        notFound: {
            text: 'No {type} found',
            btn: 'Retry',
        },
    },
    graph: {
        START: 'START',
        END: 'END',
    },
    renameVariantModal: {
        title: 'Rename variant',
        name: 'New variant name',
        submit: 'Rename',
    },
    renameLoopModal: {
        title: 'Rename loop',
        name: 'New loop name',
        submit: 'Rename',
    },
    variantRenameOK: 'The variant has been correctly renamed',
    variantRenameRequired: 'Name is required',
    variantRenameLengthError: 'Name must be between 1 and 100 characters',
    loopRenameOK: 'The loop has been correctly renamed',
    dashboard: {
        variants: 'Variants',
        variantsOver: 'By frequency versus average duration',
        activities: 'Activities',
        transitions: 'Transitions',
        download: 'Export metrics',
        notifications: {
            download: {
                text: 'Metrics exported successfully.',
                powerbi: 'PBIX file obtained successfully.',
            },
        },
        sortBy: {
            FREQUENCY: 'By frequency',
            DURATION_AVG: 'By longer average duration',
            DURATION_MAX: 'By longer maximum duration',
            DURATION_MIN: 'By shorter minimum duration',
            DURATION_STD: 'By longer standard deviation time',
        },
        heatmap: {
            source: 'Source',
            target: 'Target',
            value: 'Value',
        },
        axisLabels: {
            frequency: 'Frequency',
            duration: 'Duration',
            variantId: 'Variant',
            activityId: 'Activity',
        },
        oneVariantFrequency: 'Showing the most frequent variant',
        multipleVariantFrequency: 'Showing the {variants} most frequent variants',
        oneVariantAggregated: 'Showing 1 aggregated variant',
        multipleVariantAggregated: 'Showing {variants} aggregated variants',
    },
    traces: {
        table: {
            total: '{rowsNumber} events of {filteredTraces} cases',
            totalDesc: 'found based on current filters',
            search: {
                placeholder: 'Filter by Case ID',
            },
        },
        toPlot: 'Plot view',
        toTable: 'Table view',
        toDataSet: 'Create new data set from current filters',
        createDataSet: 'Create data set from filters',
        cancel: 'Cancel',
        create: 'Create',
        nameRequired: 'A name must be provided for the new data set',
        nameLength: 'The name must have 3 or more characters',
        creatingDataset: 'Creating data set...',
        datasetCreated: 'A new data set has been created from current filters',
        downloadCSV: 'Export to CSV',
        downloadStarted: 'Download started',
        downloadOK: 'Download successfully finished',
        exportDialog: {
            title: 'Export event data to CSV',
            subtitle: 'Select attributes to include',
            name: 'Attribute name',
            type: 'Data type',
            attribute: 'attribute',
            selected: 'selected out of',
            export: 'Generate CSV file',
        },
        plot: {
            eventsShown: 'Showing events',
            eventsTo: 'to',
            start: 'From:',
            end: 'To:',
            noEvents: 'No events to show',
        },
    },
    filters: {
        labels: {
            TRACE_STARTS_BEFORE: 'STARTS BEFORE',
            TRACE_ENDS_BEFORE: 'ENDS BEFORE',
            TRACE_STARTS_AFTER: 'STARTS AFTER',
            TRACE_ENDS_AFTER: 'ENDS AFTER',
            TRACE_STARTS_WITH_ACTIVITY: 'STARTS WITH',
            TRACE_ENDS_WITH_ACTIVITY: 'ENDS WITH',
            TRACE_CONTAINS_ACTIVITIES: 'CONTAINS ACTIVITIES',
            TRACE_NOT_CONTAINS_ACTIVITIES: 'DOES NOT CONTAIN ACTIVITIES',
            TRACE_CONTAINS_ARCS: 'CONTAINS ARCS',
            TRACE_NOT_CONTAINS_ARCS: 'DOES NOT CONTAIN ARCS',
            TRACE_DURATION_GREATER_THAN: 'TRACE LONGER THAN',
            TRACE_DURATION_LESS_THAN: 'TRACE SHORTER THAN',
            ARC_DURATION_GREATER_THAN: 'ARC LONGER THAN',
            ARC_DURATION_LESSER_THAN: 'ARC SHORTER THAN',
            ACTIVITY_DURATION_GREATER_THAN: 'ACTIVITY LONGER THAN',
            ACTIVITY_DURATION_LESS_THAN: 'ACTIVITY SHORTER THAN',
            ACTIVITIES_DURATION_GREATER_THAN: 'DURATION BETWEEN ACTIVITIES LONGER THAN',
            ACTIVITIES_DURATION_LESS_THAN: 'DURATION BETWEEN ACTIVITIES SHORTER THAN',
            ACTIVITIES_DURATION_LOOP_GREATER_THAN: 'DURATION BETWEEN CONSECUTIVE ACTIVITIES LONGER THAN',
            ACTIVITIES_DURATION_LOOP_LESS_THAN: 'DURATION BETWEEN CONSECUTIVE ACTIVITIES SHORTER THAN',
            TRACE_IS_IN_VARIANTS: 'IS IN VARIANTS',
            TRACE_IS_IN_LOOP_VARIANTS: 'CONTAINS LOOP',
            TRACE_IS_IN_N_VARIANTS: 'IS IN N MOST FREQUENT VARIANTS',
        },
        analysisContext: 'Analysis context',
        modelMetrics: 'Model metrics',
        contextual: 'Analysis context',
        total: 'Filters ({total})',
        title: 'Choose a filter to add',
        titleOpenFilter: 'Configure selected filter',
        manage: 'Add',
        sets: 'Sets',
        cancel: 'Close',
        reset: 'Reset',
        empty: 'No filters added',
        emptyModel: 'No traces found for the current filters',
        sidebarTooltips: {
            durationMetric: "Choose duration metric",
            addFilter: "Add filter",
            resetActiveFilters: "Reset active filters",
            manageFilterSets: "Manage filter sets",
            createFilterSet: "Create filter set",
            changeFilterAggregation: "Change filter aggregation operator",
        },
        activities: {
            title: 'Activities',
            label: 'Select an activity',
            activityOccurrence: 'By activity occurrence order',
            activityOccurrenceDescription: `In case of finding a loop in any trace, it filters the duration between the first/last occurrence of the first
                activity until the first/last occurrence of the second one.`,
            pairRepetition: 'By pair repetition',
            pairRepetitionDescription: `In case of finding a loop in any trace, it filters those in which all the sequences between activities match the requisite.`,
        },
        arcs: {
            title: 'Arcs',
            label: 'Select an arc',
        },
        constraints: {
            label: 'Select attribute constraints',
        },
        date: {
            title: 'Date and time',
            label: 'Date',
            noData: 'No data',
            max: {
                label: '{max}',
                tooltip: 'End date',
            },
            min: {
                label: '{min}',
                tooltip: 'Start date',
            },
        },
        activitiesDuration: {
            occurrenceLabel: 'Select occurrence',
        },
        duration: {
            title: 'Duration',
            label: 'Duration',
            avg: '{d}d {h}h {m}m {s}s',
            avgTooltip: 'Avg: {d}d {h}h {m}m {s}s',
            min: 'Min: {d}d {h}h {m}m {s}s',
            max: 'Max: {d}d {h}h {m}m {s}s',
            sd: 'Dev: {d}d {h}h {m}m {s}s',
            duration: '{d}d {h}h',
            secondDuration: '{h}h {m}m',
            minorDuration: '{m}m {s}s',
            filters: {
                trace: 'Trace duration',
                activity: 'Activity duration',
                arc: 'Arc duration',
                activities: 'Duration between activities',
            },
        },
        attributes: {
            title: 'Attributes',
            label: 'Attributes',
            value: {
                label: 'Value',
            },
            categoryAny: 'Any',
            attrIs: 'IS',
            attrIsNot: 'IS NOT',
            attrIsTrueLabel: 'IS TRUE',
            attrIsFalseLabel: 'IS FALSE',
            attrNumEqualsLabel: '=',
            attrNumNotEqualsLabel: '!=',
            attrNumGreaterThanLabel: 'GREATER THAN',
            attrNumLesserThanLabel: 'LESSER THAN',
            attrNumBetweenLabel: 'BETWEEN',
            attrBetweenLabel: 'BETWEEN',
            attrDateAfterLabel: 'AFTER',
            attrDateBeforeLabel: 'BEFORE',
            attrDateBetweenLabel: 'BETWEEN',
            attrGreaterLabel: 'LONGER THAN',
            attrLesserLabel: 'SHORTER THAN',
            attrFirstOccurrenceLabel: 'FIRST',
            attrLastOccurrenceLabel: 'LAST',
            filters: {
                numericalField: 'Numerical',
                ctgField: 'Categorical / Textual',
                booleanField: 'Boolean',
                dateTimeField: 'Date / Time',
            },
            table: {
                title: 'Selected attributes',
                activity: 'Activity',
                attribute: 'Attribute',
                constraints: 'Constraint',
                value: 'Value',
                delete: '',
            },
        },
        save: 'Save',
        add: 'Add',
        totalProgress: '{value} of {total}',
        progress: [
            { id: 'cases', label: 'Traces' },
            { id: 'variants', label: 'Variants' },
            { id: 'activities', label: 'Activities' },
            { id: 'transitions', label: 'Transitions' },
        ],
        contextualInfo: {
            total: 'Total',
            filtered: 'Filtered',
            displayed: 'Displayed',
            cases: 'Cases',
            variants: 'Variants',
            duration: 'Duration',
            med: 'Median (med)',
            avg: 'Average (avg)',
            max: 'Maximum (max)',
            min: 'Minimum (min)',
            sd: 'Standard deviation (sd)',
            maxEndDateTime: 'End date',
            minStartDateTime: 'Start date',
            estimatedCost: 'Est. Cost',
            totalEstimatedCost: 'Est. Total Cost',
            expandLess: 'Show less',
            expandMore: 'Show more',
        },
        filterSets: {
            title: 'Filter sets',
            name: 'Filter set',
            createNewFilterSet: 'Create filter set',
            register: {
                success: 'Filter set registered correctly',
            },
            delete: {
                success: 'Filter set deleted correctly',
                title: 'Delete set',
                description: 'Are you sure you want to delete this filter set?',
            },
            search: {
                title: 'Add a filter set',
                placeholder: 'Enter name for new filter set',
            },
            notifications: {
                noResult: {
                    title: 'No filter sets found',
                    noFilters: 'The selected filter set does not contain any filters',
                },
            },
            config: {
                title: 'Filter system configuration',
                aggregationOperators: {
                    title: 'Aggregation operator',
                    AND: 'AND',
                    OR: 'OR',
                },
                filterSetsTable: {
                    load: 'Load',
                    loadAsFilterSet: 'As filter set',
                    loadAsFilters: 'As individual filters',
                    title: 'Filter sets',
                    new: 'New filter set',
                    addToActive: 'Add to active filters',
                    replaceActive: 'Replace active filters',
                    removeSelected: 'Remove selected',
                    filterName: 'Name',
                    operator: 'Operator',
                    filtersIncluded: 'Filters included',
                    filters: 'filters',
                    filter: 'filter',
                },
            },
        },
    },
    alerts: {
        filterBy: 'Filter by',
        columns: {
            name: 'Name',
            severity: 'Severity',
            filterSet: 'Filter set',
            type: 'Type',
            notification: 'Notification',
        },
        tabs: {
            active: 'Active',
            history: 'History',
            definitions: 'Definitions',
        },
        detected: {
            noAlerts: 'No alerts to show',
            affected: 'Affected cases',
            lastDetected: 'Last detected',
            severity: 'Severity level',
            filterSet: 'Associated Filter Set',
            filterCases: 'Filter cases',
            dismiss: 'Dismiss',
            severityRiskLabels: {
                LOW: 'Low',
                MEDIUM: 'Medium',
                HIGH: 'High',
            },
            predictionRiskLabels: {
                LOW: 'SLA under risk',
                MEDIUM: 'SLA under risk',
                HIGH: 'SLA exceeded',
            },
        },
        definition: {
            create: 'Set up new alert',
            remove: 'Remove selected',
            name: 'Name',
            severity: 'Severity',
            type: 'Type',
            types: {
                ALL: 'All',
                STANDARD: 'Standard',
                PREDICTION: 'Prediction',
                TIME: 'Time',
            },
            risk: 'Risk',
            filterSet: 'Filter Set',
            notification: 'Notification',
            activity: 'Activity',
            period: 'Period',
            record: 'record',
            selected: 'selected of',
            createScreen: {
                newAlert: 'New alert definition',
                sendEmail: 'Send e-mail notification to',
                user: 'user\'s e-mail',
                other: 'different e-mail',
                notSend: 'do not send',
                notifyTo: 'Target e-mail address',
                cancel: 'Cancel',
                addAlert: 'Add alert',
            },
            tableColumn: {
                name: 'Name',
                severity: 'Severity',
                filterset: 'Filter Set',
                type: 'Type',
                notification: 'Notification',
            },
            activityPeriod: {
                hasStarted: 'Has started',
                hasEnded: 'Has ended',
            },
            timeUnit: {
                seconds: 'Seconds',
                minutes: 'Minutes',
                hours: 'Hours',
                days: 'Days',
                weeks: 'Weeks',
                months: 'Months',
            },
        },
        notifications: {
            created: 'Alert succesfully created',
            validateError: 'Please fill all the required fields properly',
        },
    },
    loopMetrics: {
        metricsTitle: 'Loop metrics',
        avgTime: 'Average time',
        avgCost: 'Average cost',
        percentTime: '% of average case duration',
        estTotalEffort: 'Estimated total effort',
        afterRemoval: 'Case improvement after removal',
        avgCostConf: 'Avg cost/h',
        currency: 'Currency',
    },
    customPlotPanels: {
        frequencyAndDuration: 'Frequency and duration',
        attributeInfo: 'Attribute information',
        frequency: 'Frequency',
        duration: 'Duration',
        frequencyDuration: {
            selectDuration: 'Select time unit',
            selectRange: 'Select range',
        },
        attributesInfo: {
            selectAttribute: 'Select attribute',
            sortBy: 'Sort by:',
            start: 'start',
            end: 'end',
            month: 'month',
            week: 'week',
            day: 'day',
            eventAttribute: 'Break down by activity',
            selectActivity: 'Select an activity',
            activityRequired: 'Select at least one activity',
            noData: 'There are no activities that contain this attribute',
            selectUnit: 'Select unit',
        },
    },
    conformance: {
        chooseBpmn: 'Choose a BPMN model',
        overview: 'Compliance',
        issues: 'Main issues',
        root: 'Root causes',
        noModels: 'No BPMN models currently available for this project. ' +
            'It is necessary to have at least one BPMN model to analyze compliance.',
        noModelsUpload: 'You can create a model from any graph visualization in the Process tab, or upload one.',
        fulfillment: {
            fulfillment: 'Fulfillment',
            percentageTitle: 'Model compliance ratio',
            correct: 'Expected',
            incorrect: 'Unexpected',
            percentageTimeTitle: 'Compliance ratio over time',
            validPercentageTime: 'Expected cases %',
            invalidPercentageTime: 'Unexpected cases %',
            days: 'days',
            weeks: 'weeks',
            months: 'months',
            selectUnit: 'Select time unit',
        },
        issuesView: {
            transitionsTitle: 'Unexpected transitions',
            startTitle: 'Unexpected start activities',
            endTitle: 'Unexpected end activities',
            case: 'case',
            cases: 'cases',
            outOf: 'out of',
            noResultsTransitions: 'No unexpected transitions found',
            noResultsStart: 'No unexpected starting activities found',
            noResultsEnd: 'No unexpected ending activities found',
        },
        rootCause: {
            noAttributes: 'No attributes available in this data set',
        },
    },
    settings: {
        name: 'Settings',
        caseCompletion: {
            name: 'Case completion conditions',
            endingActivities: 'Ending activities',
            occurringActivities: 'Occurring activities',
            startingActivities: 'Starting activities',
        },
        costs: {
            name: 'Cost and Currency',
            unit: 'Currency',
            value: 'Value',
            units: {
                euro: 'Euro (€)',
                dollar: 'Dollar ($)',
                pound: 'Pound (£)',
            },
            activities: 'Activities',
            avgCostHour: 'Avg Cost/Hour',
            general: 'General',
            activityCosts: 'Activity Costs',
            duplicateError: 'One or more selected activities already have assigned costs.',
            addCost: 'Add Cost',
            removeCost: 'Remove Cost',
            noData: ' No Data Available',
            ActivityCostModal: {
                editActivityCost: 'Edit Activity Cost',
                addActivityCost: 'Add Activity Cost',
                selectActivities: 'Select Activities',
                costValue: 'Cost Value',
                add: 'Add',
                save: 'Save',
                positiveNumberError: 'Cost value must be a positive number',
                selectAtLeastOneActivity: 'Please select at least one activity.',
            },
        },
        sla: {
            name: 'SLA',
            number: 'Value',
            unit: 'Unit',
            units: {
                minutes: 'minutes',
                hours: 'hours',
                days: 'days',
                weeks: 'weeks',
                months: 'months',
            },
            caseSla: 'Case SLA',
            customSla: 'Custom SLAs',
            addSla: 'Add SLA',
            activitySla: 'Activity SLA',
            transitionSla: 'Transition SLA',
            activityGapSla: 'Activity Gap SLA',
            removeSla: 'Remove',
            add: 'Add',
            edit: 'Edit',
            close: 'Close',
            variantsSlaPresent: 'Additional variant SLAs',
            customSlaPresent: 'Addtional custom SLAs',
            variantsAndCustomSlaPresent: 'Additional variant and custom SLAs',
            variantSlaModal: {
                title: 'Add variant SLA',
                selectVariant: 'Select a variant',
                all: 'All',
            },
            activitySlaModal: {
                addTitle: 'Add activity SLA',
                editTitle: 'Edit activity SLA',
                selectActivity: 'Select an activity',
            },
            transitionSlaModal: {
                addTitle: 'Add transition SLA',
                editTitle: 'Edit transition SLA',
                selectTransition: 'Select a transition',
            },
            activityGapSlaModal: {
                addTitle: 'Add SLA between non-adjacent activities',
                editTitle: 'Edit SLA between non-adjacent activities',
                selectFirstActivity: 'Select first activity',
                selectSecondActivity: 'Select second activity',
                firstToLast: 'First to last',
                consecutiveLoop: 'By ocurrence',
                sourceOcurrence: 'Occurrence of the first activity',
                targetOccurrence: 'Occurrence of the second activity',
                first: 'First',
                last: 'Last',
                firstToLastHelp: 'First to last: Check that the SLA is met from the first/last occurrence of the first activity ' +
                'to the first/last occurrence of the second activity within the trace.',
                consecutiveLoopHelp: 'By ocurrence: If there are loops in any trace, check that the SLA is met in ' +
                'all sequences formed by the selected activities',
            },
            customSlaTable: {
                type: 'Type',
                elements: 'Elements',
                sla: 'SLA',
                notifyDuplicated: 'The new SLA could not be added since there is an SLA with the same parameters',
                loopMode: 'Loop mode',
                sourceOccurrence: 'Source',
                targetOccurrence: 'Target',
            },
        },
        predictionAlgorithm: {
            name: 'Prediction Algorithms',
            chooseAlgorithm: 'Choose prediction algorithm',
            algorithms: {
                standard: {
                    name: 'Standard',
                    description: 'Fastest statistical method. Provides good overall accuracy but is not tailored for each individual data set.',
                },
                machineLearning: {
                    name: 'Machine Learning',
                    description: 'Most accurate method. Neural models are trained based on the current data set, providing the best overall results.' +
                    ' Depending on the data set size, the models may take some time to be ready for use.',
                    caseDurationCheck: 'Case duration and SLA fulfillment: ',
                    activitySequenceCheck: 'Activity sequence prediction: ',
                },
            },
            disabled: 'It is necessary to define both the case completion conditions and the SLA conditions configured in order to configure the prediction algorithm',
        },
        relevantAttributes: {
            name: 'Relevant Attributes',
            attributes: 'Attributes',
            maxAttributes: '3',
            chooseRelevant: 'Choose relevant attributes',
            noRelevant: 'No relevant attributes selected',
            noSelectableAttributes: 'No categorical attributes to select from',
            disabled: 'There are no valid attributes that allow the relevant attributes to be configured',
        },
        settingsSaved: 'Saved succesfully',
        caseCompletionConditionsNoConf: 'No conditions selected',
        caseCompletionConditionsConf: 'Conditions selected',
        startingActivities: 'Starting activities',
        occurringActivities: 'Occurring activities',
        endingActivities: 'Ending activities',
        conditionsSelected: 'Selected conditions',
        activities: 'Activities',
    },
    predictions: {
        downloadCSV: 'Export to CSV',
        caseId: 'Case ID',
        caseMetrics: 'Case metrics',
        estimatedDuration: 'Estimated duration',
        lastActivity: 'Last activity',
        estimatedVariant: 'Variant',
        currentDuration: 'Current duration',
        riskLevel: 'Risk level',
        nextActivity: 'Next activity to be executed',
        unifinishedCases: 'Unfinished cases',
        noRisk: 'No risk',
        risk: 'Risk',
        days: 'days',
        table: {
            total: '{total} unfinished cases',
            totalDesc: 'found based on current settings',
            search: {
                placeholder: 'Filter by Case ID',
            },
            newVariant: 'New variant',
        },
        newVariant: 'New variant, prediction not yet available.',
        loadingMessage: 'Loading prediction data, please wait',
        slaNotDefined: 'The SLA conditions have not been defined to retrieve prediction',
        caseCompletionNotDefined: 'The case completion conditions have not been defined to retrieve prediction',
        slaAndCaseNotDefined: 'The case completion and the SLA conditions have not been defined to retrieve prediction',
        noPredictionsText: 'You can define the prediction conditions in the Settings tab, or by clicking the following button.',
        noData: 'No data available',
        notAvailable: 'Prediction is not available due to lack of historical data. It should be available once more process cases have been uploaded and processed over time',
        someNotAvailable: 'Prediction is not available for some cases due to lack of historical data. Affected cases have been omitted in the information provided in this view',
    },
    contextComparison: {
        addContext: 'Add Context',
        downloadCSV: 'Export to CSV',
        comparison: 'Comparison',
        cases: 'Cases',
        variants: 'Variants',
        duration: 'Duration',
        dates: 'Dates',
        start: 'Start',
        end: 'End',
        before: 'BEFORE',
        after: 'AFTER',
        noData: 'No contexts added',
        noDataExplanation: 'Add contexts to compare',
        maxContext: 'Maximum of eight comparison contexts.',
        datasets: 'Datasets',
        filtersets: 'Filtersets',
        noFiltersets: '*No filtersets available',
    },
    powerBIDialog: {
        powerBI: 'Power BI',
        title: "Connect Inverbis's process intelligence analytics to Microsoft Power BI",
        clipboard: 'Copy to clipboard',
        chooseConnection: 'Choose the connections method that better suits your needs.' +
            ' Note that in both cases you will need to supply the current dataset identifier and valid API token parameters.',
        currentDataset: 'Current dataset identifier: ',
        tokenAvailable: 'API token is available',
        noTokenAvailable: 'No API token has been generated.',
        generateToken1: 'You can ',
        generateToken2: 'generate your own token',
        generateToken3: ' through the API option in the menu for your user profile.',
        downloadButton1: `Download .pbix for`,
        downloadButton2: `Power BI Desktop `,
        downloadExplanation: 'Download a prebuilt template into your desktop application. You can modify it and adapt it to suit your needs.',
        installButton1: `Install Inverbis's app in`,
        installButton2: `your own account `,
        installExplanation: "Install the Inverbis's dashboard app in your own Power BI cloud subscription to keep an online access to your process dashboard.",
    },
    infoModal: {
        title: 'Calculations in process.',
        info: 'The durations shown may change in the near future.',
    },
    SLAInfo: {
        title: 'SLA Information',
        cases: 'Cases',
        noSLAInfo: 'SLA not applicable',
    },
    inputNum: {
        IntNumError: 'The number allows up to {maxIntNum} integer digits.',
        DecimalNumError: 'The number allows up to {maxDecimalNum} decimal digits.',
        DecimalNotAllowed: 'The number must be an integer.',
    },
    frequencyIndicator: {
        percentOfActivities: '% of activities shown.',
    },
    popup: {
        buttons: {
            start_with: 'START WITH',
            end_with: 'END WITH',
            contain: 'CONTAIN',
            not_contain: 'NOT CONTAIN',
        },
        impact: {
            title: 'Impact',
            current: 'Current',
            total: 'Total',
            level: {
                low: 'Low',
                medium: 'Medium',
                high: 'High',
            },
        },
    },
}
