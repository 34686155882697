<template>
    <RouterLink
        v-if="!isMobile"
        to="/"
        class="Logo"
    >
        <img
            :src="logo"
            :alt="name"
        >
    </RouterLink>
    <div
        v-else
        class="Logo LogoMin"
    >
        <RouterLink
            to="/"
        />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import Config from '@/config'
import { mediaQueryMixin } from '@/mixins'

export default {
    name: 'Logo',
    mixins: [mediaQueryMixin],
    props: {
        name: VueTypes.string.def(Config?.project?.name),
        logo: VueTypes.string.def(Config?.project?.logo),
    },
}
</script>
<style scoped lang="scss">
.Logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $subheader-height;

    img {
        width: 100px;
        max-height: 50px;
    }

    &Min {
        max-width: $sidebar-min-width;
    }
}
</style>
