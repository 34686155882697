import actions from './actions.lang'
import booster from './booster.lang'
import error from './error.lang'
import meta from './meta.lang'
import navigation from './navigation.lang'
import validation from './validation.lang'
import general from './general.lang'
import organizations from './organizations.lang'
import projects from './projects.lang'
import events from './events.lang'
import invitations from './invitations.lang'
import notifications from './notifications.lang'
import visualization from './visualization.lang'
import settings from './settings.lang'
import forms from './forms.lang'
import report from './report.lang'
import wizard from './wizard.lang'
import assistant from './assistant.lang'

export default {
    actions,
    booster,
    error,
    meta,
    navigation,
    validation,
    general,
    organizations,
    projects,
    events,
    invitations,
    notifications,
    visualization,
    settings,
    forms,
    report,
    wizard,
    assistant,
}
