<template>
    <div class="row justify-center">
        <div class="col-8 justify-center q-pa-xs">
            <div class="Title">
                {{ $t('wizard.boosterWizard.performanceBooster.title') }}
            </div>
            <div class="Subtitle">
                {{ $t('wizard.boosterWizard.performanceBooster.costAndCaseCompletion.description') }}
            </div>
            <div class="SettingsContainer">
                <div
                    class="Settings flex row-left-center"
                    :class="{'closeSettings': !openCCSettings && !openCaseCompletionSettings && !openRelevantAttributesSettings,
                             'openSettings': openCCSettings || openCaseCompletionSettings || openRelevantAttributesSettings }"
                >
                    <q-card
                        bordered
                        shadow
                        :class="{ 'container-open': openCCSettings, 'disabled-card': noRelevantAttributesSelectable, 'q-pa-md': true}"
                    >
                        <div
                            class="CardInfo row-left-center"
                            @click="handleCCSettings ()"
                        >
                            <div
                                class="Item flex row-left-center flex-nowrap card-container"
                            >
                                <q-icon
                                    name="payments"
                                    color="primary"
                                    size="40px"
                                />
                                <div class="flex-wrap">
                                    <WText
                                        class="pl-1"
                                        typo="h3"
                                        tag="p"
                                        weight="bold"
                                    >
                                        {{ $t('visualization.settings.costs.name') }}
                                    </WText>
                                    <WText
                                        v-if="values.cost"
                                        class="pl-1 textCost"
                                        color="primary"
                                        tag="p"
                                        weight="medium"
                                    >
                                        {{ values.cost.value }} {{ $t('visualization.settings.costs.units.'+values.cost.unit) }}
                                    </WText>
                                </div>
                            </div>
                            <div class="Item rightIcon">
                                <q-icon
                                    v-if="!openCCSettings"
                                    name="chevron_right"
                                />
                                <q-icon
                                    v-if="openCCSettings"
                                    name="chevron_left"
                                />
                            </div>
                        </div>
                    </q-card>
                    <q-card
                        bordered
                        shadow
                        :class="{ 'container-open': openCaseCompletionSettings, 'disabled-card': noRelevantAttributesSelectable, 'q-pa-md': true}"
                    >
                        <div
                            class="CardInfo row-left-center"
                            @click="handleCaseCompletionSettings ()"
                        >
                            <div
                                class="Item flex row-left-center flex-nowrap card-container"
                            >
                                <div>
                                    <q-icon
                                        name="schema"
                                        color="primary"
                                        size="40px"
                                    />
                                </div>
                                <div>
                                    <WText
                                        class="pl-1"
                                        typo="h3"
                                        tag="p"
                                        weight="bold"
                                    >
                                        {{ $t('visualization.settings.caseCompletion.name') }}
                                    </WText>
                                    <WText
                                        v-if="!caseCompletionFilled"
                                        class="pl-1 textCost"
                                        color="primary"
                                        tag="p"
                                        weight="medium"
                                    >
                                        {{ $t('visualization.settings.caseCompletionConditionsNoConf') }}
                                    </WText>
                                    <WText
                                        v-else
                                        class="pl-1 textCost"
                                        color="primary"
                                        tag="p"
                                        weight="medium"
                                    >
                                        {{ $t('visualization.settings.caseCompletionConditionsConf') }}
                                    </WText>
                                </div>
                            </div>
                            <div class="Item rightIcon">
                                <q-icon
                                    v-if="!openCaseCompletionSettings"
                                    name="chevron_right"
                                />
                                <q-icon
                                    v-if="openCaseCompletionSettings"
                                    name="chevron_left"
                                />
                            </div>
                        </div>
                    </q-card>
                    <q-card
                        bordered
                        shadow
                        :class="{ 'container-open': openRelevantAttributesSettings, 'disabled-card': noRelevantAttributesSelectable, 'q-pa-md': true}"
                    >
                        <div
                            class="CardInfo row-left-center"
                            @click="handleRelevantAttributesSettings ()"
                        >
                            <div
                                class="Item flex row-left-center flex-nowrap card-container"
                            >
                                <div>
                                    <QIcon
                                        name="star"
                                        color="primary"
                                        size="40px"
                                    />
                                </div>
                                <div>
                                    <WText
                                        class="pl-1"
                                        typo="h3"
                                        tag="p"
                                        weight="bold"
                                    >
                                        {{ $t('visualization.settings.relevantAttributes.name') }}
                                    </WText>
                                    <WText
                                        v-if="noRelevantAttributesSelectable"
                                        class="pl-1 textCost"
                                        color="primary"
                                        tag="p"
                                        weight="medium"
                                    >
                                        {{ $t('visualization.settings.relevantAttributes.noSelectableAttributes') }}
                                    </WText>
                                    <WText
                                        v-else-if="relevantAttributes === null || !relevantAttributes.length"
                                        class="pl-1 textCost"
                                        color="primary"
                                        tag="p"
                                        weight="medium"
                                    >
                                        {{ $t('visualization.settings.relevantAttributes.noRelevant') }}
                                    </WText>
                                    <WText
                                        v-else
                                        class="pl-1 textCost"
                                        color="primary"
                                        tag="p"
                                        weight="medium"
                                    >
                                        {{ relevantAttributes.join(', ') }}
                                    </WText>
                                </div>
                            </div>
                            <div class="Item rightIcon">
                                <q-icon
                                    v-if="!openRelevantAttributesSettings"
                                    name="chevron_right"
                                />
                                <q-icon
                                    v-if="openRelevantAttributesSettings"
                                    name="chevron_left"
                                />
                            </div>
                        </div>
                    </q-card>
                </div>
                <div v-if="values && Object.keys(values).length > 0 && attributes">
                    <CostCurrencySettings
                        ref="CostCurrencySettings"
                        :values="values.cost"
                        :isOpen="openCCSettings"
                        :saveButton="false"
                        :firstTimeWizard="firstTimeWizard"
                        @CostCurrencyConfig="saveSettings"
                    />
                    <CaseCompletionConditions
                        ref="CaseCompletionConditions"
                        :isOpen="openCaseCompletionSettings"
                        :values="values.caseCompletion"
                        :saveButton="false"
                        :variants="variants"
                        @CaseCompletionConditionsConfig="saveSettings"
                    />
                    <RelevantAttributesSettings
                        ref="RelevantAttributesSettings"
                        :noButton="true"
                        :attributes="attributes"
                        :isOpen="openRelevantAttributesSettings"
                        @UpdateAttributes="handleUpdateAttributes"
                    />
                </div>
            </div>
            <div class="row justify-center MarginTop">
                <div
                    v-if="!caseCompletionFilled"
                    class="Warning rounded-borders q-pa-md"
                    style="white-space: pre-line;"
                >
                    <QIcon
                        class="Icon"
                        name="info"
                        color="white"
                        size="40px"
                    />
                    <WText>
                        <!-- {{ $t('wizard.boosterWizard.performanceBooster.costAndCaseCompletion.warning') }} -->
                        {{ getWarningMessage() }}
                    </WText>
                </div>
            </div>
            <div class="row justify-between MarginTop">
                <QBtn
                    color="primary"
                    noCaps
                    style="padding-inline: 40px;"
                    @click="handleReturn"
                >
                    {{ $t('wizard.boosterWizard.performanceBooster.costAndCaseCompletion.buttons.back') }}
                </QBtn>
                <QBtn
                    class="BoostButton"
                    color="primary"
                    noCaps
                    style="padding-inline: 40px;"
                    :disable="!caseCompletionFilled"
                    @click="uploadData"
                >
                    {{ $t('wizard.boosterWizard.performanceBooster.costAndCaseCompletion.buttons.boost') }}
                </QBtn>
            </div>
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'
import CostCurrencySettings from '../../Project/Process/Visualization/sections/SettingsModal/components/CostCurrencySettings.vue'
import CaseCompletionConditions from '../../Project/Process/Visualization/sections/SettingsModal/components/CaseCompletionConditions.vue'
import RelevantAttributesSettings from '../../Project/Process/Visualization/sections/SettingsModal/components/RelevantAttributesSettings.vue'

// const DEFAULT_VISUALIZATION = 'MODEL'

export default {
    name: 'WizardUpload',
    components: {
        CostCurrencySettings,
        CaseCompletionConditions,
        RelevantAttributesSettings,
    },
    props: {
    },
    data () {
        return {
            values: { cost: {}, caseCompletion: {} },
            projectId: this.$route.params.projectId,
            processId: this.$route.params.processId,
            openCCSettings: false,
            openCaseCompletionSettings: false,
            openRelevantAttributesSettings: false,
            settings: VueTypes.oneOfType([Array, Object]),
            caseCompletionFilled: true,
            attributes: false,
            noRelevantAttributesSelectable: false,
            relevantAttributes: null,
            variants: undefined,
            firstTimeWizard: false,
        }
    },
    mounted () {
        this.getSettings()
        this.getAttributes()
        this.getTotalInfoVariants()
        if (!this.$route.query.booster) {
            this.firstTimeWizard = true
        }
    },
    methods: {
        saveSettings (settings) {
            this.values[settings.type] = { ...settings.data }
            this.checkCaseCompletion()
        },
        getSettings () {
            if (this.$storage.get('WVS-values')) {
                this.values = this.$storage.get('WVS-values')
            } else {
                this.handleReturn()
            }
        },
        handleUpdateAttributes (relevant) {
            this.relevantAttributes = relevant
            this.checkCaseCompletion()
        },
        async updateAttributes (relevant) {
            const datasetId = this.$route.params.processId
            apiRequest(Api().datasets.updateRelevant({ datasetId, context: 'BOOSTER', params: { columnNames: relevant } }))
                .then((data) => {
                    notifySuccess(this.$t('visualization.settings.settingsSaved'))
                })
                .catch(notifyError)
        },
        async getAttributes () {
            const datasetId = this.$route.params.processId
            apiRequest(Api().datasets.getConfigurations({ datasetId }))
                .then(({ data }) => {
                    this.attributes = data[0].columns
                    this.noRelevantAttributesSelectable = data[0].columns
                        .filter(c => c.relevant === false && c.type === 'STRING' && c.name !== 'VariantId')
                        .length === 0 && data[0].columns
                        .filter(c => c.relevant === true)
                        .length === 0
                    this.relevantAttributes = data[0].columns
                        .filter(c => c.relevant === true)
                        .map(c => c.name)
                })
                .catch(notifyError)
        },
        handleCCSettings () {
            this.openCCSettings = !this.openCCSettings
            if (this.openSlaSettings) {
                this.openSlaSettings = false
            }
            if (this.openCaseCompletionSettings) {
                this.openCaseCompletionSettings = false
            }
            if (this.openRelevantAttributesSettings) {
                this.openRelevantAttributesSettings = false
            }
        },
        handleCaseCompletionSettings () {
            this.openCaseCompletionSettings = !this.openCaseCompletionSettings
            if (this.openCCSettings) {
                this.openCCSettings = false
            }
            if (this.openSlaSettings) {
                this.openSlaSettings = false
            }
            if (this.openRelevantAttributesSettings) {
                this.openRelevantAttributesSettings = false
            }
        },
        handleRelevantAttributesSettings () {
            this.openRelevantAttributesSettings = !this.openRelevantAttributesSettings
            if (this.openCCSettings) {
                this.openCCSettings = false
            }
            if (this.openCaseCompletionSettings) {
                this.openCaseCompletionSettings = false
            }
            if (this.openSlaSettings) {
                this.openSlaSettings = false
            }
        },
        handleReturn () {
            this.$router.push({ name: 'WizardSLA', params: { projectId: this.projectId, processId: this.processId }, query: { booster: this.$route.query.booster } })
        },
        async uploadData () {
            await this.updateAttributes(this.relevantAttributes)
            this.updateSettings()
        },
        updateSettings () {
            apiRequest(Api().datasets.update({
                datasetId: this.$route.params.processId,
                params: this.values,
            }))
                .then(() => {
                    notifySuccess(this.$t('visualization.settings.settingsSaved'))
                    this.setOpened()
                    this.getSettings()
                    this.checkCaseCompletion()
                    this.$router.push({ name: 'ProcessBooster', params: { projectId: this.projectId, subview: this.$route.query.booster } })
                })
                .catch(notifyError)
        },
        setOpened () {
            apiRequest(Api().datasets.setOpened({
                datasetId: this.processId,
            }))
                .catch(notifyError)
        },
        checkCaseCompletion () {
            if (
                this.values.caseCompletion &&
                this.values.caseCompletion.endingActivities &&
                this.values.caseCompletion.occurringActivities &&
                this.values.caseCompletion.startingActivities
            ) {
                if (
                    (this.values.caseCompletion.endingActivities.length > 0 ||
                    this.values.caseCompletion.occurringActivities.length > 0 ||
                    this.values.caseCompletion.startingActivities.length > 0) &&
                    (this.relevantAttributes.length > 0) && !this.noRelevantAttributesSelectable
                ) {
                    this.caseCompletionFilled = true
                } else {
                    this.caseCompletionFilled = false
                }
            }
        },
        getWarningMessage () {
            let message = ''
            if (!this.relevantAttributes.length) {
                message += this.$t('wizard.preview.relevantNoAttributesSelectedWarning')
                message += '\n'
            }
            if (this.values.caseCompletion &&
                (this.values.caseCompletion.endingActivities.length === 0 &&
                this.values.caseCompletion.occurringActivities.length === 0 &&
                this.values.caseCompletion.startingActivities.length === 0)) {
                message += this.$t('wizard.boosterWizard.performanceBooster.costAndCaseCompletion.warning')
            }
            return message
        },
        getTotalInfoVariants () {
            const { processId } = this.$route.params
            const params = { filters: [] }
            apiRequest(Api().visualizations.modelInfo({ processId, params }))
                .then((data) => {
                    this.variants = data.variants
                })
                .catch(notifyError)
        },
    },
}
</script>
<style lang="scss" scoped>
.Head {
        margin-left: 10vw;
    }
.Title {
    display: flex;
    justify-content: center;
    font-size: 24px;
    margin-top: 3vh;
    @media (max-height:$md) {
        margin-top: 0px;
        font-size: 20px;
    }
}
.Subtitle {
    display: flex;
    justify-content: center;
    font-size: 22px;
    font-weight: 600;
    margin-top: 3vh;
    margin-bottom: 2.5vh;
    @media (max-height:$md) {
        margin-top: 0.5vh;
        margin-bottom: 1vh;
        font-size: 15px;
    }
}
.Warning {
    width: 80%;
    background-color: #ffe48c;
    display: flex;
    align-items: center;
    .Icon {
        margin-left: 1vw;
        margin-right: 1vw;
    }
}
.MarginTop {
    margin-top: 20px;
    @media (max-height:$md) {
        margin-top: 10px;
    }
}
.BoostButton {
    border-color: #2596be !important;
    background-color: #2596be !important;
}
    .SettingsContainer {
        width: 100%;
        margin: 0 auto;
        min-height: 300px;
    }
    .openSettings{
        width: 40%;
    }
    .closeSettings{
        width:100%;
    }
    .Settings {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
        float: left;
        .Buttons {
            height: 5vh;
            display: flex;
            align-items: center;
            width: 100%;
            margin-right: 10px;
        }
        .q-card {
            height: 100%;
            align-items: center;
            width: 100%;
            margin-right: 10px;
            &:deep(.Body) {
                width: 100%;
                padding: 0;
                .Item {
                    padding: 20px;
                }
            }
            .CardInfo {
                width: 100%;
                display: inline-flex;
            }
        }

        .rightIcon {
            display: flex;
            justify-content: flex-end;
        }
        .q-card:hover{
            box-shadow: 0px 0px 1px 1px $alpha-primary;
            border: solid 1px rgba(68, 68, 68, 0.2);}
        .q-card {
            background-color: #f5f5f5;
        }
        .disabled-card {
            pointer-events: none;
            opacity: .5;
        }

        .card-container{
            width:100%;
        }
        .container-open {
            background-color: #ffffff;
            border: solid 1px rgba($black, 0.2);          }
        @media screen and (min-width: $md) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (min-width: $lg) {
            grid-template-columns: repeat(3, minmax(10px, 280px));
        }
        @media screen and (min-width: $xl) {
            grid-template-columns: repeat(4, minmax(10px, 280px));
        }
    }
</style>
