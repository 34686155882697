<template>
    <div class="ModelListCards">
        <Table
            ref="table"
            :data="normalizeRows"
            :loading="loading"
            :total="total"
            hideHeader
            rowKey="uuid"
            @table-request="handleFetchData"
        >
            <template #content="{ row }">
                <ModelCard
                    :row="row"
                    @handle-emit="handleEmit($event, row)"
                />
            </template>
            <template #empty>
                <EmptyList
                    :title="emptyMessage.title"
                    :text="emptyMessage.text"
                    class="mt-2 mb-2"
                >
                    <template #footer>
                        <WButton
                            class="mt-1"
                            iconLeft="upload-cloud"
                            :disabled="archived"
                            @click="$emit('onNewItem')"
                        >
                            {{ $t('projects.models.upload') }}
                        </WButton>
                    </template>
                </EmptyList>
            </template>
        </Table>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { filtersMixin } from '@/mixins'
import { Table, EmptyList } from '@/components'
import ModelCard from './ModelCard.vue'

const ACTIONS = {
    DELETE: 'DELETE',
    DOWNLOAD: 'DOWNLOAD',
    EDIT: 'EDIT',
}

export default {
    name: 'ModelListCards',
    components: {
        ModelCard,
        Table,
        EmptyList,
    },
    mixins: [filtersMixin],
    props: {
        columns: VueTypes.array,
        rows: VueTypes.array,
        total: VueTypes.number,
        search: VueTypes.string,
        loading: VueTypes.bool.def(false),
        archived: VueTypes.bool.def(false),
    },
    emits: ['onNewItem', 'onFetchData'],
    computed: {
        emptyMessage () {
            const key = `projects.models.notifications.${this.search ? 'noResult' : 'empty'}`
            return {
                title: this.$t(`${key}.title`),
                text: this.$t(`${key}.text`),
            }
        },
        normalizeRows () {
            return (this.rows || []).map(row => ({
                ...row,
                options: this.generateOptionsRow(row),
            }))
        },
    },
    methods: {
        generateOptionsRow () {
            return {
                dropdown: [
                    {
                        key: ACTIONS.EDIT, icon: 'edit', iconColor: 'primary', label: this.$t('actions.edit'), event: 'onUpdate',
                    },
                    {
                        key: ACTIONS.DOWNLOAD, icon: 'download', iconColor: 'primary', label: this.$t('actions.downloadBPMN'), event: 'onDownload',
                    },
                    {
                        key: ACTIONS.DELETE, icon: 'delete', iconColor: 'primary', label: this.$t('actions.delete'), event: 'onDelete',
                    },
                ],
            }
        },
        handleEmit (event, row) {
            this.$emit(event, row)
        },
        handleFetchData (props) {
            this.$emit('onFetchData', props)
        },
        getPagination () {
            return this.$refs.table.getPagination()
        },
        isArchived ({ deletedAt }) {
            return !!deletedAt
        },
    },
}
</script>
<style scoped lang="scss">
.Table:deep() {
  border: none !important;
  .q-table__bottom {
    margin-top: 20px;
    border: 1px solid $table-border-color;
  }
  tbody {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 16px;
    @media screen and (min-width: $md) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (min-width: $xl) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media screen and (min-width: $xxxl) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}
</style>
