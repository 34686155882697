<template>
    <div class="Wizard">
        <div
            v-if="config.steps"
            class="Header flex row-stretch-center pt-0-5 pb-0-5 pl-1 pr-1 mb-1"
        >
            <div class="Left flex row-center">
                <QBtn
                    v-if="config.exit"
                    outline
                    noCaps
                    color="primary"
                    @click="handleClickExit"
                >
                    {{ $t('wizard.cancel') }}
                </QBtn>
            </div>

            <div class="flex flex-1 row-center">
                <WText
                    v-if="config.title"
                    tag="h2"
                    weight="semi"
                    color="primary"
                    :size="18"
                    class="mb-0"
                >
                    {{ config.title }}
                </WText>
            </div>

            <div
                v-if="step"
                class="Right flex row-center"
            >
                <Transition
                    name="fade"
                >
                    <div
                        v-if="$DEV && step.mock"
                        class="Mock pr-1 mr-1"
                    >
                        <QBtn
                            outline
                            noCaps
                            color="primary"
                            :disable="config.isNavigate"
                            @click="config.mockStep"
                        >
                            Mock
                        </QBtn>
                    </div>
                </Transition>
                <Transition
                    name="fade"
                >
                    <QBtn
                        v-if="step.prev"
                        outline
                        noCaps
                        color="primary"
                        class="mr-1"
                        :disable="config.isNavigate"
                        @click="config.prevStep"
                    >
                        {{ $t('wizard.back') }}
                    </QBtn>
                </Transition>
                <transition-group
                    name="fade"
                >
                    <QBtn
                        v-if="step.next && !step.end"
                        :disable="config.isNavigate"
                        color="primary"
                        noCaps
                        @click="config.nextStep"
                    >
                        {{ $t('wizard.continue') }}
                    </QBtn>
                    <QBtn
                        v-if="step.end"
                        :disable="config.isNavigate"
                        color="primary"
                        noCaps
                        @click="config.nextStep"
                    >
                        {{ $t('wizard.finish') }}
                    </QBtn>
                </transition-group>
            </div>
        </div>

        <div
            v-else
            style="font-size: xx-large;"
            class="row justify-between MediumPadding"
        >
            <h5 class="q-px-lg">
                {{ datasetName }}
            </h5>
            <QIcon
                name="close"
                style="cursor: pointer;"
                @click="handleExitNoConfig()"
            />
        </div>
        <RouterView v-slot="{ Component, route }">
            <Transition
                v-if="projectId"
                name="main-router"
                mode="out-in"
            >
                <component
                    v-bind="route.params"
                    :is="Component"
                    :key="projectId"
                    :projectId="projectId"
                    :datasetId="datasetId"
                    class="Screen"
                    @on-change-dataset-id="handleChangeDatasetId"
                />
            </Transition>
        </RouterView>
        <ModalRequest
            ref="exitDialog"
            :title="config.title"
            :text="$t('wizard.modals.cancel.text')"
            @on-accept="handleExit"
        />
    </div>
</template>

<script>
import { ModalRequest } from '@/components'

export default {
    name: 'Wizard',
    components: {
        ModalRequest,
    },
    // TODO: revisar??
    inject: ['$DEV'],
    provide () {
        return {
            Wizard: this,
        }
    },
    data () {
        return {
            store: {},
            projectId: undefined,
            datasetId: undefined,
            datasetName: undefined,
            booster: false,
            boosterTab: undefined,
        }
    },
    computed: {
        config () {
            const { config } = this.store
            return config || {}
        },
        step () {
            const { step, steps = [] } = this.config
            return steps.find(s => s.name === step)
        },
    },
    mounted () {
        const { projectId, processId } = this.$route.params
        this.projectId = projectId
        this.datasetId = processId
        this.checkBooster()
        this.datasetName = this.$route.params.name
    },
    methods: {
        checkBooster () {
            if (this.$route.query.booster) {
                this.booster = true
                this.boosterTab = this.$route.query.booster
            }
        },

        handleChangeDatasetId (datasetId) {
            this.datasetId = datasetId
        },
        handleClickExit () {
            const { exitDialog } = this.$refs
            if (exitDialog) exitDialog.open()
        },
        handleExit () {
            this.config.exit()
        },
        handleExitNoConfig () {
            if (this.booster) {
                this.$router.push({ name: 'ProcessBooster', params: { subview: this.boosterTab } })
            } else {
                const { organizationId } = this.$route.params || {}
                this.$router.push({ name: 'Project', params: { organizationId, projectId: this.projectId } })
            }
        },
    },
}
</script>
<style scoped lang="scss">
.Header {
    position: relative;
    height: $subheader-height;
    border-bottom: 1px solid $border-color;
    box-sizing: border-box;

    .Left, .Right {
        position: absolute;
        top: 0;
        height: 100%;
    }

    .Left {
        left: 20px;
    }

    .Right {
        right: 20px;
    }

    .Mock {
        border-right: 1px solid $border-color;
    }
}

.Screen {
    &:deep() {
        .q-stepper {
            background: transparent;
        }
        .q-stepper__tab {
            .q-stepper__dot {
                background: transparent;
                color: $secondary;
                border: 2px solid $secondary;
                width: 40px;
                height: 40px;
            }
            .q-icon {
                color: $secondary;
            }

            &--active, &--done {
                .q-stepper__dot {
                    background: $secondary;
                    color: $white;
                    border: 2px solid $white;
                }
                .q-icon {
                    color: $white;
                }
            }
        }
        // Fix scroll between steps in Firefox
        .q-stepper__content .q-panel.scroll {
            overflow: hidden;
        }
    }
}
.MediumPadding {
    padding:  8px 8px 0px 8px;
}
</style>
