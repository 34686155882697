<template>
    <div class="SettingsContainer">
        <div
            class="Settings flex row-left-center"
            :class="{
                'closeSettings':
                    !openSlaSettings &&
                    !openCCSettings &&
                    !openCaseCompletionSettings &&
                    !openPredictionAlgorithms &&
                    !openRelevantAttributes,
                'openSettings':
                    openSlaSettings ||
                    openCCSettings ||
                    openCaseCompletionSettings ||
                    openPredictionAlgorithms ||
                    openRelevantAttributes,
            }"
        >
            <q-card
                bordered
                shadow
                :class="{ 'container-open': openSlaSettings, 'q-pa-md': true}"
            >
                <div
                    class="CardInfo row-left-center"
                    @click="handleSLASettings()"
                >
                    <div
                        class="Item flex row-left-center flex-nowrap card-container"
                    >
                        <q-icon
                            name="schedule"
                            color="primary"
                            size="40px"
                        />
                        <div class="flex-wrap text-card-container">
                            <WText
                                class="pl-1"
                                typo="h3"
                                tag="p"
                                weight="bold"
                            >
                                {{ $t('visualization.settings.sla.name') }}
                            </WText>
                            <WText
                                v-if="values.sla"
                                class="pl-1 slaCost"
                                color="primary"
                                tag="p"
                                weight="medium"
                            >
                                {{ values.sla.value }} {{ $t('visualization.settings.sla.units.'+values.sla.unit) }}
                            </WText>
                            <WText
                                v-if="(values.variantsSla && values.variantsSla.length > 0) || (values.customSla && values.customSla.length > 0)"
                                class="pl-1 slaCost"
                                color="primary"
                                tag="p"
                                weight="medium"
                            >
                                {{ getAdvacedSlaText() }}
                            </WText>
                        </div>
                    </div>
                    <div class="Item rightIcon">
                        <q-icon
                            v-if="!openSlaSettings"
                            name="chevron_right"
                        />
                        <q-icon
                            v-if="openSlaSettings"
                            name="chevron_left"
                        />
                    </div>
                </div>
            </q-card>
            <q-card
                bordered
                shadow
                :class="{ 'container-open': openCaseCompletionSettings, 'q-pa-md': true}"
            >
                <div
                    class="CardInfo row-left-center"
                    @click="handleCaseCompletionSettings()"
                >
                    <div
                        class="Item flex row-left-center flex-nowrap card-container"
                    >
                        <div>
                            <q-icon
                                name="schema"
                                color="primary"
                                size="40px"
                            />
                        </div>
                        <div>
                            <WText
                                class="pl-1"
                                typo="h3"
                                tag="p"
                                weight="bold"
                            >
                                {{ $t('visualization.settings.caseCompletion.name') }}
                            </WText>
                            <WText
                                v-if="values.caseCompletion && values.caseCompletion.startingActivities.length === 0
                                    && values.caseCompletion.occurringActivities.length === 0
                                    && values.caseCompletion.endingActivities.length === 0"
                                class="pl-1"
                                color="primary"
                                tag="p"
                                weight="medium"
                            >
                                {{ $t('visualization.settings.caseCompletionConditionsNoConf') }}
                            </WText>
                            <WText
                                v-else
                                class="pl-1"
                                color="primary"
                                tag="p"
                                weight="medium"
                            >
                                {{ $t('visualization.settings.caseCompletionConditionsConf') }}
                            </WText>
                        </div>
                    </div>
                    <div class="Item rightIcon">
                        <q-icon
                            v-if="!openCaseCompletionSettings"
                            name="chevron_right"
                        />
                        <q-icon
                            v-if="openCaseCompletionSettings"
                            name="chevron_left"
                        />
                    </div>
                </div>
            </q-card>
            <q-card
                bordered
                shadow
                :class="{ 'container-open': openCCSettings, 'q-pa-md': true}"
            >
                <div
                    class="CardInfo row-left-center"
                    @click="handleCCSettings()"
                >
                    <div
                        class="Item flex row-left-center flex-nowrap card-container"
                    >
                        <q-icon
                            name="payments"
                            color="primary"
                            size="40px"
                        />
                        <div class="flex-wrap">
                            <WText
                                class="pl-1"
                                typo="h3"
                                tag="p"
                                weight="bold"
                            >
                                {{ $t('visualization.settings.costs.name') }}
                            </WText>
                            <WText
                                v-if="values.cost"
                                class="pl-1"
                                color="primary"
                                tag="p"
                                weight="medium"
                            >
                                {{ App.numberLocationFormat(values.cost.value) }} {{ $t('visualization.settings.costs.units.'+values.cost.unit) }}
                            </WText>
                        </div>
                    </div>
                    <div class="Item rightIcon">
                        <q-icon
                            v-if="!openCCSettings"
                            name="chevron_right"
                        />
                        <q-icon
                            v-if="openCCSettings"
                            name="chevron_left"
                        />
                    </div>
                </div>
            </q-card>
            <q-card
                bordered
                shadow
                :class="{ 'container-open': openPredictionAlgorithms, 'disabled-card': predictionAlgorithmDisabled, 'q-pa-md': true }"
            >
                <div
                    class="CardInfo row-left-center"
                    @click="handlePredictionAlgorithm()"
                >
                    <div
                        class="Item flex row-left-center flex-nowrap card-container"
                    >
                        <QIcon
                            size="xl"
                            color="primary"
                            name="batch_prediction"
                        />
                        <div class="flex-wrap text-card-container">
                            <WText
                                class="pl-1"
                                typo="h3"
                                tag="p"
                                weight="bold"
                            >
                                {{ $t('visualization.settings.predictionAlgorithm.name') }}
                            </WText>
                            <WText
                                v-if="!predictionAlgorithmDisabled"
                                class="pl-1 slaCost"
                                color="primary"
                                tag="p"
                                weight="medium"
                            >
                                {{ values.predictionAlgorithm }}
                            </WText>
                            <WText
                                v-else
                                class="pl-1 slaCost"
                                color="primary"
                                tag="p"
                                weight="medium"
                            >
                                {{ $t('visualization.settings.predictionAlgorithm.disabled') }}
                            </WText>
                        </div>
                    </div>
                    <div class="Item rightIcon">
                        <q-icon
                            v-if="!openPredictionAlgorithms"
                            name="chevron_right"
                        />
                        <q-icon
                            v-if="openPredictionAlgorithms"
                            name="chevron_left"
                        />
                    </div>
                </div>
            </q-card>
            <q-card
                bordered
                shadow
                :class="{ 'container-open': openRelevantAttributes, 'disabled-card': relevantAttributesDisabled, 'q-pa-md': true }"
            >
                <div
                    class="CardInfo row-left-center"
                    @click="handleRelevantAttributesSettings()"
                >
                    <div
                        class="Item flex row-left-center flex-nowrap card-container"
                    >
                        <QIcon
                            size="xl"
                            color="primary"
                            name="star"
                        />
                        <div class="flex-wrap text-card-container">
                            <WText
                                class="pl-1"
                                typo="h3"
                                tag="p"
                                weight="bold"
                            >
                                {{ $t('visualization.settings.relevantAttributes.name') }}
                            </WText>
                            <WText
                                v-if="!relevantAttributes"
                                class="pl-1 slaCost"
                                color="primary"
                                tag="p"
                                weight="medium"
                            >
                                {{ $t('visualization.settings.relevantAttributes.noRelevant') }}
                            </WText>
                            <WText
                                v-else-if="!relevantAttributesDisabled"
                                class="pl-1 slaCost"
                                color="primary"
                                tag="p"
                                weight="medium"
                            >
                                {{ relevantAttributes }}
                            </WText>
                            <WText
                                v-else
                                class="pl-1 slaCost"
                                color="primary"
                                tag="p"
                                weight="medium"
                            >
                                {{ $t('visualization.settings.relevantAttributes.disabled') }}
                            </WText>
                        </div>
                    </div>
                    <div class="Item rightIcon">
                        <q-icon
                            v-if="!openRelevantAttributes"
                            name="chevron_right"
                        />
                        <q-icon
                            v-if="openRelevantAttributes"
                            name="chevron_left"
                        />
                    </div>
                </div>
            </q-card>
        </div>
        <div>
            <SlaSettings
                ref="SlaSettings"
                :isOpen="openSlaSettings"
                :values="{ sla: values.sla, customSla: values.customSla, variantsSla: values.variantsSla }"
                @SlaConfig="saveSettings"
            />
            <CostCurrencySettings
                ref="CostCurrencySettings"
                :values="{ cost: values.cost, activityCosts: values.activityCosts }"
                :isOpen="openCCSettings"
                @CostCurrencyConfig="saveSettings"
            />
            <CaseCompletionConditions
                ref="CaseCompletionConditions"
                class="caseCompletionSettings"
                :variants="totalInfo.variants"
                :isOpen="openCaseCompletionSettings"
                :values="values.caseCompletion"
                @CaseCompletionConditionsConfig="saveSettings"
            />
            <PredictionAlgorithmSettings
                ref="PredictionAlgorithm"
                :isOpen="openPredictionAlgorithms"
                :values="{ predictionAlgorithm: values.predictionAlgorithm,
                           sequenceModelStatus: values.sequenceModelStatus,
                           durationModelStatus: values.durationModelStatus,
                }"
                @PredictionAlgorithm="saveSettings"
            />
            <RelevantAttributesSettings
                ref="RelevantAttributesSettings"
                class="relevantAttributesSettings"
                :attributes="attributes"
                :isOpen="openRelevantAttributes"
                @UpdateAttributes="handleUpdateAttributes"
            />
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import SlaSettings from './SlaSettings.vue'
import CostCurrencySettings from './CostCurrencySettings.vue'
import CaseCompletionConditions from './CaseCompletionConditions.vue'
import PredictionAlgorithmSettings from './PredictionAlgorithmSettings.vue'
import RelevantAttributesSettings from './RelevantAttributesSettings.vue'

export default {
    name: 'SettingsModalSection',
    components: {
        SlaSettings,
        CostCurrencySettings,
        CaseCompletionConditions,
        PredictionAlgorithmSettings,
        RelevantAttributesSettings,
    },
    inject: ['App'],
    props: {
        values: VueTypes.object,
        title: VueTypes.string,
        attributes: VueTypes.array,
        relevantAttributes: VueTypes.String,
        totalInfo: VueTypes.object,
    },
    emits: ['SettingsConfig', 'UpdateRelevant'],
    data () {
        return {
            inputOptions: false,
            openSlaSettings: false,
            openCCSettings: false,
            openCaseCompletionSettings: false,
            openPredictionAlgorithms: false,
            openRelevantAttributes: false,
            isOpen: false,
            predictionAlgorithm: null,
            predictionAlgorithmDisabled: true,
            relevantAttributesDisabled: true,
        }
    },
    watch: {
        'values.sla': {
            handler: 'checkPredictionAvailable',
            immediate: true,
        },
        'values.caseCompletion': {
            handler: 'checkPredictionAvailable',
            immediate: true,
        },
        attributes: {
            handler: 'checkRelevantAttrAvailable',
            immediate: true,
        },
    },
    mounted () {
    },
    methods: {
        saveSettings (settings) {
            this.values[settings.type] = {
                ...settings.data,
            }
            this.$emit('SettingsConfig', settings)
        },
        handleUpdateAttributes (relevant) {
            this.$emit('UpdateRelevant', relevant)
        },
        handleSLASettings () {
            this.openSlaSettings = !this.openSlaSettings
            if (this.openCCSettings) {
                this.openCCSettings = false
            }
            if (this.openCaseCompletionSettings) {
                this.openCaseCompletionSettings = false
            }
            if (this.openPredictionAlgorithms) {
                this.openPredictionAlgorithms = false
            }
            if (this.openRelevantAttributes) {
                this.openRelevantAttributes = false
            }
        },
        handleCCSettings () {
            this.openCCSettings = !this.openCCSettings
            if (this.openSlaSettings) {
                this.openSlaSettings = false
            }
            if (this.openCaseCompletionSettings) {
                this.openCaseCompletionSettings = false
            }
            if (this.openPredictionAlgorithms) {
                this.openPredictionAlgorithms = false
            }
            if (this.openRelevantAttributes) {
                this.openRelevantAttributes = false
            }
        },
        handleCaseCompletionSettings () {
            this.openCaseCompletionSettings = !this.openCaseCompletionSettings
            if (this.openCCSettings) {
                this.openCCSettings = false
            }
            if (this.openSlaSettings) {
                this.openSlaSettings = false
            }
            if (this.openPredictionAlgorithms) {
                this.openPredictionAlgorithms = false
            }
            if (this.openRelevantAttributes) {
                this.openRelevantAttributes = false
            }
        },
        handlePredictionAlgorithm () {
            this.openPredictionAlgorithms = !this.openPredictionAlgorithms
            if (this.openSlaSettings) {
                this.openSlaSettings = false
            }
            if (this.openCCSettings) {
                this.openCCSettings = false
            }
            if (this.openCaseCompletionSettings) {
                this.openCaseCompletionSettings = false
            }
            if (this.openRelevantAttributes) {
                this.openRelevantAttributes = false
            }
        },
        handleRelevantAttributesSettings () {
            this.openRelevantAttributes = !this.openRelevantAttributes
            if (this.openSlaSettings) {
                this.openSlaSettings = false
            }
            if (this.openCCSettings) {
                this.openCCSettings = false
            }
            if (this.openCaseCompletionSettings) {
                this.openCaseCompletionSettings = false
            }
            if (this.openPredictionAlgorithms) {
                this.openPredictionAlgorithms = false
            }
        },
        checkPredictionAvailable () {
            if (this.values.sla !== null && this.values.caseCompletion !== null) {
                this.predictionAlgorithmDisabled = false
            } else {
                this.predictionAlgorithmDisabled = true
            }
        },
        checkRelevantAttrAvailable () {
            const relevantAttributes = this.attributes.filter(c => c.type === 'STRING').map(c => c.name)
            if (relevantAttributes.length > 0) {
                this.relevantAttributesDisabled = false
            } else {
                this.relevantAttributesDisabled = true
            }
        },
        getAdvacedSlaText () {
            let advancedSlaText = ''
            const variansSla = this.values.variantsSla && this.values.variantsSla.length > 0
            const customSla = this.values.customSla && this.values.customSla.length > 0
            if (variansSla && !customSla) {
                advancedSlaText = this.$t('visualization.settings.sla.variantsSlaPresent')
            } else if (!variansSla && customSla) {
                advancedSlaText = this.$t('visualization.settings.sla.customSlaPresent')
            } else if (variansSla && customSla) {
                advancedSlaText = this.$t('visualization.settings.sla.variantsAndCustomSlaPresent')
            }
            return advancedSlaText
        },
    },
}
</script>
<style lang="scss" scoped>
.arrowContainer{
    width:45%;
}
.titleContainer{
    width:65%;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 10px;
}
.headerContainer{
    display:flex;
    padding-bottom: 20px;
}
.title {
    text-align: center;
}
    .SettingsContainer {
        margin: 0 auto;
        padding-top: 20px;
    }
    .openSettings{
        width: 40%;;
    }
    .closeSettings{
        width:100%;
    }
    .Settings {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
        float: left;
        .Buttons {
            height: 5vh;
            display: flex;
            align-items: center;
            width: 100%;
            margin-right: 10px;
        }
        .q-card {

            height: 100%;
            align-items: center;
            width: 100%;
            margin-right: 10px;
            &:deep(.Body) {
                width: 100%;
                padding: 0;
                .Item {
                    padding: 1.7vh;
                }
            }
            .CardInfo {
                width: 100%;
                display: inline-flex;
            }
        }

        .rightIcon {
            display: flex;
            justify-content: flex-end;
        }
        .q-card:hover{
            box-shadow: 0px 0px 1px 1px $alpha-primary;
            border: solid 1px rgba(68, 68, 68, 0.2);}
        .q-card {
            background-color: #f5f5f5;
        }

        .card-container{
            width:100%;
        }
        .container-open {
            background-color: #ffffff;
            border: solid 1px rgba($black, 0.2);          }
        @media screen and (min-width: $md) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (min-width: $lg) {
            grid-template-columns: repeat(3, minmax(10px, 280px));
        }
        @media screen and (min-width: $xl) {
            grid-template-columns: repeat(4, minmax(10px, 280px));
        }
    }
    .disabled-card {
  pointer-events: none;
  opacity: .5;
}
</style>
