<template>
    <Modal
        :isOpen="opened"
        @close="close"
    >
        <template #header>
            <WText
                tag="h3"
                :size="16"
                weight="semi"
                class="mb-0"
            >
                {{ $t('visualization.settings.sla.variantSlaModal.title') }}
            </WText>
        </template>
        <template #body>
            <QForm
                class="align-center q-gutter-md q-my-md"
                @submit="handleSubmit"
            >
                <div class="row mt-1">
                    <QSelect
                        v-model="variant"
                        class="Field"
                        outlined
                        dense
                        labelColor="primary"
                        color="primary"
                        :label="$t('visualization.settings.sla.variantSlaModal.selectVariant')"
                        :options="variants"
                        :disable="edit"
                    />
                </div>
                <div class="OptionsGroup row">
                    <InputNumeric
                        v-model="number"
                        :decimalNum="0"
                        :decimalStrictLimit="true"
                        :label="$t('visualization.settings.sla.number')"
                        standout
                        outlined
                        :min="0"
                        type="number"
                        class="col"
                    />
                    <QSelect
                        v-model="unit"
                        outlined
                        class="col"
                        labelColor="primary"
                        color="primary"
                        :label="$t('visualization.settings.sla.unit')"
                        :options="slaUnits"
                    />
                </div>
                <div class="justify-end row q-my-lg">
                    <QBtn
                        noCaps
                        unelevated
                        color="primary"
                        type="submit"
                    >
                        {{ $t('visualization.settings.sla.add') }}
                    </QBtn>
                </div>
            </QForm>
        </template>
    </Modal>
</template>

<script>
import VueTypes from 'vue-types'
import { Modal, InputNumeric } from '@/components'

export default {
    name: 'VariantSlaModal',
    components: {
        Modal,
        InputNumeric,
    },
    props: {
        variants: VueTypes.array,
    },
    emits: [
        'addVariantSla',
    ],
    data () {
        return {
            opened: false,
            variant: '',
            edit: false,
            variantOptions: [],
            unit: { value: 'minutes', label: this.$t('visualization.settings.sla.units.minutes') },
            number: 0,
        }
    },
    computed: {
        slaUnits () {
            return [
                {
                    label: this.$t('visualization.settings.sla.units.minutes'),
                    value: 'minutes',
                },
                {
                    label: this.$t('visualization.settings.sla.units.hours'),
                    value: 'hours',
                },
                {
                    label: this.$t('visualization.settings.sla.units.days'),
                    value: 'days',
                },
                {
                    label: this.$t('visualization.settings.sla.units.weeks'),
                    value: 'weeks',
                },
                {
                    label: this.$t('visualization.settings.sla.units.months'),
                    value: 'months',
                },
            ]
        },
    },
    mounted () {
    },
    methods: {
        open (editVariantSla = undefined) {
            if (editVariantSla !== undefined) {
                this.variant = 'variantId' in editVariantSla
                    ? this.variants.find(v => v.value === editVariantSla.variantId)
                    : { label: this.$t('visualization.settings.sla.variantSlaModal.all'), value: 'All' }
                // this.unit = editVariantSla.unit
                this.unit = { value: editVariantSla.unit, label: this.$t(`visualization.settings.sla.units.${editVariantSla.unit}`) }
                this.number = editVariantSla.value
                this.edit = true
                this.opened = true
            } else {
                this.edit = false
                this.reset()
                this.opened = true
            }
        },
        close () {
            this.opened = false
        },
        reset () {
            [this.variant] = this.variantOptions
            this.unit = { value: 'minutes', label: this.$t('visualization.settings.sla.units.minutes') }
            this.number = 0
        },
        handleSubmit () {
            this.edit
                ? this.handleEditVariantSla()
                : this.handleAddVariantSla()
        },
        handleAddVariantSla () {
            this.$emit('addVariantSla', {
                variant: this.variant,
                number: this.number,
                unit: this.unit,
            })
        },
        handleEditVariantSla () {
            this.$emit('editVariantSla', {
                variant: this.variant,
                number: this.number,
                unit: this.unit,
            })
        },
    },
}
</script>
<style scoped lang="scss">
.Modal {
    &:deep(.Dialog) {
        width: 100%;
        max-width: 600px;
    }

    .OptionsGroup {
        justify-content: space-between;
        gap: 10px;
    }

    .q-btn {
        padding-left: 25px;
        padding-right: 25px;
    }

    .Field {
        width: 100%;
        height: auto;
        &:deep(.q-field__control) {
                height: 50px;
                .q-field__control-container .q-field__native span {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        line-height: 34px;
                }
                .q-field__append {
                    height: 50px;
                }
        }
    }
}
</style>
