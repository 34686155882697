<template>
    <div class="Container">
        <div class="ChartTitle">
            {{ $t('booster.prediction.ongoingCases.ongoingCaseList') }}
        </div>
        <q-inner-loading :showing="isLoading" />
        <div
            v-if="!isLoading"
            class="TableSelect"
        >
            <div
                v-if="rows"
                class="element"
            >
                <QTable
                    ref="myTable"
                    v-model:pagination="pagination"
                    class="Table"
                    :rows="rows"
                    :columns="columns"
                    :rowsPerPageOptions="rowsPerPageOptions"
                    rowKey="caseId"
                    flat
                    outlined
                    dense
                    separator="vertical"
                    bordered
                    wrapCells
                    @request="emitRequest"
                >
                    <template #body="props">
                        <QTr
                            :props="props"
                            class="cursor-pointer"
                        >
                            <QTd
                                key="caseId"
                                class="caseId"
                            >
                                {{ props.row.caseId }}
                                <QTooltip>
                                    {{ props.row.caseId }}
                                </QTooltip>
                            </QTd>
                            <QTd
                                key="currentDuration"
                                :class="checkSla(props.row.currentDuration.number)"
                            >
                                {{ props.row.currentDuration.label }}
                            </QTd>
                            <QTd
                                key="estimatedDuration"
                                :class="checkSla(props.row.estimatedDuration.number)"
                            >
                                {{ props.row.estimatedDuration.label }}
                            </QTd>
                            <QTd
                                key="state"
                                :class="getStateClass(props.row.state)"
                            >
                                {{ props.row.state }}
                            </QTd>
                            <QTd key="search-icon">
                                <QIcon
                                    name="search"
                                    @click="(event) => selectRow(props.row, event)"
                                />
                            </QTd>
                        </QTr>
                    </template>
                    <template #no-data="{ message }">
                        <div class="full-width row flex-center text-accent q-gutter-sm">
                            <span>
                                {{ message }}
                            </span>
                        </div>
                    </template>
                </QTable>
            </div>
            <div class="FiltersContainer">
                <label
                    class="LabelFiltersContainer"
                >
                    {{ $t('booster.prediction.ongoingCases.showOnly') }}
                </label>
                <QSelect
                    v-model="selectedAttr"
                    class="Select"
                    dense
                    outlined
                    :options="attributes.map(i => i.name)"
                    @update:model-value="checkValue"
                />
                <QSelect
                    v-if="selectedAttr && (selectedAttr !== $t('booster.state.all'))"
                    v-model="selectedValue"
                    dense
                    outlined
                    :options="(attributes.find(i => i.name === selectedAttr)).values"
                    @update:model-value="updateValue"
                />
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import moment from 'moment'
import {
    Api, apiRequest, notifyError,
} from '@/api'

const ROWS_PER_PAGE_OPTIONS = [
    5, 10, 15, 20, 25, 30,
]

export default {
    name: 'OngoingCaseList',
    props: {
        attributes: VueTypes.array,
        sla: VueTypes.number,
        processId: VueTypes.string,
    },
    data () {
        return {
            columns: undefined,
            rows: undefined,
            total: 0,
            isLoading: false,
            filteredTraces: [],
            selectedAttr: this.$t('booster.state.all'),
            selectedValue: undefined,
            selectedRow: null,
            mostrarElemento: false,
            rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
            page: 1,
            rowsPerPage: 5,
            rowsNumber: 0,
        }
    },
    computed: {
        pagination: {
            get () {
                return {
                    page: this.page,
                    rowsPerPage: this.rowsPerPage,
                    rowsNumber: this.rowsNumber,
                }
            },
            set (value) {
                this.page = value.page
                this.rowsPerPage = value.rowsPerPage
            },
        },
    },
    mounted () {
        this.selectDefaultAttribute()
    },
    methods: {
        toggleExpand (row) {
            this.expanded[row.id] = !this.expanded[row.id]
        },
        selectDefaultAttribute () {
            if (this.attributes[0].name !== this.$t('booster.state.all')) {
                this.attributes.unshift({ name: this.$t('booster.state.all') })
            }
            this.checkValue()
        },
        checkValue () {
            this.selectedValue = undefined
            this.page = 1
            if (this.selectedAttr === this.$t('booster.state.all')) {
                const params = {
                    attribute: null,
                    value: null,
                    start: this.page - 1,
                    limit: this.rowsPerPage,
                }
                this.getTraces(params)
            } else {
                // eslint-disable-next-line prefer-destructuring
                this.selectedValue = this.attributes.find(i => i.name === this.selectedAttr).values[0]
                const params = {
                    attribute: this.selectedAttr,
                    value: this.selectedValue,
                    start: this.page - 1,
                    limit: this.rowsPerPage,
                }
                this.getTraces(params)
            }
        },
        getStateClass (state) {
            if (state === this.$t(`booster.prediction.ongoingCases.above`)) {
                return 'AboveState'
            } if (state === this.$t(`booster.prediction.ongoingCases.Good`)) {
                return 'BelowState'
            }
            return 'IntermediateState'
        },
        checkSla (value) {
            if (value > this.sla) {
                return 'AboveState'
            }
            if (value < this.sla) {
                return 'BelowState'
            }
            return 'IntermediateState'
        },
        updateValue (value) {
            this.page = 1
            // eslint-disable-next-line prefer-destructuring
            this.selectedValue = value
            const params = {
                attribute: this.selectedAttr,
                value: this.selectedValue,
                start: this.page - 1,
                limit: this.rowsPerPage,
            }
            this.getTraces(params)
        },
        async getTraces (params) {
            this.isLoading = true
            if (!params) {
                this.page = 1
            }
            const formattedParams = params || {
                attribute: this.selectedAttr,
                value: this.selectedValue,
                start: this.page - 1,
                limit: this.rowsPerPage,
            }
            apiRequest(Api().booster.ongoing({ processId: this.processId, params: formattedParams }))
                .then((res) => {
                    this.rowsNumber = res.total
                    if (res.total > 0) {
                        this.rows = res.rows.map((row) => {
                            // eslint-disable-next-line no-param-reassign, max-len
                            row.currentDuration = { label: moment.duration(row.currentDuration, 'seconds').format('d[d] h[h]:m[m]:s[s]', { largest: 2, trim: false }), number: row.currentDuration }
                            // eslint-disable-next-line no-param-reassign, max-len
                            row.estimatedDuration = { label: moment.duration(row.estimatedDuration, 'seconds').format('d[d] h[h]:m[m]:s[s]', { largest: 2, trim: false }), number: row.estimatedDuration }
                            // eslint-disable-next-line no-param-reassign, max-len
                            row.state = row.state === 'Above SLA' ? row.state = this.$t(`booster.prediction.ongoingCases.above`) : row.state = this.$t(`booster.prediction.ongoingCases.${row.state}`)
                            return row
                        })
                        const keys = Object.keys(res.rows[0])
                        this.columns = keys.map(key => ({
                            name: key,
                            align: 'left',
                            field: key,
                            label: this.$t(`booster.prediction.ongoingCases.${key}`),
                            // style: key === 'caseId' ? 'white-space: nowrap; overflow: hidden; text-overflow: ellipsis;' : 'width: fit-content;',
                        }))
                        this.columns.push({
                            name: '',
                            align: 'left',
                            field: '',
                            label: '',
                            style: 'color: #33699a',
                        })
                    } else {
                        this.rows = undefined
                        this.columns = undefined
                    }
                })
                .catch(notifyError)
                .finally(() => {
                    this.isLoading = false
                })
        },
        selectRow (row, event) {
            this.selectedRow = row.caseId
            // lupita inicial
            // const rect = event.target.getBoundingClientRect()
            // const tableRect = this.$refs.myTable.$el.getBoundingClientRect()
            // this.$refs.button.$el.style.top = `${rect.top - tableRect.top}px`
            // this.mostrarElemento = true
            // añadido
            this.traceSelected(this.selectedRow)
        },
        traceSelected (caseId) {
            const { organizationId } = this.$route.params
            const { projectId } = this.$route.params
            const { processId } = this.$route.params
            sessionStorage.setItem('selectedCaseId', caseId)
            this.$router.push({
                name: 'ProcessVisualization',
                params: {
                    organizationId,
                    projectId,
                    processId,
                    tab: 'TRACES',
                },
            })
        },
        emitRequest (props) {
            this.pagination = props.pagination
            const rowsPerPage = props.pagination.rowsPerPage === 0 ? 9999 : props.pagination.rowsPerPage
            const page = props.pagination.page - 1
            if (this.selectedAttr === this.$t('booster.state.all')) {
                const params = {
                    attribute: null,
                    value: null,
                    start: page,
                    limit: rowsPerPage,
                }
                this.getTraces(params)
            } else {
                // eslint-disable-next-line prefer-destructuring
                const params = {
                    attribute: this.selectedAttr,
                    value: this.selectedValue,
                    start: page,
                    limit: rowsPerPage,
                }
                this.getTraces(params)
            }
        },
    },
}
</script>
<style scoped lang="scss">
.Container {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
}
.caseId {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ChartTitle {
    margin: 1vh 0px;
    margin-right: 2em;
    font-size: 15px;
    @media screen and (min-height: $md) {
        font-size: large;
    }
    height: fit-content;
}
.TableSelect {
    flex-grow: 1;
    max-height: 80%;
    max-width: 100%;
}
.FiltersContainer {
    display: flex;
    justify-content: row;
    width: 100%;
    height: fit-content;
    margin-top: 0.7vh;
}
.LabelFiltersContainer {
        margin: auto 0px;
        margin-right: 1em;
        color:#33699a;
    }
.caseId {
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.isLoading {
    display: flex;
    top: 75%;
    left: 25%;
    max-width: 50px;
    max-height: 50px;
}
.selected-row > * {
    background-color: $gray-07;
  }
.Select {
    margin-right: 5px;
}
.AboveState {
    color: #fb2332;
}

.BelowState {
    color: #04c48c;
}

.IntermediateState {
    color: #fc8a28;
}
.element {
    width:95%;
    display:flex;
    max-height: 90%;
    .button {
        width: fit-content;
    }
    .Table {
        flex-grow: 1;
        &:deep(thead) {
            position: sticky;
            top: 0;
            background: white;
            z-index: 99;
        }
        ::-webkit-scrollbar {
            width: 5px;
          }
          ::-webkit-scrollbar-thumb {
            background: #DDDDDD;
            border-radius: 10px;
          }
    }
}

#button {
    opacity: 0;
    transition: opacity 1s ease;
  }
  #button.mostrar {
    opacity: 1;
  }
</style>
