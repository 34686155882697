<template>
    <div
        v-if="isOpen"
        class="CaseCompletionSettings"
    >
        <div class="CaseCompletion">
            <WText
                class="title"
                weight="bold"
                color="primary"
            >
                {{ $t('visualization.settings.caseCompletion.name') }}
            </WText>
            <div class="container">
                <WText
                    weight="bold"
                    color="primary"
                >
                    {{ $t('visualization.settings.startingActivities') }}
                </WText>
                <div class="OptionsGroup">
                    <QSelect
                        v-model="startingA"
                        outlined
                        multiple
                        dense
                        hideSelected
                        class="Options"
                        labelColor="primary"
                        color="primary"
                        :label="$t('visualization.settings.activities')"
                        :options="startingActivityOptions"
                        :loading="startingActivityOptions.length === 0"
                        @update:modelValue="saveWithoutButton"
                    >
                        <template #option="scope">
                            <QItem v-bind="scope.itemProps">
                                <QItemSection>
                                    <QItemLabel v-html="scope.opt" />
                                </QItemSection>
                                <QItemSection side>
                                    <QToggle
                                        v-model="scope.selected"
                                        :val="scope.opt"
                                        @update:modelValue="handleStartingActivitiesInput(scope.opt)"
                                    />
                                </QItemSection>
                            </QItem>
                        </template>
                    </QSelect>
                </div>
                <div
                    v-for="(selected, index) in startingA"
                    :key="index"
                >
                    <QChip
                        removable
                        color="primary"
                        textColor="white"
                        @remove="deleteIndexValue(index, 'startingA' )"
                    >
                        {{ selected }}
                    </Qchip>
                </div>
            </div>
            <div class="container">
                <WText
                    weight="bold"
                    color="primary"
                >
                    {{ $t('visualization.settings.occurringActivities') }}
                </WText>
                <div class="OptionsGroup">
                    <QSelect
                        v-model="occurringA"
                        outlined
                        multiple
                        dense
                        hideSelected
                        class="Options"
                        labelColor="primary"
                        color="primary"
                        :label="$t('visualization.settings.activities')"
                        :options="activities"
                        :loading="activities.length === 0"
                    >
                        <template #option="scope">
                            <QItem v-bind="scope.itemProps">
                                <QItemSection>
                                    <QItemLabel v-html="scope.opt" />
                                </QItemSection>
                                <QItemSection side>
                                    <QToggle
                                        v-model="scope.selected"
                                        :val="scope.opt"
                                        @update:modelValue="handleOccurringActivitiesInput(scope.opt)"
                                    />
                                </QItemSection>
                            </QItem>
                        </template>
                    </QSelect>
                </div>
                <div
                    v-for="(selected, index) in occurringA"
                    :key="index"
                >
                    <QChip
                        removable
                        color="primary"
                        textColor="white"
                        @remove="deleteIndexValue(index, 'occurringA' )"
                    >
                        {{ selected }}
                    </Qchip>
                </div>
            </div>
            <div class="container">
                <WText
                    weight="bold"
                    color="primary"
                >
                    {{ $t('visualization.settings.endingActivities') }}
                </WText>
                <div class="OptionsGroup">
                    <QSelect
                        v-model="endingA"
                        outlined
                        multiple
                        dense
                        hideSelected
                        class="Options"
                        labelColor="primary"
                        color="primary"
                        :label="$t('visualization.settings.activities')"
                        :options="endingActivityOptions"
                        :loading="endingActivityOptions.length === 0"
                    >
                        <template #option="scope">
                            <QItem v-bind="scope.itemProps">
                                <QItemSection>
                                    <QItemLabel v-html="scope.opt" />
                                </QItemSection>
                                <QItemSection side>
                                    <QToggle
                                        v-model="scope.selected"
                                        :val="scope.opt"
                                        @update:modelValue="handleEndingActivitiesInput(scope.opt)"
                                    />
                                </QItemSection>
                            </QItem>
                        </template>
                    </QSelect>
                </div>
                <div
                    v-for="(selected, index) in endingA"
                    :key="index"
                >
                    <QChip
                        removable
                        color="primary"
                        textColor="white"
                        @remove="deleteIndexValue(index, 'endingA' )"
                    >
                        {{ selected }}
                    </Qchip>
                </div>
            </div>
            <div
                v-if="saveButton"
                class="row justify-end mt-1"
            >
                <QBtn
                    outline
                    color="primary"
                    class="buttons"
                    noCaps
                    :label="$t('visualization.filters.save')"
                    @click="saveWithButton"
                />
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import {
    Api,
    apiRequest,
    notifyError,
} from '@/api'

export default {
    name: 'CaseCompletionConditions',
    components: {},
    mixins: [],
    props: {
        saveButton: VueTypes.bool.def(true),
        isOpen: VueTypes.bool.def(false),
        attributes: VueTypes.array,
        values: VueTypes.object,
        variants: undefined,
    },
    emits: ['CaseCompletionConditionsConfig'],
    data () {
        return {
            inputOptions: false,
            startingA: [],
            occurringA: [],
            endingA: [],
            selectedStartingA: [],
            selectedOccurringA: [],
            selectedEndingA: [],
            timeout: null,
            optionValue: 'id',
            optionLabel: 'label',
            title: "$t('visualization.settings.cost.name')",
            startingActivityOptions: [],
            endingActivityOptions: [],
            activities: [],
        }
    },
    computed: {
    },
    watch: {
        variants: {
            handler () {
                if (this.variants !== undefined) {
                    this.getSplitFilteredActivities()
                }
            },
            immediate: true,
        },
    },
    beforeMount () {
    },
    mounted () {
        this.checkIfValues()
        this.saveWithoutButton()
    },
    methods: {
        checkIfValues () {
            if (this.values) {
                this.endingA = this.values.endingActivities
                this.occurringA = this.values.occurringActivities
                this.startingA = this.values.startingActivities
                this.selectedStartingA = this.startingA
                this.selectedOccurringA = this.occurringA
                this.selectedEndingA = this.endingA
            }
        },
        saveWithoutButton () {
            if (!this.saveButton) {
                this.saveCaseCC()
            }
        },
        saveWithButton () {
            this.saveCaseCC()
        },
        saveCaseCC () {
            const caseCompletion = {
                endingActivities: this.endingA ? this.endingA : [],
                occurringActivities: this.occurringA ? this.occurringA : [],
                startingActivities: this.startingA ? this.startingA : [],
            }

            this.$emit('CaseCompletionConditionsConfig', { data: caseCompletion, type: 'caseCompletion' })
        },

        async getSplitFilteredActivities () {
            const { processId } = this.$route.params
            const params = { filters: [] }
            apiRequest(Api().visualizations.splitFilteredActivities({ processId, params }))
                .then((data) => {
                    this.startingActivityOptions = data.firstActivities
                    this.endingActivityOptions = data.lastActivities
                    this.activities = data.allActivities
                })
                .catch(notifyError)
        },
        deleteIndexValue (index, nombre) {
            if (nombre === 'startingA') {
                this.startingA.splice(index, 1)
            } else if (nombre === 'occurringA') {
                this.occurringA.splice(index, 1)
            } else {
                this.endingA.splice(index, 1)
            }
            this.saveWithoutButton()
        },
        handleStartingActivitiesInput (selected) {
            if (!this.startingA.includes(selected)) {
                this.startingA.push(selected)
            } else {
                const index = this.startingA.indexOf(selected)
                if (index !== -1) {
                    this.startingA.splice(index, 1)
                }
            }
            this.selectedStartingA = this.startingA
            this.saveWithoutButton()
        },
        handleOccurringActivitiesInput (selected) {
            if (!this.occurringA.includes(selected)) {
                this.occurringA.push(selected)
            } else {
                const index = this.occurringA.indexOf(selected)
                if (index !== -1) {
                    this.occurringA.splice(index, 1)
                }
            }
            this.selectedOccurringA = this.occurringA
            this.saveWithoutButton()
        },
        handleEndingActivitiesInput (selected) {
            if (!this.endingA.includes(selected)) {
                this.endingA.push(selected)
            } else {
                const index = this.endingA.indexOf(selected)
                if (index !== -1) {
                    this.endingA.splice(index, 1)
                }
            }
            this.selectedEndingA = this.endingA
            this.saveWithoutButton()
        },
    },
}
</script>
<style lang="scss" scoped>
.CaseCompletionSettings {
    min-height: 300px;
    max-width: 100%;
    top: $subheader-height;
    bottom: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    outline: 0;
    z-index: 2002;
    background: white;
    border: solid 1px rgba($black, 0.2);
    box-shadow: 0 2px 5px 0 rgba(68, 68, 68, 0.2);
    border-radius: $border-radius;
    display: flex;
    .CaseCompletion {
        width:100%;
        margin: 20px;
        display: flex;
        flex-direction: column;
        @media (max-height:$md) {
            margin: 10px;
        }
    }
    .title {
        margin-bottom: 10px;
        @media (max-height:$md) {
            margin: 0px;
        }
    }
    .OptionsGroup {
        display: flex;
        gap: 10px;
        padding: 10px 0;
    }
    .Options {
        width: 60%;
    }
    .container {
        width:auto;
        padding: 25px;
        border: solid 1px rgba($black, 0.2);
        border-radius: 5px;
        margin-top: 10px;
        justify-content: center;
        @media (max-height:$md) {
            margin-top: 5px;
            padding: 10px;
        }
    }

    .buttons {
        width: 80px;
    }
}
</style>
