export default {
    visualizationToBooster: 'Impulsionador de processos',
    boosterDisabledTooltip: 'É necessário configurar as condições necessárias para aceder ao Booster',
    customAnalysis: 'Análise personalizada',
    performanceBoosterTitle: 'Booster de desempenho',
    overview: {
        process: 'Síntese do processo',
        description: 'Compreender o desempenho atual do seu processo',
        slaNotDefined: 'Configurar as condições de SLA para exibir os dados.',
        caseCompletionNotDefined: 'Configurar as condições de conclusão do caso para exibir os dados.',
        slaAndCaseNotDefined: 'Configurar as condições de SLA e as condições de conclusão do caso para exibir os dados.',
        noConditionsText: 'É possível definir as condições necessárias na configuração do conjunto de dados.',
        costNotDefined: 'Configurar as condições de Custo para exibir os dados.',
    },
    prediction: {
        process: 'Previsão de processos',
        description: 'Descubra o desempenho do seu processo',
        predictionHighlights: {
            name: 'A previsão destaca',
            cases: 'casos',
            ongoingCases: 'ongoing cases',
            meetingSLA: 'in condition of',
            meetingSLAColor: 'cumprindo o SLA',
            missingSLAColor: 'under risk',
            missingSLA: 'de não cumprir o SLA',
            exceeding: 'already',
            exceedingColor: 'excedendo o SLA',
        },
        predictedPerformance: {
            name: 'Predicted performance by',
            risk: 'Estimativa de risco das previsões em curso',
            riskLabel: 'Risco',
            duration: 'Duração prevista versus duração passada',
            durationLabel: 'Dur. SLA',
            distribution: 'Distribuição do desempenho do SLA',
            distributionSla: 'Distr. SLA',
            distributionLabel: 'Distr. SLA',
            past: 'Passado',
            predicted: 'Previsto',
            finishedAbove: 'Terminou acima do Sla',
            finishedUnder: 'Terminou abaixo do Sla',
            finished: 'Concluído',
            ongoingAbove: 'Em curso acima de Sla',
            ongoingUnder: 'Em curso abaixo de Sla',
            finishedOngoing: 'Terminado - Em curso',
            pastPredicted: 'Passado - Previsto',
            ongoing: 'Em curso',
            pastMonths: 'Passado',
            months: 'meses',
        },
        ongoingCases: {
            showOnly: 'Show only for',
            ongoingCaseList: 'Lista de casos em curso',
            state: 'Estado',
            caseId: 'ID do caso',
            currentDuration: 'Duração atual',
            estimatedDuration: 'Duração estimada',
            Good: 'Bom',
            Risk: 'Risco',
            above: 'Acima do SLA',
            select: 'Por favor, seleccione um valor',

        },
        timeToMeet: {
            timeToMeet: 'Time to meet',
            improvementRate: 'Minimum monthly improvement rate for meeting',
            improvementRateSla: 'Minimum monthly improvement rate for',
            improvementRateSecondPart: 'in',
            past: 'Passado',
            months: 'meses',
            bestPerforming: 'current best performing',
        },
    },
    state: {
        performanceBy: 'Performance by',
        rightClick: 'Clique com o botão direito do rato numa barra para ver o gráfico',
        leadTimesBy: 'Lead times by',
        timeSpentPerCase: 'Tempo médio gasto por caso',
        performanceComparison: 'comparação de desempenho',
        leadTimes: 'lead times',
        cases: 'casos',
        selectFilteringElements: 'Elementos atualmente seleccionados para filtragem de recursos',
        evolutionOverTime: 'Evolução do desempenho ao longo do tempo',
        overallPerformance: 'Desempenho global por:',
        activity: 'Atividade',
        transition: 'Transição',
        variant: 'Variante',
        months: 'meses',
        weeks: 'semanas',
        all: 'Todos',
        below: 'Abaixo do SLA',
        intermediate: 'Intermediário',
        above: 'Acima do SLA',
        slaCompliance: 'Cumprimento do SLA',
        noTraces: 'Não foram encontrados vestígios para os filtros actuais',
        performanceByClick: 'Clique numa barra do gráfico à esquerda para selecionar',
    },
    improvement: {
        process: 'Melhoria de processos',
        description: 'Descubra como melhorar o desempenho do seu processo',
    },
    tabs: {
        overview: 'Visão geral',
        prediction: 'Previsão',
        improvement: 'Melhoria',
    },
    d2t: {
        viewReport: 'Ver relatório',
        analysisReport: 'Relatório de análise',
        copyToClipboard: 'Copiar para a área de transferência',
        copied: 'Copiado!',
    },
}
