<template>
    <div class="WizardDataset">
        <QStepper
            v-if="(steps || []).length"
            v-model="step"
            alternativeLabels
            animated
            flat
        >
            <QStep
                v-for="(item, index) in steps"
                :key="`WizardDatasetStep-${index}`"
                :name="item.name"
                :title="item.title"
                :icon="item.icon"
                :done="currentIndex > index"
                :headerNav="currentIndex > index"
            >
                <Component
                    :is="item.component"
                    v-if="index === steps.length - 1"
                    :ref="item.name"
                    v-model="data"
                    :projectId="projectId"
                    :datasetId="datasetId"
                    :steps="steps"
                    :isLoadingRelevant="isLoadingRelevant"
                />
                <Component
                    :is="item.component"
                    v-else
                    :ref="item.name"
                    v-model="data"
                    :projectId="projectId"
                    :datasetId="datasetId"
                    :steps="steps"
                />
            </QStep>
        </QStepper>
    </div>
</template>
<script>
/* eslint-disable consistent-return */
import VueTypes from 'vue-types'
import cloneDeep from 'lodash/cloneDeep'
import {
    Api, notifyError, notifySuccess, apiRequest,
} from '@/api'
import { wizardMixin } from '@/mixins'
import { WIZARD } from '@/entities'
import Steps from './Steps'
import Setup from './setup'

export default {
    name: 'WizardDataset',
    components: Steps,
    mixins: [wizardMixin],
    props: {
        projectId: VueTypes.string.isRequired,
        storageKey: VueTypes.string.def(WIZARD.KEYS.DATASET),
    },
    wizard () {
        const { config, initialState, mocks } = Setup()

        return {
            config,
            data: cloneDeep(initialState),
            mocks,
        }
    },
    data () {
        return {
            datasetId: '',
            isLoadingRelevant: false,
        }
    },
    methods: {
        async exit () {
            const { datasetId } = this.data

            if (datasetId) {
                const { ok } = await this.removeDataset(datasetId)
                if (ok) this.finish()
            } else {
                this.finish()
            }
        },
        finish () {
            const { projectId } = this.$route.params
            this.$router.push({ name: 'ProjectDatasets', params: { projectId } }).then(this.resetWizard)
        },
        fetchConfigureAndFinish ({ datasetId, params }, config) {
            this.isLoadingRelevant = true
            Api().datasets.configure({ datasetId, params })
                .then(({ headers, response }) => {
                    if (headers) {
                        notifySuccess(this.$t('wizard.notifications.keyFieldsSuccess.text'))
                        apiRequest(Api().datasets.finishUpload({ datasetId }))
                            .then((res) => {
                                notifySuccess(this.$t('wizard.notifications.launched.dataset.text'))
                                this.finish()
                            })
                            .catch(notifyError)
                    } else {
                        notifyError({ result: { response } })
                    }
                })
                .catch(notifyError)
                .finally(() => { this.isLoadingRelevant = false })
        },
        async removeDataset (datasetId) {
            return Api().datasets.delete({ datasetId })
                .then(({ data }) => ({ ok: true }))
                .catch(notifyError)
        },
        async submitStep0 () {
            const isValid = await this.component.isValid()
            if (isValid) {
                const { settings = {} } = this.data
                return Api().datasets.create({ projectUid: this.projectId, ...settings })
                    .then(({ headers, response }) => {
                        if (headers) {
                            this.datasetId = headers['x-location-uid']
                            notifySuccess(this.$t('projects.datasets.notifications.created.text'))
                            return { ok: true, data: { datasetId: headers['x-location-uid'] } }
                        }

                        notifyError({ result: { response } })
                        return { ok: false }
                    })
                    .catch(notifyError)
            }
        },
        async submitStep1 () {
            const isValid = await this.component.isValid()
            if (isValid) return { ok: true }
        },
        async submitStep2 () {
            const isValid = await this.component.isValid()
            if (isValid) {
                return { ok: true }
            }
            notifyError({
                result: {
                    response: {
                        data: { errorMessage: this.$t('wizard.notifications.keyFieldsError.text') },
                        status: 400,
                    },
                },
            })
        },
        async submitStep3 () {
            const isValid = await this.component.isValid()

            const errorMessages = []

            if (!isValid.checkStringType) {
                errorMessages.push(this.$t('wizard.notifications.keyRelevantFieldsError.text'))
            }
            if (!isValid.checkCountRelevant) {
                errorMessages.push(this.$t('wizard.notifications.keyRelevantFieldsCountError.text'))
            }
            if (errorMessages.length > 0) {
                errorMessages.forEach((m) => {
                    notifyError({
                        result: {
                            response: {
                                data: { errorMessage: m },
                                status: 400,
                            },
                        },
                    })
                })
                return
            }

            const { config } = this.data
            const {
                activity, traceId, start, end,
            } = config || {}
            if (config.modifiedTypes) {
                const params = {
                    name: 'default',
                    activityColumn: (activity || {}).value,
                    startTimeColumn: (start || {}).value,
                    traceColumn: (traceId || {}).value,
                    ...(end ? { endTimeColumn: end.value } : {}),
                }
                const formattedColumns = config.modifiedTypes.map((c) => {
                    let { thousandCharacter } = config
                    let { decimalCharacter } = config

                    if (!thousandCharacter && !decimalCharacter) {
                        thousandCharacter = '.'
                        decimalCharacter = ','
                    } else if (!thousandCharacter) {
                        thousandCharacter = decimalCharacter === ',' ? '.' : ','
                    } else if (!decimalCharacter) {
                        decimalCharacter = thousandCharacter === '.' ? ',' : '.'
                    }

                    return {
                        ...c,
                        format: c.type === 'DECIMAL'
                            ? `${thousandCharacter}${decimalCharacter}`
                            : c.format,
                    }
                })
                const configureParams = { ...params, columns: formattedColumns, homogenizeAttr: config.homogenizeAttr }
                this.fetchConfigureAndFinish({ datasetId: this.datasetId, params: configureParams }, config)
            } else {
                notifyError({
                    result: {
                        response: {
                            data: { errorMessage: this.$t('wizard.notifications.keyRelevantFieldsLoading.text') },
                            status: 400,
                        },
                    },
                })
            }
        },
    },
}
</script>
<style>
.q-stepper--horizontal .q-stepper__step-inner {
    padding-bottom: 5px !important;
}
</style>
