<template>
    <div
        v-if="isOpen"
        class="PredictionAlgorithmSettings"
    >
        <div class="PredictionAlgorithm">
            <WText
                class="mb-1"
                weight="bold"
                color="primary"
            >
                {{ $t('visualization.settings.predictionAlgorithm.name') }}
            </WText>
            <div class="OptionsGroup row justify-start">
                <WText>
                    {{ $t('visualization.settings.predictionAlgorithm.chooseAlgorithm') }}
                </WText>
                <QSelect
                    v-model="algorithm"
                    outlined
                    class="col"
                    labelColor="primary"
                    color="primary"
                    :options="algorithms"
                    @update:modelValue="handleAlgorithmChange"
                />
            </div>
            <WText v-if="algorithm === 'Machine Learning' || algorithm.value === 'Machine Learning'">
                <p>
                    {{ $t('visualization.settings.predictionAlgorithm.algorithms.machineLearning.caseDurationCheck') }}
                    <span :class="getStatusColorClass(values.durationModelStatus)">
                        {{ values.durationModelStatus }}
                    </span>
                </p>
                <p>
                    {{ $t('visualization.settings.predictionAlgorithm.algorithms.machineLearning.activitySequenceCheck') }}
                    <span :class="getStatusColorClass(values.sequenceModelStatus)">
                        {{ values.sequenceModelStatus }}
                    </span>
                </p>
            </WText>
            <WText v-if="algorithm">
                <p v-if="algorithm === 'Standard' || algorithm.value === 'Standard'">
                    {{ $t('visualization.settings.predictionAlgorithm.algorithms.standard.description') }}
                </p>
                <p v-else>
                    {{ $t('visualization.settings.predictionAlgorithm.algorithms.machineLearning.description') }}
                </p>
            </WText>
            <div class="row justify-end mt-1">
                <QBtn
                    class="sbuttons"
                    color="primary"
                    outline
                    noCaps
                    :label="$t('visualization.filters.save')"
                    @click="savePredictionAlgorithm"
                />
            </div>
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'

export default {
    name: 'PredictionAlgorithmSettings',
    components: {},
    mixins: [],
    props: {
        isOpen: VueTypes.bool.def(false),
        values: VueTypes.object,
    },
    emits: ['PredictionAlgorithm', 'mlEnabledGet'],
    data () {
        return {
            inputOptions: false,
            algorithm: this.$t('visualization.settings.predictionAlgorithm.algorithms.standard.name'),
            timeout: null,
            readyCheck: false,
            mlEnabled: false,
        }
    },
    computed: {
        algorithms () {
            const algorithms = [
                {
                    label: this.$t('visualization.settings.predictionAlgorithm.algorithms.standard.name'),
                    value: this.$t('visualization.settings.predictionAlgorithm.algorithms.standard.name'),
                    description: this.$t('visualization.settings.predictionAlgorithm.algorithms.standard.description'),
                },
            ]
            if (this.mlEnabled) {
                algorithms.push({
                    label: this.$t('visualization.settings.predictionAlgorithm.algorithms.machineLearning.name'),
                    value: this.$t('visualization.settings.predictionAlgorithm.algorithms.machineLearning.name'),
                    description: this.$t('visualization.settings.predictionAlgorithm.algorithms.machineLearning.description'),
                })
            }
            return algorithms
        },
    },
    created () {
        this.changeMLEnabled()
        this.getMlEnabled()
    },
    mounted () {
        this.checkIfValues()
    },
    methods: {
        checkIfValues () {
            if (this.values) {
                this.algorithm = this.values.predictionAlgorithm
            }
        },
        getMlEnabled () {
            this.$eventBus.emit('mlEnabledGet')
        },
        changeMLEnabled () {
            this.$eventBus.on('mlEnabledChanged', (mlEnabled) => {
                this.mlEnabled = mlEnabled
            })
        },
        savePredictionAlgorithm () {
            this.$emit('PredictionAlgorithm', { data: this.algorithm, type: 'predictionAlgorithm' })
        },
        getStatusColorClass (status) {
            switch (status) {
                case 'Training model':
                    return 'primaryColor'
                case 'Ready':
                    return 'secondaryColor'
                default:
                    return ''
            }
        },
        handleAlgorithmChange (algorithm) {
            this.algorithm = algorithm.value
        },
    },
}
</script>
<style lang="scss" scoped>
.PredictionAlgorithmSettings {
    top: $subheader-height;
    min-height: 300px;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    outline: 0;
    z-index: 2002;
    background: white;
    border: solid 1px rgba($black, 0.2);
    box-shadow: 0 2px 5px 0 rgba(68, 68, 68, 0.2);
    border-radius: $border-radius;
    width: 60%;
    .PredictionAlgorithm {
        margin: 20px;
    }
    .OptionsGroup {
        gap: 10px;
    }
    .WText{
        margin-top: 20px;
    }
    .buttons {
        width: 80px;
    }
    .primaryColor {
        color: $primary;
    }
    .secondaryColor {
        color: $secondary;
    }
}
</style>
