<template>
    <div
        class="ContainerMain"
    >
        <div
            v-if="fullResult && !highlightsData && ready"
            class="LackData"
        >
            {{ $t('visualization.predictions.noData') }}
        </div>
        <div
            v-if="!fullResult && !highlightsData && ready"
            class="LackData"
        >
            <div
                class="Warning rounded-borders q-pa-md"
            >
                <QIcon
                    class="Icon"
                    name="warning"
                    color="white"
                    size="40px"
                />
                <WText class="CenteredText">
                    {{ $t('visualization.predictions.notAvailable') }}
                </WText>
            </div>
        </div>
        <div
            v-else
            class="GraphsContainer"
        >
            <div
                v-if="highlightsData"
                class="fila"
            >
                <PredictionHighlights
                    class="element"
                    :predictionHighlights="predictionHighlights"
                    :highlightsLoading="highlightsLoading"
                />
                <PredictedPerformance
                    v-if="attributes"
                    class="element"
                    :attributes="attributes"
                    :processId="processId"
                    @selectedAttr="asignAttr"
                />
            </div>
            <div
                v-if="highlightsData"
                class="fila"
            >
                <OngoingCaseList
                    v-if="attributes"
                    class="element"
                    :attributes="attributes"
                    :processId="processId"
                    :sla="sla"
                />
                <TimeToMeet
                    v-if="attributes"
                    class="element"
                    :processId="processId"
                    :selectedAttr="selectedAttr"
                />
            </div>
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest, notifyError,
} from '@/api'
import {
    OngoingCaseList, PredictedPerformance, PredictionHighlights, TimeToMeet,
} from './components'

export default {
    name: 'Prediction',
    components: {
        PredictionHighlights,
        PredictedPerformance,
        TimeToMeet,
        OngoingCaseList,
    },
    props: {
        processId: VueTypes.string,
        attributes: VueTypes.array,
        sla: VueTypes.number,
    },
    emits: ['onAnalysisReportLoading', 'onAnalysisReportFinished', 'onCheckHighlights'],
    data () {
        return {
            filter: '',
            selectedAttr: undefined,
            predictionHighlights: undefined,
            highlightsLoading: false,
            highlightsData: false,
            fullResult: false,
            ready: false,
        }
    },
    async mounted () {
        this.getPredictionHighlights()
    },
    methods: {
        asignAttr (e) {
            this.selectedAttr = e
        },
        getPredictionAnalysisReport () {
            const params = { format: 'html' }
            apiRequest(Api().booster.predictionD2T({ processId: this.processId, params }))
                .then((data) => {
                    const { analysisReport } = data
                    this.analysisReport = analysisReport
                })
                .catch(notifyError)
                .finally(() => {
                    this.$emit('onAnalysisReportFinished', this.analysisReport)
                })
        },
        getPredictionHighlights () {
            this.highlightsLoading = true
            this.$emit('onAnalysisReportLoading')
            apiRequest(Api().booster.highlights({ processId: this.processId }))
                .then((res) => {
                    this.predictionHighlights = res
                    this.fullResult = res.isFullResult
                })
                .catch(notifyError)
                .finally(() => {
                    this.highlightsLoading = false
                    this.highlightsData = this.checkIfHighlightsData()
                    this.ready = true
                    if (this.highlightsData && !this.fullResult) {
                        this.$emit('onCheckHighlights', {
                            highlightsData: this.highlightsData,
                            fullResult: this.fullResult,
                        })
                    }
                    if (this.highlightsData) {
                        this.getPredictionAnalysisReport()
                    } else {
                        this.analysisReport = undefined
                        this.$emit('onAnalysisReportFinished', this.analysisReport)
                    }
                })
        },
        checkIfHighlightsData () {
            if (
                this.predictionHighlights.exceedSlaAbsolute > 0 ||
                this.predictionHighlights.meetSlaAbsolute > 0 ||
                this.predictionHighlights.riskSlaAbsolute > 0
            ) {
                return true
            }
            return false
        },
    },
}
</script>
<style scoped>

    .Prediction {
        position: relative;
        display: flex;
        justify-content: space-around;
    }
    .PredictionSection {
        max-width: 50%;
        display: flex;
    }
    .ContainerMain {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        max-height: 100%;
    }
    .fila {
        display: flex;
        height:50%;
        width: 100%;
        gap: 1%;
    }
    .element {
        width: 50%;
        height: 97%;
    }
    .Warning {
        width: 80%;
        background-color: #ffbc04;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        .Icon {
            margin-left: 1vw;
            margin-right: 1vw;
            float: right;
        }
        .CenteredText {
            text-align: center;
            flex: 1;
            font-weight: bold;
        }
    }
    .TooltipText {
        display: none;
        position: absolute;
        top: 70%;
        left: -15%;
        transform: translate(-100%, -50%);
        width: 35vw;
        background-color: #ffbc04;
        color: white;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 10;
        white-space: normal;
    }
    .WarningIcon {
        float: right
    }
    .WarningIcon:hover .TooltipText {
        display: block;
    }
    .LackData {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        height: 100%;
    }
    .GraphsContainer {
        width: 100%;
    }
</style>
