<template>
    <QExpansionItem
        class="overflow-hidden q-ma-md bg-grey-2"
        style="border: grey solid 1px; border-radius: 5px;"
        :icon="getSeverityIcon(row.severity)"
        :label="row.name"
        headerClass="bg-grey-2"
        expandIconClass=""
    >
        <template #header>
            <QItemSection avatar>
                <QAvatar
                    :icon="getSeverityIcon(row.severity)"
                    :color="`${ getSeverityColor(row.severity) }`"
                    class="text-white"
                />
            </QItemSection>
            <QItemSection class="text-weight-bold">
                {{ row.name }}
            </QItemSection>
            <QItemSection side>
                <div class="row items-center">
                    <QAvatar
                        :color="`${ getTypeColor(row.type) }`"
                        class="text-white"
                    >
                        {{ getTypeInitial(row.type) }}
                    </QAvatar>
                </div>
            </QItemSection>
            <QItemSection side>
                <div class="row items-center">
                    <QIcon
                        v-for="n in getSeverityLevel(row.severity)"
                        :key="n"
                        name="circle"
                        :color="`${ getSeverityColor(row.severity) }`"
                        size="20px"
                    />
                </div>
                <div class="row items-center">
                    <QIcon
                        v-for="n in getSeverityLevel(row.risk)"
                        :key="n"
                        name="circle"
                        :color="`${ getSeverityColor(row.risk) }`"
                        size="20px"
                    />
                </div>
            </QItemSection>
        </template>
        <QCard>
            <QCardSection>
                <div class="row q-gutter-md">
                    <div
                        class="column col"
                    >
                        <div class="col text-weight-bold">
                            {{ $t('visualization.alerts.detected.affected') }}
                        </div>
                        <div class="col text-primary">
                            {{ App.numberLocationFormat(row.affectedCases) }}
                        </div>
                    </div>
                    <div
                        class="column col"
                    >
                        <div class="col text-weight-bold">
                            {{ $t('visualization.alerts.detected.lastDetected') }}
                        </div>
                        <div class="col text-primary">
                            {{ App.dateLocationFormat(row.timestamp, 'long') }}
                        </div>
                    </div>
                    <div
                        class="column col"
                    >
                        <div class="col text-weight-bold">
                            {{ $t('visualization.alerts.detected.severity') }}
                        </div>
                        <div
                            v-if="row.type === 'STANDARD' || row.type === 'TIME'"
                            :class="`col text-${ getSeverityColor(row.severity) }
                            text-weight-bolder`"
                        >
                            {{ $t(`visualization.alerts.detected.severityRiskLabels.${row.severity}`) }}
                        </div>
                        <div
                            v-else
                            :class="`col text-${ getSeverityColor(row.severity) }
                            text-weight-bolder`"
                        >
                            {{ $t(`visualization.alerts.detected.predictionRiskLabels.${row.severity}`) }}
                        </div>
                    </div>
                    <div
                        v-if="row.type !== 'TIME'"
                        class="column col"
                    >
                        <div class="col text-weight-bold">
                            {{ $t('visualization.alerts.detected.filterSet') }}
                        </div>
                        <div class="col text-primary">
                            {{ Object.values(row.filters)[0] }}
                        </div>
                    </div>
                </div>
            </QCardSection>
            <QCardActions
                align="right"
            >
                <QBtn
                    v-show="false"
                    :label="$t('visualization.alerts.detected.filterCases')"
                    noCaps
                    color="primary"
                    @click="$emit('filter',row)"
                />
                <QBtn
                    v-if="!row.dismissed"
                    :label="$t('visualization.alerts.detected.dismiss')"
                    noCaps
                    color="primary"
                    @click="$emit('dismiss',row)"
                />
            </QCardActions>
        </QCard>
    </QExpansionItem>
</template>
<script>
import VueTypes from 'vue-types'
import { isEmpty } from 'lodash'

export default {
    name: 'AlertElement',
    inject: ['App'],
    props: {
        row: VueTypes.any,
    },
    emits: ['filter', 'dismiss'],
    data () {
        return {
        }
    },
    computed: {
    },
    methods: {
        getSeverityIcon (severity) {
            const icons = {
                LOW: 'info',
                MEDIUM: 'warning',
                HIGH: 'error',
            }
            return icons[severity]
        },
        getSeverityColor (severity) {
            const colors = {
                LOW: 'amber-11',
                MEDIUM: 'orange-11',
                HIGH: 'red-11',
            }
            return colors[severity]
        },
        getSeverityLevel (severity) {
            const level = {
                LOW: 1,
                MEDIUM: 2,
                HIGH: 3,
            }
            return level[severity]
        },
        getTypeColor (type) {
            const color = {
                STANDARD: 'primary',
                PREDICTION: 'secondary',
                TIME: 'black',
            }
            return color[type]
        },
        getTypeInitial (type) {
            const initial = {
                STANDARD: 'S',
                PREDICTION: 'P',
                TIME: 'T',
            }
            return initial[type]
        },
        withFilterSet (row) {
            return !isEmpty(row.filters)
        },
    },
}
</script>
<style scoped lang="scss">
.AlertList {
    height: fit-content;
    max-height: 90vh;
    overflow: auto;
}
</style>
