<template>
    <div>
        <Draggable
            :zIndex="1015"
            style="width: 100%;"
        >
            <ActivityPopUp
                :data="popupActivityData"
                :visible="isActivityPopupVisible"
                :isGroupActivities="isGroupActivities"
                :position="popupPosition"
                :tab="tab"
                @close="closeActivityPopup"
                @add-filter="handleAddFilterPopUp"
                @delete-filter="handleDeleteFilterPopUp"
            />
        </Draggable>
        <Draggable
            :zIndex="1010"
            style="width: 100%;"
        >
            <ArcsPopUp
                :data="popupArcData"
                :visible="isArcPopupVisible"
                :position="popupPosition"
                :tab="tab"
                @close="closeArcPopup"
                @add-filter="handleAddFilterPopUp"
                @delete-filter="handleDeleteFilterPopUp"
            />
        </Draggable>
        <div
            id="cy"
            class="Graph"
            style="width: auto;"
        />
        <FrequencyIndicator
            ref="frequencyIndicator"
            class="frequency-indicator"
            :validKnobValues="validKnobValues"
            @remove-activity="removeLessFreqActivities"
            @add-activity="addLessFreqActivities"
        />
        <q-inner-loading :showing="!data || data.activities.length == 0 || Object.keys(data).length == 0 || variantsLoading" />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import cytoscape from 'cytoscape'
import dagre from 'cytoscape-dagre'
import svg from 'cytoscape-svg'
import { mediaQueryMixin } from '@/mixins'
import mount from '@/utils/mount'
import { Svg, nameFormatter } from './utils'
import { Arc, Activity } from './components'
import Utils from './components/utils'
import Draggable from '../Draggable/Draggable.vue'
import ActivityPopUp from './components/ActivityPopUp.vue'
import ArcsPopUp from './components/ArcsPopUp.vue'
import FrequencyIndicator from './components/FrequencyIndicator.vue'

const colorMap = {
    START: '#07f5b4',
    END: '#fad9dd',
}

export default {
    name: 'GraphCyTo',
    components: {
        FrequencyIndicator,
        ActivityPopUp,
        ArcsPopUp,
        Draggable,
    },
    mixins: [mediaQueryMixin],
    inject: ['App'],
    props: {
        data: VueTypes.shape({
            activities: VueTypes.any,
            arcs: VueTypes.any,
        }).loose,
        model: VueTypes.shape({
            values: VueTypes.string,
            statsValue: VueTypes.string,
            heatMapValue: VueTypes.string,
            directionValue: VueTypes.oneOf(['LR', 'TB']).def('TB'),
        }).loose,
        color: VueTypes.shape({
            activities: VueTypes.any,
            arcs: VueTypes.any,
        }).loose,
        parent: VueTypes.string,
        simple: VueTypes.bool.def(false),
        variantsLoading: VueTypes.bool.def(false),
        tab: VueTypes.string,
        settings: VueTypes.object,
    },
    emits: ['add-filter', 'delete-filter'],
    data () {
        return {
            cy: null,
            popupActivityData: {},
            popupArcData: {},
            isActivityPopupVisible: false,
            isArcPopupVisible: false,
            isGroupActivities: false,
            popupPosition: { x: 0, y: 0 },
            selectedActivityPopup: null,
            selectedArcPopup: null,
            nodesPosition: {},

            minPath: [],
            totalPath: [],
            validKnobValues: [],
            percentageMap: {},
            actualPercentIndex: 0,
        }
    },
    watch: {
        data () {
            this.cy.fit(this.cy.elements(), 50)
        },
    },

    created () {
        cytoscape.use(dagre)
        cytoscape.use(svg)
        cytoscape.warnings(false) // Disable warnings in console
    },

    mounted () {
        this.$watch(vm => JSON.stringify([vm.data, vm.model?.values, vm.model?.statsValue, vm.model?.heatMapValue, vm.model?.directionValue]), () => {
            this.fetchGraph()
        }, { immediate: true })
        this.$watch(vm => JSON.stringify([vm.color, vm.model?.values, vm.model?.statsValue, vm.model?.heatMapValue, vm.model?.directionValue]),
            this.colorLoops, { immediate: true })

        document.addEventListener('click', this.handleClickOutside)
    },
    beforeUnmount () {
        document.removeEventListener('click', this.handleClickOutside)
        if (this.cy) {
            this.cy.off('tap', 'node')
            this.cy.off('tap', 'edge')
        }
    },
    methods: {
        fetchGraph () {
            this.renderGraph()
            this.adjustNodeWithContent()
            this.adjustEdgeMarginContent()
            if (this.model && this.data) this.enableLoopsAndOverlaps(this.data, this.model)
            this.adjustSelfLoops()
            this.applyLayout()
            this.alignFirstAndLastNodes()
            const variant = this.data?.variantId || this.data?.variants
            const orientation = this.model?.directionValue
            const key = JSON.stringify({ variant, orientation })
            const sessionInfoPosition = sessionStorage.getItem(key)
            if (sessionInfoPosition) {
                this.applyNodesPositionByVariant(JSON.parse(sessionInfoPosition))
            }
            this.resetFreqIndictadorValues()
            this.calcMinPath()
        },
        renderGraph () {
            if (this.cy) {
                this.cy.destroy()
                this.cy = null
            }
            this.cy = cytoscape({
                container: document.getElementById('cy'),
                style: [
                    {
                        selector: 'node',
                        style: {
                            'text-valign': 'center',
                            'text-halign': 'center',
                            'background-color': '#fff',
                            'border-width': 'data(stroke)',
                            height: '70px',
                            shape: 'round-rectangle',
                            content: 'data(content)',
                            'text-wrap': 'wrap',
                            'font-family': 'Roboto',
                            'font-size': '18px',
                            'font-weight': 500,
                            'line-height': '0.75em',
                        },
                    },
                    {
                        selector: 'edge',
                        style: {
                            'curve-style': 'bezier',
                            'control-point-distance': 200,
                            'text-outline-color': '#f00',
                            color: '#000',
                            'text-background-padding': 6,
                            width: 'data(strokeWidth)',
                            'target-arrow-shape': 'triangle',
                            'line-color': '#007bff',
                            'target-arrow-color': '#007bff',
                            label: 'data(label)',
                            'font-family': 'Roboto',
                            'text-wrap': 'wrap',
                            'text-max-width': 'none',
                            'text-justification': 'center',
                            'font-size': '18px',
                            'font-weight': '500',
                            'text-background-color': '#E9E9EF',
                            'text-background-opacity': 1,
                            'text-border-color': '#d0d0d0',
                            'text-border-width': 1,
                            'text-border-opacity': 1,
                            'text-background-shape': 'roundrectangle',
                            'text-margin-y': 10,
                        },
                    },
                    {
                        selector: '.highlighted-edge',
                        style: {
                            'text-background-color': '#b1d0f2',
                            'line-color': '#053f7d',
                            'target-arrow-color': '#053f7d',
                        },
                    },
                    {
                        selector: 'edge:loop',
                        style: {
                            'curve-style': 'bezier',
                            'loop-direction': '90deg',
                            'loop-sweep': '20deg',
                            'target-arrow-shape': 'triangle',
                            'target-arrow-color': '#FF8000',
                            'line-color': '#FF8000',
                        },
                    },
                ],
                wheelSensitivity: 0.25,
                maxZoom: 1.6,
                pixelRatio: 2,
            })
            // Charging the data if exists
            if (this.data) {
                this.cy.add(this.renderActivities())
                this.cy.add(this.renderArcs())
            }
            this.listenNodeHover()
            this.listenEdgeHover()
            this.listenNodeMove()
        },

        applyLayout () {
            const layout = this.cy.layout({
                name: 'dagre',
                rankDir: this.model.directionValue,
                ...this.adjustNodeAndEdgeByDirection(this.model.directionValue),
                align: 'DR',
                ranker: 'network-simplex',
            })
            layout.run()
            this.adjustZoomByNodes()
            this.saveInitialPosition()
            this.listenNodeHover()
            this.listenEdgeHover()
            this.openNodePopup()
            this.openArcPopup()
        },
        renderActivities () {
            const { activities = [], frequency } = this.data
            const elements = []
            const maxFreq = Math.max(...activities.map(item => item.frequency))
            activities.forEach((item, index) => {
                const statistics = {
                    duration: item.duration,
                    frequency: this.App.numberLocationFormat(item.frequency),
                    ...(item.attribute ? { attribute: item.attribute } : {}),
                    heatMap: {
                        duration: 0.098,
                        frequency: item.frequency / frequency,
                    },
                }
                const strokeCalc = (item.frequency * 5) / maxFreq
                const maxStroke = 7
                const borderStroke = Math.min(strokeCalc < 1 ? 1.75 : strokeCalc, maxStroke)
                const propsData = {
                    id: `activity-${index}`,
                    name: item.name === 'START' || item.name === 'END' ? this.$t(`visualization.graph.${item.name}`) : item.name,
                    statistics,
                    model: this.model,
                    simple: this.simple,
                }
                const { vNode } = mount(Activity, { props: propsData })
                const value = this.valueFormatter(vNode.component.ctx.value)
                const info = `\t${propsData.name }\n\n${ value}`
                elements.push({
                    group: 'nodes',
                    data: {
                        id: item.name,
                        freq: item.frequency,
                        statistics,
                        model: this.model,
                        simple: this.simple,
                        content: info,
                        rank: index, // Now we rank by index, bcs its "always the same"
                        stroke: borderStroke,
                        modelData: {
                            variantCount: this.data?.variants,
                            variantUid: this.data?.variantId,
                            loopUid: this.data?.loopId,
                        },
                    },
                })
            })
            return elements
        },
        renderArcs () {
            const { arcs = [], frequency } = this.data
            const maxFreq = Math.max(...arcs.map(item => item.frequency))
            const elements = []
            arcs.forEach((item, index) => {
                let cyclesEdge = 0
                const id = `arc-node-${index}`
                const labelId = `${id}-label`
                const maxStroke = 7
                const strokeSize = (item.frequency * 5) / maxFreq
                const stroke = Math.min(strokeSize < 1 ? 1.75 : strokeSize, maxStroke)
                const statistics = {
                    duration: item.duration,
                    frequency: this.App.numberLocationFormat(item.frequency),
                    overlap: true,
                    heatMap: {
                        duration: item.duration.avg / item.duration.max,
                        frequency: item.frequency / frequency,
                    },
                }
                const propsData = {
                    id: labelId, name: item.name, statistics, model: this.model, simple: this.simple,
                }
                const { vNode } = mount(Arc, { props: propsData })
                if (item.source === item.target) {
                    cyclesEdge = this.valueFormatter(vNode.component.ctx.value)
                }
                elements.push({
                    group: 'edges',
                    data: {
                        id: `arc-node-${nameFormatter(item.name)}`,
                        name: item.name,
                        freq: item.frequency,
                        source: item.source,
                        target: item.target,
                        label: this.valueFormatter(vNode.component.ctx.value),
                        statistics,
                        strokeWidth: stroke,
                        cycles: cyclesEdge,
                        modelData: {
                            variantCount: this.data?.variants,
                            variantUid: this.data?.variantId,
                            loopUid: this.data?.loopId,
                        },
                    },
                })
            })
            return elements
        },

        nameFormatter (name) {
            const sanitizedName = name.replace(/[^\w\s]/gi, '')
            return sanitizedName.replace(/\s/g, '-')
        },

        valueFormatter (value) {
            return value.replace(/\n/g, ' ')
                .replace(/<[^>]*>/g, '')
        },

        adjustNodeWithContent () {
            this.cy.nodes().forEach((node) => {
                const canvasTemp = document.createElement('canvas')
                const ctx = canvasTemp.getContext('2d')
                const counter = node.data('content')
                ctx.font = '17px roboto'
                const textWidth = ctx.measureText(counter).width
                node.style('width', `${textWidth - 2}px`)
                node.style('height', 'auto')
                node.style('padding', `2px`)
            })
        },

        adjustEdgeMarginContent () {
            if (this.model.directionValue === 'LR') return
            this.cy.edges().forEach((edge) => {
                const counter = edge.data('label')
                if (counter.length > 6) {
                    return edge.style('text-margin-x', 50)
                }
                return edge.style('text-margin-x', 35)
            })
        },

        adjustNodeAndEdgeByDirection (modelDirection) {
            if (modelDirection === 'TB') {
                if (this.cy.edges().length < 40) { // TB TINY GRAPH
                    return {
                        nodeSep: 130, // Horizontal
                        edgeSep: 140, // +/- BOTH
                        rankSep: 80, // Vertical
                    }
                }
                return { // TB BIG GRAPH
                    nodeSep: 80,
                    edgeSep: 60,
                    rankSep: 120,
                }
            }
            if (this.cy.edges().length < 40) { // LR TINY GRAPH
                return {
                    nodeSep: 100, // Vertical
                    edgeSep: 80, // +/- BOTH
                    rankSep: 270, // Horizontal
                }
            }
            return { // LR BIG GRAPH
                nodeSep: 60,
                edgeSep: 20,
                rankSep: 220,
            }
        },

        adjustSelfLoops () {
            this.cy.edges().forEach((edge) => {
                const source = edge.source()
                const target = edge.target()
                if (source.data('id') === target.data('id')) {
                    const nodeWidth = parseFloat(source.style('width')) / 2.2
                    const newControlPointStepSize = nodeWidth < 130 ? 130 : nodeWidth
                    edge.style('control-point-step-size', newControlPointStepSize)
                }
            })
        },

        adjustZoomByNodes () {
            if (this.cy.nodes().length <= 5) {
                this.cy.zoom({ level: 1.1 })
                this.cy.center()
            } else {
                this.cy.center()
            }
        },

        enableLoopsAndOverlaps (data, model, onlyColor) {
            if (onlyColor) {
                this.enableLOActivities(data, model)
                this.enableLOArcs(data, model)
            } else {
                this.enableLOActivities(data)
                this.enableLOArcs(data, model)
            }
        },

        enableLOActivities ({
            activities,
            frequency,
            loops_activities: loopsActivities,
            activitiesExecuted: executed,
        },
        model) {
            const loopsActivitiesIds = (loopsActivities || []).map(act => act.name)
            const onlyExecutedActivities = Object.entries(executed || []).filter(([key, value]) => value === true).map(([key, value]) => Number(key))
            const executedNames = activities?.filter(act => onlyExecutedActivities.includes(act.id)).map(act => act.name)
            this.cy.nodes().forEach((node) => {
                if (node.data('id') === 'START') node.style('border-color', '#01C38E')
                else if (node.data('id') === 'END') node.style('border-color', '#FABFC5')
                else if (this.settings?.zoomerConfig?.groupedActivities?.some(group => group.name === node.data('id'))) {
                    const nodeActivity = activities.find(activity => activity.name === node.data('id'))
                    const activityHeatMap = {
                        duration: 0.098,
                        frequency: nodeActivity.frequency / frequency,
                    }
                    const modelType = (model || {}).values || Utils.graphValues.BOTH
                    if (node) {
                        const dotColor = Utils.getHeatMapColors(activityHeatMap)[modelType !== Utils.graphValues.BOTH ? modelType : Utils.graphValues.FREQ]
                        node.style('border-color', dotColor)
                    }
                    node.style('background-color', '#f0f7fa')
                    node.style('border-style', 'double')
                } else {
                    const nodeActivity = activities.find(activity => activity.name === node.data('id'))
                    const activityHeatMap = {
                        duration: 0.098,
                        frequency: nodeActivity.frequency / frequency,
                    }
                    const modelType = (model || {}).values || Utils.graphValues.BOTH
                    if (node) {
                        const dotColor = Utils.getHeatMapColors(activityHeatMap)[modelType !== Utils.graphValues.BOTH ? modelType : Utils.graphValues.FREQ]
                        node.style('border-color', dotColor)
                    }
                }
                if (node) {
                    if (loopsActivitiesIds.includes(node.data('id'))) {
                        node.style('border-color', '#f29304')
                    } else if (executedNames.includes(node.data('id'))) {
                        node.style('stroke', Svg.executed.rectColor)
                    }
                }
            })
        },

        enableLOArcs ({ loop_arcs: loopArcs, arcs: allArcs }, { statsValue: durMode, values: dataMode }) {
            const loopsArcsId = (loopArcs || []).map(arc => `arc-node-${nameFormatter(arc.name)}`)
            const overlapArcsId = dataMode !== Utils.graphValues.FREQ
                ? (allArcs || []).filter(arc => Utils.getStatValue(arc.duration, durMode) < 0).map(arc => `arc-node-${nameFormatter(arc.name)}`)
                : []
            this.cy.edges().forEach((edge) => {
                if (edge) {
                    edge.style('line-color', '#007bff')
                    edge.style('target-arrow-color', '#007bff')
                    if (loopsArcsId.includes(edge.data('id'))) {
                        edge.style('line-color', Svg.loops.pathColor)
                        edge.style('target-arrow-color', Svg.loops.arrowColor)
                    } else if (overlapArcsId.includes(edge.data('id'))) {
                        edge.style('line-color', Svg.overlap.pathColor)
                        edge.style('target-arrow-color', Svg.overlap.arrowColor)
                    }
                }
            })
        },

        colorLoops () {
            if (this.color !== undefined) {
                this.enableLoopsAndOverlaps(this.color, this.model, true)
            }
        },

        centerGraph () {
            this.resetInitialPosition()
            this.cy.fit(this.cy.elements(), 50)
            this.alignFirstAndLastNodes()
            const variant = this.data?.variantId || this.data?.variants
            const orientation = this.model?.directionValue
            const key = JSON.stringify({ variant, orientation })
            sessionStorage.removeItem(key)
        },

        saveInitialPosition () {
            this.cy.nodes().forEach((node) => {
                node.data('initialPosition', { x: node.position().x, y: node.position().y })
            })
        },

        resetInitialPosition () {
            this.cy.nodes().forEach((node) => {
                const originalPosition = node.data('initialPosition')
                node.position({
                    x: originalPosition.x,
                    y: originalPosition.y,
                })
            })
        },

        downloadGraph (format) {
            if (format.value === 'SVG') {
                this.downloadSVG()
            } else {
                this.downloadPNGorJPG(format)
            }
        },

        downloadPNGorJPG (format) {
            const link = document.createElement('a')
            link.download = `Graph.${format.value.toLowerCase()}`
            link.href = format.value === "PNG"
                ? this.cy.png({ full: true, scale: 2 })
                : this.cy.jpg({ full: true, scale: 2 })
            link.click()
        },

        downloadSVG () {
            const svgContent = this.cy.svg({ scale: 1 })
            const blob = new Blob([svgContent], { type: 'image/svg+xml;charset=utf-8' })
            const link = document.createElement('a')
            link.download = 'Graph.svg'
            link.href = URL.createObjectURL(blob)
            link.click()
        },

        listenNodeHover () {
            this.cy.on('mouseover', 'node', (event) => {
                const node = event.target
                const name = node.data('id')
                if (node.style('border-color') === 'rgb(242,147,4)') {
                    node.style('background-color', '#fab348')
                    return
                }
                const highlightColor = colorMap[name] || '#0f9ffc'
                node.style('background-color', highlightColor)
            })

            this.cy.on('mouseout', 'node', (event) => {
                const node = event.target
                this.settings?.zoomerConfig?.groupedActivities?.some(group => group.name === node.data('id'))
                    ? node.style('background-color', '#f0f7fa')
                    : node.style('background-color', '')
            })
        },

        saveNodesPositionByVariant () {
            const nodes = this.cy.nodes()
            nodes.forEach((node) => {
                const name = node.data('id')
                const position = node.position()
                this.nodesPosition[name] = position
            })
            const variant = this.data?.variantId || this.data?.variants
            const orientation = this.model?.directionValue
            const key = JSON.stringify({ variant, orientation })
            sessionStorage.setItem(key, JSON.stringify(this.nodesPosition))
        },

        applyNodesPositionByVariant (positions) {
            const nodes = this.cy.nodes()
            nodes.forEach((node) => {
                const name = node.data('id')
                const position = positions[name]
                if (position) {
                    node.position(position)
                }
            })
        },
        listenEdgeHover () {
            this.cy.on('mouseover', 'edge', (event) => {
                const edge = event.target
                edge.addClass('highlighted-edge')
            })
            this.cy.on('mouseout', 'edge', (event) => {
                const edge = event.target
                edge.removeClass('highlighted-edge')
            })
        },

        listenNodeMove () {
            this.cy.on('dragfree', 'node', (event) => {
                this.saveNodesPositionByVariant()
            })
        },

        alignFirstAndLastNodes () {
            if (this.cy.nodes().length < 2) return
            const startNode = this.cy.$('#START')
            const endNode = this.cy.$('#END')
            const startNodePosition = startNode.position()
            const endNodePosition = endNode.position()

            if (this.model.directionValue === 'TB') {
                endNode.position({ x: startNodePosition.x, y: endNodePosition.y })
            } else {
                endNode.position({ x: endNodePosition.x, y: startNodePosition.y })
            }
        },

        // POPUPs METHODS

        openNodePopup () {
            const groupData = this.settings?.zoomerConfig?.groupedActivities
            this.cy.on('tap', 'node', (event) => {
                const node = event.target
                if (node.data('id') === 'START' ||
                    node.data('id') === 'END') return
                if (node.data('id') === this.selectedActivityPopup) {
                    this.closeActivityPopup()
                    return
                }
                this.closeArcPopup()
                const pos = this.calcPositionPopup(node)

                if (groupData && groupData.some(group => group.name === node.data('id'))) {
                    this.isGroupActivities = true
                    this.popupActivityData = groupData.find(group => group.name === node.data('id'))
                    this.selectedActivityPopup = this.popupActivityData.name
                    const nodeBox = node.renderedBoundingBox()
                    this.popupPosition = {
                        x: (nodeBox.x1 + (nodeBox.x2 - nodeBox.x1) / 2) + 100,
                        y: (nodeBox.y1 + (nodeBox.y2 - nodeBox.y1) / 2) - 40,
                    }
                    event.stopPropagation()
                    this.isActivityPopupVisible = true
                } else {
                    this.popupActivityData = node.data()
                    this.popupPosition = {
                        x: pos.x,
                        y: pos.y,
                    }
                    event.stopPropagation()
                    this.isGroupActivities = false
                    this.isActivityPopupVisible = true
                    this.selectedActivityPopup = node.data('id')
                }
            })
            if (this.isActivityPopupVisible && this.selectedActivityPopup) {
                if (groupData && groupData.some(group => group.name === this.selectedActivityPopup)) {
                    const node = this.cy.getElementById(this.selectedActivityPopup)
                    if (node.data() === undefined) {
                        this.closeActivityPopup()
                    } else {
                        this.popupActivityData = groupData.find(group => group.name === node.data('id'))
                        const nodeBox = node.renderedBoundingBox()
                        this.popupPosition = {
                            x: (nodeBox.x1 + (nodeBox.x2 - nodeBox.x1) / 2) + 100,
                            y: (nodeBox.y1 + (nodeBox.y2 - nodeBox.y1) / 2) - 40,
                        }
                    }
                } else {
                    const node = this.cy.getElementById(this.selectedActivityPopup)
                    if (node.data() === undefined) {
                        this.closeActivityPopup()
                    } else {
                        this.popupActivityData = node.data()
                        const newPos = this.calcPositionPopup(node)
                        this.popupPosition = {
                            x: newPos.x,
                            y: newPos.y,
                        }
                    }
                }
            }
        },

        openArcPopup () {
            const groupData = this.settings?.zoomerConfig?.groupedActivities
            this.cy.on('tap', 'edge', (event) => {
                const edge = event.target
                if (edge.data('source') === 'START' || edge.data('target') === 'END' ||
                (groupData.some(group => group.name === edge.data('source'))) ||
                (groupData.some(group => group.name === edge.data('target')))) return
                if (edge.data('id') === this.selectedArcPopup) {
                    this.closeArcPopup()
                    return
                }
                this.closeActivityPopup()
                const pos = this.calcPositionPopup(edge)
                this.popupArcData = edge.data()
                this.popupPosition = {
                    x: pos.x,
                    y: pos.y,
                }
                this.isArcPopupVisible = true
                this.selectedArcPopup = edge.data('id')
            })
            // If user changes variant of the graph
            if (this.isArcPopupVisible && this.selectedArcPopup) {
                const edge = this.cy.getElementById(this.selectedArcPopup)
                if (edge.data() === undefined) {
                    this.closeArcPopup()
                } else {
                    this.popupArcData = edge.data()
                    const newPos = this.calcPositionPopup(edge)
                    this.popupPosition = {
                        x: newPos.x,
                        y: newPos.y,
                    }
                }
            }
        },

        calcPositionPopup (el) {
            if (el.isNode()) {
                const nodeBox = el.renderedBoundingBox()
                const popupHeight = 260
                const offsetX = 100
                const offsetY = 40
                const visibleAreaHeight = this.cy.height()

                let popupX = nodeBox.x1 + (nodeBox.x2 - nodeBox.x1) / 2 + offsetX
                let popupY = nodeBox.y1 + (nodeBox.y2 - nodeBox.y1) / 2 - offsetY

                popupX = Math.max(0, Math.min(popupX, this.cy.width() - offsetX))

                if (popupY + popupHeight + 60 > visibleAreaHeight) {
                    popupY = nodeBox.y1 - popupHeight - offsetY
                } else if (popupY < popupHeight / 2) {
                    popupY = nodeBox.y2 + offsetY
                }
                popupY = Math.max(0, Math.min(popupY, visibleAreaHeight - popupHeight))

                return {
                    x: popupX,
                    y: popupY,
                }
            }
            const edgeBox = el.renderedBoundingBox()
            const offsetX = 100
            const offsetY = 40
            const popupHeight = 200
            const visibleAreaHeight = this.cy.height()

            let popupX = edgeBox.x1 + (edgeBox.x2 - edgeBox.x1) / 2 + offsetX
            let popupY = edgeBox.y1 + (edgeBox.y2 - edgeBox.y1) / 2 - offsetY

            popupX = Math.max(0, Math.min(popupX, this.cy.width() - offsetX))

            if (popupY + popupHeight + 60 > visibleAreaHeight) {
                popupY = edgeBox.y1 - popupHeight - offsetY
            } else if (popupY < popupHeight / 2) {
                popupY = edgeBox.y2 + offsetY
            }
            popupY = Math.max(0, Math.min(popupY, visibleAreaHeight - popupHeight))

            return {
                x: popupX,
                y: popupY,
            }
        },

        closeActivityPopup () {
            this.isActivityPopupVisible = false
            this.selectedActivityPopup = null
        },
        closeArcPopup () {
            this.isArcPopupVisible = false
            this.selectedArcPopup = null
        },
        handleAddFilterPopUp (filter) {
            this.$emit('add-filter', filter)
        },
        handleDeleteFilterPopUp (filter) {
            this.$emit('delete-filter', filter)
        },

        // FREQ INDICATOR METHODS

        calcMinPath () {
            if (this.cy.elements().nodes().length <= 2) {
                return
            }
            this.totalPath = this.cy.elements().nodes()
            const totalPathLength = this.totalPath.length - 2
            this.minPath = this.$refs.frequencyIndicator.calcMinPath(this.cy, 'START', 'END')
            const minPathNodesCount = this.minPath.filter(item => item.isNode()).length - 2

            this.minPercent = Math.round((minPathNodesCount / totalPathLength) * 100)
            this.calculateValidPercentages()
        },

        calculateValidPercentages () {
            const validPercentages = []
            const percentageToNodesMap = {}
            let currentPath = this.minPath
            let currentNodesCount = currentPath.filter(item => item.isNode()).length

            percentageToNodesMap[this.minPercent] = [...currentPath.filter(item => item.isNode()).map(node => node.data('id'))]
            validPercentages.push(this.minPercent)

            while (currentNodesCount < this.totalPath.length) {
                const nextActivity = this.calcSymmetricDifferencePath(currentPath).nodes().first()
                const prevActivitiesToDraw = []
                const postActivitiesToDraw = []

                // eslint-disable-next-line no-loop-func
                const checkPrevActivities = (node) => {
                    if (!node || node.data('id') === 'START' || node.data('id') === 'END') return
                    if (!currentPath.includes(node) && !prevActivitiesToDraw.includes(node)) {
                        prevActivitiesToDraw.push(node)
                        const prevNode = node?.incomers().nodes().first()
                        if (prevNode) checkPrevActivities(prevNode)
                    }
                }

                // eslint-disable-next-line no-loop-func
                const checkPostActivities = (node) => {
                    if (!node || node.data('id') === 'START' || node.data('id') === 'END') return
                    if (!currentPath.includes(node) && !postActivitiesToDraw.includes(node)) {
                        postActivitiesToDraw.push(node)
                        const nextNode = node?.outgoers().nodes().first()
                        if (nextNode) checkPostActivities(nextNode)
                    }
                }

                checkPrevActivities(nextActivity)
                checkPostActivities(nextActivity)
                const activitiesToDraw = [...new Set([...prevActivitiesToDraw, ...postActivitiesToDraw])]
                currentPath = currentPath.concat(activitiesToDraw)

                currentNodesCount = currentPath.filter(item => item.isNode()).length

                const newPercent = Math.round(((currentNodesCount) / (this.totalPath.length)) * 100)
                validPercentages.push(newPercent)
                percentageToNodesMap[newPercent] = [...currentPath.filter(item => item.isNode()).map(node => node.data('id'))]
            }

            this.validKnobValues = validPercentages.sort((a, b) => b - a)
            console.log(this.validKnobValues)
            this.percentageMap = percentageToNodesMap
        },

        calcSymmetricDifferencePath (path) {
            return this.totalPath.filter(node => !path.includes(node)).sort((a, b) => a.data('freq') - b.data('freq'))
        },

        removeLessFreqActivities () {
            if (this.actualPercentIndex < this.validKnobValues.length - 1) {
                // eslint-disable-next-line no-plusplus
                this.actualPercentIndex++
                this.$refs.frequencyIndicator.updatePercent(this.validKnobValues[this.actualPercentIndex])
                this.drawPercentNodes(this.percentageMap[this.validKnobValues[this.actualPercentIndex]])
            }
        },

        addLessFreqActivities () {
            if (this.actualPercentIndex > 0) {
                // eslint-disable-next-line no-plusplus
                this.actualPercentIndex--
                this.$refs.frequencyIndicator.updatePercent(this.validKnobValues[this.actualPercentIndex])
                this.drawPercentNodes(this.percentageMap[this.validKnobValues[this.actualPercentIndex]])
            }
        },

        drawPercentNodes (activityNames) {
            this.cy.nodes().forEach((node) => {
                if (!activityNames.includes(node.id())) node.hide()
                else node.show()
            })
        },

        resetFreqIndictadorValues () {
            this.minPath = []
            this.totalPath = []
            this.validKnobValues = []
            this.percentageMap = {}
            this.actualPercentIndex = 0
            this.$refs.frequencyIndicator?.resetInitialValues()
        },

    },
}
</script>

<style lang="scss">
.Graph {
    display: block;
    margin: auto;
    width: 100%;
    height: 100%;
}

.title-label {
  font-weight: bold;
  font-size: 14px;
  margin: 0;
}

.frequency-label {
  font-size: 12px;
  color: #666;
  margin: 0;
}
.popup-content {
  position: absolute;
  z-index: 1010;
}
.frequency-indicator {
    position: fixed;
    bottom: 90px;
    right: 350px;
}
</style>
