<template>
    <div class="OrganizationLayout">
        <div class="Container flex row-stretch flex-nowrap">
            <Sidebar
                v-model="tab"
                :tabs="tabs"
                @update:modelValue="handleChangeTab"
            />

            <div class="Wrapper">
                <div class="Header flex row-stretch-center">
                    <div class="Breadcrumbs">
                        <Breadcrumb />
                    </div>
                    <div class="Actions flex">
                        <HeaderList
                            v-model="filters"
                            :search="selected.search"
                        />
                    </div>
                </div>

                <div
                    class="Screen flex-1"
                    :class="{ isDesktop: !isMobile }"
                >
                    <RouterView v-slot="{ Component }">
                        <Transition
                            name="main-router"
                            mode="out-in"
                        >
                            <component
                                :is="Component"
                                :headers="filters"
                            />
                        </Transition>
                    </RouterView>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Breadcrumb, HeaderList, Sidebar } from '@/components'
import { mediaQueryMixin } from '@/mixins'

export default {
    name: 'OrganizationLayout',
    components: {
        Breadcrumb,
        HeaderList,
        Sidebar,
    },
    mixins: [mediaQueryMixin],
    data () {
        return {
            tab: '',
            filters: {
                search: '',
                archived: false,
            },
        }
    },
    computed: {
        selected () {
            return this.tabs.find(({ id }) => id === this.tab) || {}
        },
        tabs () {
            return [
                {
                    id: 'projects',
                    icon: 'airplay',
                    label: this.$t('navigation.projects'),
                    screen: 'Projects',
                    search: true,
                },
                {
                    id: 'invitations',
                    icon: 'calendar_today',
                    label: this.$t('navigation.invitations'),
                    screen: 'Invitations',
                    search: false,
                },
                {
                    id: 'notifications',
                    icon: 'notifications',
                    label: this.$t('navigation.notifications'),
                    screen: 'Notifications',
                    search: true,
                },
            ]
        },
    },
    mounted () {
        this.tab = this.getTabName()
    },
    methods: {
        handleChangeTab (value) {
            const tab = this.tabs.find(({ id }) => id === value)
            if (tab && !tab.disabled) this.$router.push({ name: tab.screen }).catch((e) => {})
        },
        getTabName () {
            const { name } = this.$route
            return name.toLowerCase()
        },
    },
}
</script>
<style scoped lang="scss">
.OrganizationLayout {
     & > .Container {
        min-height: calc(100vh);

        & > .Wrapper {

            & > .Header {
                position: sticky;
                top: 0;
                background: $white;
                z-index: 50;
                height: $subheader-height;
                padding: 0 25px;
                border-bottom: 1px solid $border-color;
                box-sizing: border-box;
            }
        }
    }

    .Screen {
        width: calc(100vw - #{$sidebar-min-width});
        padding: 25px;

        &.isDesktop {
            width: calc(100vw - #{$sidebar-width});
        }
    }

    .Sidebar {
        position: sticky;
        height: 100vh;
        top: 0;
    }
}
</style>
