<template>
    <div class="Tracking">
        <fragment v-if="!!step">
            <WText
                weight="semi"
                :size="18"
                class="mb-0-5"
            >
                {{ $t('wizard.preview.relevantAttributesTitle') }} ({{ step.num }}/{{ length }}):
            </WText>
            <WText>
                {{ $t('wizard.preview.relevantAttributesDescription') }}
            </WText>
        </fragment>
        <fragment v-else>
            <WText
                :size="18"
                weight="semi"
                color="primary"
                class="mb-0-5"
            >
                {{ $t('wizard.preview.done.title') }}
            </WText>
            <fragment v-if="length > 0">
                <WText>
                    {{ $t('wizard.preview.done.text') }}
                </WText>
            </fragment>
            <fragment v-else>
                <WText>
                    {{ $t('wizard.preview.done.noColumnsLeft') }}
                </WText>
            </fragment>
        </fragment>
    </div>
</template>
<script>
import VueTypes from 'vue-types'

export default {
    name: 'Tracking',
    props: {
        length: VueTypes.number,
        step: VueTypes.shape({
            num: VueTypes.number,
            label: VueTypes.string,
            tooltip: VueTypes.string,
            optional: VueTypes.bool.def(false),
        }).loose,
    },
}
</script>
